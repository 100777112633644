import { getApiUrl, parseRecord, getNewValues, getApiObjectKey, makeMediaObject, buildFormDataUpdateRequest } from '../utils'
import tokenFetcher from '../tokenFetcher'
import sortMediaArray from '../../utils/sortMediaArray';

export default (httpClient, serverProvider) => {

	const convertDataRequestToHTTP = (resource, params) => {

		const options = {};

		const payload = { ...params.data }; // need cloning otherwise will affect actual form content

		// Converting audio payload field to melody when question
		if (payload?.melody){
			payload.audios = payload.melody
			delete payload.melody
		}
		// re-map relationships
		const relationships = payload.relationships
			? Object.keys(payload.relationships)
			: [];
		['rules', 'goodAnswer'].forEach(rel => {
			if (payload[rel])
				relationships.push(rel)
		})

		relationships.forEach(rel => {

			const newValues = getNewValues(payload, rel)
			const empty = newValues === false

			if (payload[rel])
				delete payload[rel]

			const key = getApiObjectKey(rel)
			payload[key] = empty ? [] : newValues
		})

		if (payload.relationships)
			delete payload.relationships
		if (payload.modules)
			delete payload.modules

		// re-build medias object
		if (payload.medias) {
			const newMedias = makeMediaObject(payload);
			payload.medias = newMedias;
		}

		if (payload.audios)
			payload.audios = sortMediaArray(payload.audios).map(({ id }, i) => id)
		if (payload.videos)
			payload.videos = sortMediaArray(payload.videos).map(({ id }, i) => id)
		if (payload.slideshows)
			payload.slideshows = sortMediaArray(payload.slideshows).map(({ id }, i) => id)

		// in order to display a 'custom' value in the Select (not accepted by the API), we just remove it here
		if(payload.duration_type === 'custom' && (resource === 'cards' || resource === 'questions')) {
			payload.duration_type = 'short'
		}

		// in order to put a null value in duration_type corresponding  on 'none' value, (not accepted by the API), we just add it here
		if(payload.duration_type === 'none' && (resource === 'cards' || resource === 'questions')) {
			payload.duration_type = null
		}


		// remove keys handled otherwise
		if (payload.rules_file_url_fr) delete payload.rules_file_url_fr;
		if (payload.rules_file_url_en) delete payload.rules_file_url_en;

		// remove keys added by react-admin || dataProvider
		if (payload.hasOwnProperty('file') && payload.file.hasOwnProperty('url')) {
			payload.url = payload.file.url;
		}
		delete payload.file;

		options.method = 'POST';
		options.body = JSON.stringify(payload);
		options.headers = tokenFetcher.getHeaders();
		return {
			url: `${getApiUrl()}/${resource}?mode=contribution`,
			options
		};
	}

	const convertHTTPResponse = (response, resource, params) => {
		const { json } = response;
		const result = { data: { ...json.data, id: json.id || json.data.id } }
		result.data = parseRecord(resource, result.data);
		return result;
	}

	const createMedia = (resource, payload) => {
		const makeUploadRequest = binary => {
			const url = `${getApiUrl()}/${resource}/upload`;
			const options = {};
			options.method = 'POST';
			const formData = new FormData();
			formData.append('file', binary);
			options.body = formData;
			options.headers = tokenFetcher.getHeaders();
			return httpClient(url, options);
		};

		return Promise.all(payload.data.file.map(file => makeUploadRequest(file.rawFile)))
			.then(responses => {
				const data = responses.map(response => convertHTTPResponse(response, resource, payload));
				return data[0];
			});
	}

	const createInstantWinnerDates = async (resource, payload) => {
		const { options, empty } = buildFormDataUpdateRequest(
			`instantWinnerDates/NO_ID_YET/rules/upload`,
			payload,
			[['rules_file_url_fr', 'file_fr'], ['rules_file_url_en', 'file_en']]
		)

		const result = await createDefault(resource, payload)

		if (empty)
			return result

		const response = await httpClient(`${getApiUrl()}/instantWinnerDates/${result.data.id}/rules/upload?mode=contribution`, options)
		return convertHTTPResponse(response, resource)
	}

	const createDefault = (resource, payload) => {
		const { url, options } = convertDataRequestToHTTP(resource, payload);
		return httpClient(url, options).then(response => convertHTTPResponse(response, resource, payload));
	}

	return (resource, payload) => {

		if (['mediaLibraries', 'audioLibraries', 'videoLibraries'].includes(resource))
			return createMedia(resource, payload)

		if (resource === 'instantWinnerDates')
			return createInstantWinnerDates(resource, payload)

		return createDefault(resource, payload)
	}

}
