import React from 'react';

import {
	List,
	Datagrid,
	BooleanField,
	Filter,
	TextInput,
	translate,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import LengthField from '../../components/fields/lengthField';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
})

const SlideshowGrid = compose(
	withStyles(gridStyles),
	translate
)(({classes, translate, ...props}) => (
	<Datagrid {...props} >
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<BooleanField source="active"/>
		<LengthField source="medias" sortBy="qty_medias" label={translate('resources.slideshows.fields.image_count')}/>
		<DateTimeField source="updated_at" />
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({ classes, ...props }) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
	</Filter>
));


const SlideshowList = ({filter, drawerPath, rowClick = 'edit', editInDrawer, ...props}) => (
	<List
		exporter={false}
		filter={filter}
		filters={<PostFilter />}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<SlideshowGrid rowClick={rowClick}></SlideshowGrid>
	</List>
);

export default SlideshowList;
