import React from 'react';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import parseHTML from '../../utils/parseHTML';

const styles = {
	heading: {
		fontSize: '22px',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		marginLeft: '18px'
	}
};

const HeadingView = ({classes, translate, resource, view, record, ...props }) => {
	const title = record.internal_title || record.title_fr || record.title || record.label_fr || record.id;
	const parsedTitle = parseHTML(title);

	if (view === "create") {
		return (
			<Typography component='h1' className={classes.heading}>
				{`${translate(`resources.${resource}.name`)}`}
			</Typography>
		);
	} else {
		return (
			<Typography component='h1' className={classes.heading}>
				{`${translate(`resources.${resource}.name`)} : ${parsedTitle} `}
			</Typography>
		);
	}

}

export default compose(
	withStyles(styles),
	translate
)(HeadingView);