import React from 'react';

import {
	List,
	Datagrid,
	Filter,
	LongTextInput,
	translate
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import LengthField from '../../components/fields/lengthField';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

export const StoryGrid = compose(
	withStyles(gridStyles),
	translate,
)(({classes, translate, ...props}) => (
		<Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<LengthField source="cards" sortBy="qty_cards" label={translate('resources.stories.fields.cards_count')}/>
		<DateTimeField source="updated_at" />
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<LongTextInput source="fInternalTitle" alwaysOn className={classes.field}/>
	</Filter>
));


const StoryList = ({drawerPath, rowClick = 'edit', ...props}) => (
	<List
		filters={<PostFilter/>}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<StoryGrid rowClick={rowClick}></StoryGrid>
	</List>
);

export default StoryList;
