import React from 'react';

import {
	FormDataConsumer,
	ReferenceField,
} from 'react-admin';
import { change, FormName } from 'redux-form';
import get from 'lodash/get';

import * as MODULES from '../../views';
import SelectInput from './styledSelectInput';


/**
 * 
 * 
 * @param {object} record    react-admin typical record object. Passed automatically by `<SimpleForm>`
 * @param {object} props     the same props that were passed to the parent controler `<Edit>` or `<Create>` should be passed to `<SelectFromForm>`
 * @param {string} label     [optional] translated label to display
 * @param {string} source    Which key in the record contains the ID of the selected item
 * @param {string} formName  allows to reset field if ID disappears from choices
 * @param {string} reference Resource name uses for multiple things
 *                              - which API endpoint to call to fetch data
 *                              - wihch key in `record.relationships` contains the ids of the possible choices (`record.relationships[reference]` must be of type `{ids, positions}`)
 *                              - which exported view to use to display `<Item>` in the select input
 * 
 * @example

<Edit {...props}>
    <SimpleForm>
        <SelectFromForm {...props} label="Good answer" source="good_answer_id" reference="possibleAnswers"/>
    </SimpleForm>
</Edit>

 * 
 * 
 */

const SelectFromForm = ({ label, source, reference, formName, record, showMedia, validate, ...props }) => {
	const module = Object.values(MODULES).find(module => module.name === reference)
	return (
		<FormDataConsumer record={record}>
			{({ formData, dispatch }) => {
				const choices = formData.relationships && formData.relationships[reference] && formData.relationships[reference].ids
					? formData.relationships[reference].ids.map(id => ({ id }))
					: []
				const currentChoice = get(formData, source)
				if (currentChoice && (choices.length === 0 || !formData.relationships[reference].ids.includes(currentChoice))) {
					dispatch(change(formName, source, null))
				}
				return (
					<SelectInput label={label} source={source} choices={choices} validate={validate} optionText={
						<ReferenceField {...props} source="id" reference={reference} linkType={false}>
							<module.item showMedia={showMedia} />
						</ReferenceField>
					} />
				)
			}}
		</FormDataConsumer>
	)
}

export default (props) => (
	<FormName>
		{({ form }) => <SelectFromForm formName={form} {...props} />}
	</FormName>
)