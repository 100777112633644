import React from 'react';
import {
	CardActions,
	CreateButton,
	Button,
	translate,
	GET_LIST,
	withDataProvider,
	CRUD_GET_LIST_SUCCESS,
	FETCH_END,
	Toolbar,
	SaveButton,
} from 'react-admin'
import CloseIcon from '@material-ui/icons/Close';
import RoutedDrawerView from '../routedDrawerView';
import { MediaGridToggleButton } from '../../parts/MediaGrid';
import { compose } from 'recompose';

const ModuleToolbar = translate(({ translate, controls, ...props }) => (
	<Toolbar {...props}>
		<SaveButton />
		<>
			<Button
				label='interface.cancel'
				onClick={() => controls.current.close()}
				style={{ marginLeft: '1em' }}
			>
				<CloseIcon />
			</Button>
		</>
	</Toolbar>
));

const ListActions = ({ grid, setGrid, dataProvider, dispatch, module, drawerPath, controls: parentControls, translate, ...props }) => {
	const drawerControls = React.useRef({});

	const redirect = (basePath, id, data) => {
		drawerControls.current.close()
		const requestPayload = {
			pagination: { page: props.page, perPage: props.perPage },
			sort: props.currentSort,
			filter: props.filterValues,
		}
		dataProvider(GET_LIST, module.name, requestPayload).then((payload) => {
			dispatch({
				type: CRUD_GET_LIST_SUCCESS,
				payload,
				requestPayload,
				meta: {
					resource: module.name,
					fetchResponse: GET_LIST,
					fetchStatus: FETCH_END,
				}
			})
		})
		return false
	}

	const createOnClick = e => {
		e.stopPropagation()
		e.preventDefault()
		drawerControls.current.open()
	}

	return (
		<CardActions>
			<MediaGridToggleButton grid={grid} setGrid={setGrid} />
			<CreateButton label='interface.upload' onClick={createOnClick} />
			<Button label='interface.close' onClick={parentControls.current.close}>
				<CloseIcon />
			</Button>


			<RoutedDrawerView reference={module.name} view={'create'} controls={drawerControls} origin={drawerPath} {...props}>
				{({ reduxData, controls, ...createProps }) => (
					<module.create
						{...createProps}
						toolbar={<ModuleToolbar controls={controls} />}
						redirect={redirect}
					/>
				)}
			</RoutedDrawerView>
		</CardActions>
	);
};

export default compose(
	withDataProvider,
	translate,
)(ListActions);