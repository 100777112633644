import React from 'react';

import {
	RichTextField,
} from 'react-admin';

export default ({ record }) => (
	<>
		<RichTextField source="internal_title" record={record} />
	</>
);
