import React from 'react';

import {
	List,
	Datagrid,
	TextInput,
	NullableBooleanInput,
	BooleanField,
	RichTextField,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';

import {Filter} from 'react-admin';

import InListBooleanInput from '../../components/fields/inlistBooleanInput';
import FilterTagInput from '../../components/fields/tagFields/filterTagInput';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { withStyles } from '@material-ui/core';

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
	...theme.filters.tags,
})

const PostFilter = withStyles(filterStyles)(({ classes: {container, field, ...classes}, ...props }) => (
	<Filter {...props} className={container}>
		<TextInput source="fInternalTitle" alwaysOn className={field}/>
		<NullableBooleanInput label="published" source="fPublished" style={{"minWidth":"250px"}} alwaysOn className={field}/>
		<FilterTagInput label="tag_search" source="qTags" reference="tags" alwaysOn classes={classes}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const CourseGrid = withStyles(gridStyles)(({classes, ...props}) => (
	<Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<RichTextField source="description_fr" className={classes.abbrev}/>
		<BooleanField source="is_locked_for_guests"/>
		<BooleanField source="is_highlighted" />
		<InListBooleanInput source="published"/>
		<DateTimeField source="updated_at" />
	</Datagrid>
))

const CourseList = ({ drawerPath, rowClick = 'edit', ...props }) => (
	<List
		filters={<PostFilter/>}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<CourseGrid rowClick={rowClick}/>
	</List>
);

export default CourseList;
