import React from 'react';
import { Fields } from 'redux-form';
import { ReferenceField, setListSelectedIds, translate } from 'react-admin';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import * as MODULES from '../../views';
import RoutedDrawerView from "./routedDrawerView";
import DragAndDropContainer from './dragAndDropArrayInput';
import SelectButton from './selectButton';


/**
 * 
 * @param {string || array<string>}	reference		list of resources included in the list || name of the single resource
 * @param {number} 					maxSelection	max length of list
 * 
 * 
 * @usage the parent <SimpleForm> should declare a form name `<SimpleForm form={formName}>` to preserve form data when Drawer opens
 * 
 * 
 * @example

<SimpleForm {...props} form={formName}>
	<ManyTypesRelationshipsOrderedInput reference={['questionnaires', 'stories']}/>
</SimpleForm>

 * 
 * @usage <ManyTypesRelationshipsOrderedInput> can also pass along `filter` to the <List> included in the <RoutedDrawerView>
 * this allows to restrict the items in the list based on other inputs from the current form
 * 
 * @example

<FormDataConsumer>
	{({ formData, ...props }) => <ManyTypesRelationshipsOrderedInput reference={'questions'} className={classes.full} filter={{fType: formData.type}} {...props} /> }
</FormDataConsumer>

 * 
 * 
 */

const DraggableInnerComponent = ({ meta, id, module, ...props }) => {
	if (module && module.item)
		return (
			<ReferenceField {...props} record={{item_id: id}} reference={module.name} source='item_id' linkType={false}>
				<module.item resource={module.name}/>
			</ReferenceField>
		)

	return <>
		{meta._mergedListItemType} #{id} {meta._mergedListIdCount > 0 ? `.${meta._mergedListIdCount}` : ''} (⚠ {meta._mergedListItemType} don't export an Item view!)
	</>
};

const getListActions = () => translate(({ translate, controls }) => {
	return <Button variant="outlined" color="secondary" onClick={controls.current.close}>
		<CloseIcon /> <span> {translate('interface.close')} </span>
	</Button>
});

const getBulkActions = (field) => translate(({ translate, selectedIds, controls, maxSelection, count, maxPosition, resource, setListSelectedIds }) => {
	const isMaxSelection = (count + selectedIds.length) > maxSelection;
	const onClick = () => {
		const positions = selectedIds.map((id, i) => maxPosition + i);
		const oldValue = field.input.value && field.input.value.ids.length ? field.input.value : {ids: [], positions: []};
		const newValue = {
			ids: oldValue.ids.concat(selectedIds),
			positions: oldValue.positions.concat(positions),
		}

		controls.current.close();

		field.input.onChange(newValue);

		// Unselect all checkboxes
		setListSelectedIds(resource, []);
	};

	return (
		<>
			{isMaxSelection &&
				<span style={{ color: 'red', marginRight: '10px' }}>
					{translate('interface.drawers.maximum_selection', {
						count: maxSelection, 
						plural: maxSelection > 1 ? 's' : ''
					})}
				</span>
			}

			<Button variant="outlined" color="secondary" onClick={onClick} disabled={isMaxSelection}>
				<AddIcon />
				<span>
					{translate('interface.drawers.add_selection', {
						count: selectedIds.length, 
						plural: selectedIds.length > 1 ? 's' : ''
					})}
				</span>
			</Button>
		</>
	);
});

const dragAndDropOnChange = (modules, fields, orderedItems) => {
	const newValues = {};
	modules.forEach(({ name }) => newValues[name] = {ids: [], positions: []});

	orderedItems.forEach((meta, i) => {
		newValues[meta._mergedListItemType].ids.push(meta.id);
		newValues[meta._mergedListItemType].positions.push(i + 1);
	});

	modules.forEach(({ name }) => fields.relationships[name].input.onChange(newValues[name]));
};

const dragAndDropOnRemove = (fields, source, id) => {
	const oldValue = fields.relationships[source].input.value || {};
	if(!oldValue.ids)
		oldValue.ids = []
	if(!oldValue.positions)
		oldValue.positions = []
	const removeIndex = oldValue.ids.findIndex(item => item === id);
	const newValue = {
		ids: [...oldValue.ids],
		positions: [...oldValue.positions],
	}
	newValue.ids.splice(removeIndex, 1);
	newValue.positions.splice(removeIndex, 1);
	fields.relationships[source].input.onChange(newValue);
};

const ManyTypesRelationshipsOrderedInput = ({ names: fieldNames, adminProps: { record, maxSelection = Infinity, filter, setListSelectedIds, selectedIds, reference, names, ...props }, ...fields }) => {
	const modules = Object.values(MODULES).filter(module => names.includes(module.name))

	const mergedList = modules.map((module, i) => fields.relationships[module.name] && fields.relationships[module.name].input.value
		? fields.relationships[module.name].input.value.ids.map((id, j) => ({
			id,
			position: fields.relationships[module.name].input.value.positions && fields.relationships[module.name].input.value.positions.length 
				? fields.relationships[module.name].input.value.positions[j] 
				: i*10000 + j + 1,
			// keep track of the type of item in a multi-type list
			_mergedListItemType: module.name,
			// if we ever have several of the same item in this list, we still need to uniquely identify each one, so we count them
			_mergedListIdCount: fields.relationships[module.name].input.value.ids.filter((other, k) => k < j && id === other).length,
		}))
		: []
	)
		.flat()
		.sort((a, b) => a.position - b.position)
		.map((meta, i) => {
			meta.position = i + 1
			return meta
		})

	// count items of all types (used w/ `maxSelection` to determine button state in <List> view)
	const currentItemsCount = Object.values(fields.relationships)
		.map(field => field.input.value.ids ? field.input.value.ids.length : 0)
		.reduce((accu, curr) => accu + curr, 0);

	// for <RoutedDrawerView> (see doc in ./routedDrawerView.js)
	const drawerControls = React.useRef(modules.map(() => ({ current: {} })));

	const getHandleRowClick = resource => id => {
		const newIds = [...selectedIds[resource]]

		if (newIds.includes(id)) {
			const index = newIds.findIndex(arrayId => arrayId === id)
			newIds.splice(index, 1)
		} else {
			newIds.push(id)
			newIds.sort()
		}
		setListSelectedIds(resource, newIds)

		return false
	}

	return <>
		{modules.map((module, i) => {
			return (
				<RoutedDrawerView key={module.name} reference={module.name} controls={drawerControls} view={'list'} {...props} id={record.id} multiRouteIndex={i}>
					{({ reduxData, controls, ...routedDrawerViewProps }) => {
						const ListActions = getListActions();
						const BulkActions = getBulkActions(fields.relationships[module.name]);
						const excludeIds = !!fields.relationships[module.name] && Array.isArray(fields.relationships[module.name].input.value.ids) && fields.relationships[module.name].input.value.ids
						const listProps = Object.assign({}, routedDrawerViewProps, {
							rowClick: getHandleRowClick(module.name),
							filter: Object.assign({excludeIds}, filter),
							actions: <ListActions controls={controls} />,
							bulkActionButtons: <BulkActions controls={controls} setListSelectedIds={setListSelectedIds} maxSelection={maxSelection} count={currentItemsCount} maxPosition={currentItemsCount + 1} />,
						});
						return module.list(listProps); // treating <List> view as a function
					}}
				</RoutedDrawerView>
			)
		})}
		<DragAndDropContainer
			onOrderChange={orderedItems => dragAndDropOnChange(modules, fields, orderedItems)}
			onClickRemove={({ source, id }) => dragAndDropOnRemove(fields, source, id)}
			onClickEdit={true}
			childrenData={mergedList.map(item => {
				const module = modules.find(module => module.name === item._mergedListItemType)
				return ({
					meta: item,
					uniqueId: `${item._mergedListItemType}#${item.id}${item._mergedListIdCount > 0 ? `.${item._mergedListIdCount}` : ''}`,
					source: item._mergedListItemType,
					id: item.id,
					component: <DraggableInnerComponent {...props} id={item.id} module={module} />,
					icon: <module.icon/>
				})
			})}
		/>
		<SelectButton
			disabled={mergedList.length >= maxSelection}
			options={modules.map((module, i) => {
				return ({
					handleClose: () => drawerControls.current[i].current.open(),
					text: module.name,
				});
			})} 
			icon={<PlaylistAddIcon />}
		/>
	</>
}


const ContainerWithFields = ({ names, ...props }) => {
	const fieldNames = names.map(name => `relationships.${name}`)
	return <Fields names={fieldNames} component={ManyTypesRelationshipsOrderedInput} adminProps={{...props, names}} />
};

const mapStateToProps = (state, { reference }) => {
	const names = Array.isArray(reference) ? reference : [reference]
	const selectedIds = {}
	names.forEach(name => selectedIds[name] = state.admin.resources[name].list.selectedIds)
	return { selectedIds, names }
}

export default connect(mapStateToProps, { setListSelectedIds })(ContainerWithFields);