import React from 'react'
import { SelectInput } from 'react-admin'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	textField: {
		minWidth: 'fill-available',
	},
})

const StyledSelectInput = withStyles(styles)(({ classes, ...props }) => (
	<SelectInput className={classes.textField} {...props}/>
))

export default StyledSelectInput