import React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Button } from 'react-admin';

import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import DICT from '../../utils/frontResDict'
import tokenFetcher from '../../providers/tokenFetcher'

export const previewUrlLeaf = 'preview'

const ZOOM = 1.25
const WIDTH = 414
const HEIGHT = 736

const ModalContent = ({ resource, controls, classes, record }) => {

	const token = tokenFetcher.getToken()

	let src = undefined;

	const width = WIDTH * ZOOM
	const height = HEIGHT * ZOOM

	if (record.slug_fr) {
		if (DICT[resource] === 'session' || DICT[resource] === 'course' ) {
			src = `https://${window.ARIA_CONFIG.FRONT_URL}/fr/${DICT[resource]}/${record.slug_fr}?token=${token.token}&token_type=${token.type}`
		} else {
			src = `https://${window.ARIA_CONFIG.FRONT_URL}/fr/preview/${record.slug_fr}?token=${token.token}&token_type=${token.type}&module_type=${DICT[resource]}`
		}
	} else {
		console.warn('There doesnt seem to be a valid `slug_fr` for this content', record)
	}


	return (
		<div className={classes.iframeContainer} >
			<div className={classes.progress}>
				<CircularProgress />
			</div>
			<iframe
				className={classes.iframe}
				id={`preview-${resource}-${record.slug_fr}`}
				title={record.slug_fr}
				width={width}
				height={height}
				src={src}>
			</iframe>
			<Button
				className={classes.close}
				onClick={() => controls.current.close()}
				variant="fab"
			>
				<CloseIcon />
			</Button>
			<a href={src} target="_blank" rel="noopener noreferrer" className={classes.newTab}>
				<Button variant="fab">
					<ChevronRightIcon />
				</Button>
			</a>
		</div>
	)
}

const StatefulModalWrapper = ({ resource, controls, routeProps, state, ...props }) => {
	delete routeProps.staticContext;
	React.useEffect(() => {
		if (state.open !== !!routeProps.match) {
			if(window.ARIA_CONFIG.OWNER === 'Mazarine') 
				console.log(`On MOUNT: setting state of Modal to ${!state.open} for ${resource} based on route matching.`)
			state.setOpen(!state.open);
		}
	}, [!!routeProps.match])

	return (
		<Modal
			open={state.open}
			onBackdropClick={() => controls.current.close()}
			onEscapeKeyDown={() => controls.current.close()}
		>
			<ModalContent {...props} resource={resource} controls={controls} />
		</Modal>
	);
}

const RoutedModalView = ({
	resource,
	classes,
	push,
	controls = { current: null },
	onOpen,
	onClose,
	basePath,
	id,
	record,
	origin
}) => {

	const routeId = record.id || id
	const [open, setOpen] = React.useState(false);

	const root = origin
		? `${origin}`
		: `${basePath}/${routeId}`

	controls.current = {
		open: () => {
			typeof onOpen === 'function' && onOpen();
			push(`${root}/${previewUrlLeaf}`);
			setOpen(true);
		},
		close: () => {
			push(root);
			setOpen(false);
			typeof onClose === 'function' && onClose();
		}
	};

	return <>
		{(routeId || origin) &&
			<Route path={`${root}/${previewUrlLeaf}`}>
				{(routeProps) =>
					<StatefulModalWrapper
						root={root}
						controls={controls}
						routeProps={routeProps}
						state={{ open, setOpen }}
						classes={classes}
						resource={resource}
						record={record}
					/>
				}
			</Route>
		}
	</>;
};

const styles = theme => ({
	iframe: {
		zIndex: 3,
		border: 'none',
		backgroundColor: 'transparent',
		position: 'relative',
		width: `${100 * ZOOM}%`,
		height: `${100 * ZOOM}%`,
		transform: `scale(${1 / ZOOM})`,
		transformOrigin: "top left",
	},
	iframeContainer: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: "translate(-50%, -50%)",
		backgroundColor: 'black',
		'&:focus': {
			outline: 'none',
		},
		zIndex: 1,
		width: `calc(${WIDTH} / ${HEIGHT} * calc(100vh - 3em))`,
		maxWidth: `${WIDTH}px`,
		height: "calc(100vh - 3em)",
		maxHeight: `${HEIGHT}px`,
	},
	close: {
		position: 'absolute',
		top: '-28px',
		right: '-28px',
		zIndex: 4,
	},
	newTab: {
		position: 'absolute',
		bottom: '-25px',
		right: '-28px',
		zIndex: 4,
	},
	progress: {
		zIndex: 2,
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'block'
	}
})

export default compose(
	connect(undefined, { push }),
	withStyles(styles)
)(RoutedModalView);
