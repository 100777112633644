import React from 'react';

import { translate } from 'react-admin';

import AdjustIcon from '@material-ui/icons/Adjust';
import AlarmIcon from '@material-ui/icons/Alarm';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';

import SelectInput from './styledSelectInput';

const TestSelectInputOption = ({ record: { icon: Icon, name } }) => <span><Icon style={{ verticalAlign: 'bottom' }} /> {name}</span>

export const questionId = {
	'J': 'QJ',
	'I': 'QI',
	'S': 'QS',
	'B': 'QT',
	'BT': 'QB',
	'BTPA': 'QBPA',
	'BTPV': 'QBPV',
	'M': 'QM',
}

const TypeSelectInput = ({translate, question, ...props}) => (
	<SelectInput
		source="answer_type"
		label={translate('resources.questions.fields.answer_type')}
		choices={[
			{ id: question ? questionId['J'] : 'J', icon: AdjustIcon, name: translate('interface.select.answerType.joystick') },
			{ id: question ? questionId['I'] : 'I', icon: LinearScaleIcon, name: translate('interface.select.answerType.image') },
			{ id: question ? questionId['S'] : 'S', icon: PlayArrowIcon, name: translate('interface.select.answerType.sound') },
			{ id: question ? questionId['B'] : 'B', icon: AlarmIcon, name: translate('interface.select.answerType.timer') },
			{ id: question ? questionId['BT'] : 'BT', icon: MusicVideoIcon, name: translate('interface.select.answerType.blind_test') },
			{ id: question ? questionId['BTPA'] : 'BTPA', icon: QueueMusicIcon, name: translate('interface.select.answerType.blind_test_progressive_audio') },
			{ id: question ? questionId['BTPV'] : 'BTPV', icon: QueueMusicIcon, name: translate('interface.select.answerType.blind_test_progressive_video') },
			{ id: question ? questionId['M'] : 'M', icon: LibraryMusicIcon, name: translate('interface.select.answerType.melody') }
		]}
		optionText={<TestSelectInputOption />}
		optionValue="id"
		{...props}
	/>
)

export default translate(TypeSelectInput);

