import React    from 'react';
import {Layout} from 'react-admin';
import AppBar   from './AppBar';
// import Sidebar from './SideBar'
import Menu     from './Menu';
import {withStyles}           from '@material-ui/core/styles';


const styles = theme => ({
	root: {
		backgroundColor: '#ffffff'
	},
	main: {
		marginTop: '30px'
	}
});

const CustomLayout = ({ classes, children, ressources, ...props }) =>{
	return(
		<Layout
			className={classes.root}
			{...props}
			appBar={AppBar}
			// sidebar={Sidebar}
			menu={Menu}
		>
			<main className={classes.main}>
				<div>
					{children}
				</div>
			</main>

	</Layout>);

} ;

export default withStyles(styles)(CustomLayout);