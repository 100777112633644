import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	DateTimeInput,
	NumberInput,
	required,
	maxValue,
	maxLength,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid'
import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";
import FormToolbar from "../../components/parts/FormToolbar";
import SelectInput from '../../components/fields/styledSelectInput';
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const cleanNumberValidationMessage = ({translate}) => translate('validation.textLength', {maxLength: 4})
const validateText = [maxLengthRichText(500)];
const validateTitle = [maxLength(60)];
const validateNumberTitle = [maxValue(9999, cleanNumberValidationMessage)];
const validateReq = [required()];

const _defaultValue = {
	active_en: true,
	duration_type: 'short',
	type: 'text',
};

const Form = ({ record, view, parentProps, defaultValue, redirect = 'list', ...props }) => {

	if (typeof record.duration === 'number')
		record.duration_type = 'custom'

	return (
		<SimpleForm
			{...sanitizeFormProps(props)}
			record={record}
			redirect={redirect}
			toolbar={<FormToolbar />}
			defaultValue={{ ..._defaultValue, ...defaultValue }}
		>
			<HeadingView resource={props.resource} view={view} />
			<Grid container>
				<Grid item xs={8}>

					<Grid container head>
						<Grid item xs={6}>
							<EscapedTextInput source="internal_title" validate={validateReq} />
						</Grid>
						<Grid item xs={12}></Grid>
						<Grid item xs={6}>
							<BooleanInput source="active_en" defaultValue={true} />
						</Grid>
					</Grid>

					<Container title={'info'}>
						<Grid container>
							<Grid item xs={6}>
								<SelectInput source="type" choices={[
									{ id: 'text', name: 'text' },
									{ id: 'number', name: 'number' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<ConditionalField source="subtitle_fr" test={({ type }) => type === 'text'}>
									<EscapedTextInput source="title_fr" validate={validateTitle} label="title_fr" />
									<EscapedTextInput source="subtitle_fr" validate={validateTitle} label="subtitle_fr" />
								</ConditionalField>
								<ConditionalField test={({ type }) => type === 'number'}>
									<NumberInput source="title_fr" validate={validateNumberTitle} label="title_fr" options={{ fullWidth: true }}/>
								</ConditionalField>
								<RichTextInputRelLinks source="text_fr" relLinkSource="slug_fr" multiFieldIndex={0} {...parentProps} validate={validateText} />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<ConditionalField source="subtitle_en" test={({ type }) => type === 'text'}>
										<EscapedTextInput source="title_en" validate={validateTitle} label="title_en" />
										<EscapedTextInput source="subtitle_en" validate={validateTitle} label="subtitle_en" />
									</ConditionalField>
									<ConditionalField test={({ type }) => type === 'number'}>
										<NumberInput source="title_en" validate={validateNumberTitle} label="title_en" options={{ fullWidth: true }}/>
									</ConditionalField>
									<RichTextInputRelLinks source="text_en" relLinkSource="slug_en" multiFieldIndex={1} {...parentProps} validate={validateText} />
								</ConditionalField>
							</Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="opera_show_id" />
								<SelectInput source="duration_type" choices={[
									{ id: 'short', name: 'interface.select.durationType.short' },
									{ id: 'long', name: 'interface.select.durationType.long' },
									{ id: 'custom', name: 'interface.select.durationType.custom' },
								]} />
								<ConditionalField source="duration" test={({ duration_type }) => duration_type === 'custom'}>
									<NumberInput source="duration" options={{ fullWidth: true }} validate={validateReq} />
								</ConditionalField>
							</Grid>
						</Grid>
					</Container>
					<Container title={'chatbot'}>
						<Grid container>
							<Grid item xs={6}>
								<SelectInput allowEmpty source="chatbot_waiting_animation" choices={[
									{ id: 'waiting_1', name: 'interface.select.waiting.waiting_1' },
									{ id: 'waiting_2', name: 'interface.select.waiting.waiting_2' },
									{ id: 'waiting_3', name: 'interface.select.waiting.waiting_3' },
									{ id: 'two_choice_waiting_1', name: 'interface.select.waiting.two_choice_waiting_1' },
									{ id: 'two_choice_waiting_2', name: 'interface.select.waiting.two_choice_waiting_2' },
									{ id: 'two_choice_waiting_3', name: 'interface.select.waiting.two_choice_waiting_3' },
									{ id: 'three_choice_waiting_1', name: 'interface.select.waiting.three_choice_waiting_1' },
									{ id: 'three_choice_waiting_2', name: 'interface.select.waiting.three_choice_waiting_2' },
									{ id: 'three_choice_waiting_3', name: 'interface.select.waiting.three_choice_waiting_3' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="chatbot_teaser_text_fr" label="chatbot_teaser_text_fr" />
								<EscapedTextInput source="chatbot_waiting_text_fr" label="chatbot_waiting_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="chatbot_teaser_text_en" label="chatbot_teaser_text_en" />
									<EscapedTextInput source="chatbot_waiting_text_en" label="chatbot_waiting_text_en" />
								</ConditionalField>
							</Grid>
						</Grid>
					</Container>
					<Container title={'images'}>
						<Grid item xs={6}>
							<SelectInput source="orientation" choices={[
								{ id: 'landscape', name: '16/9' },
								{ id: 'portrait', name: '9/16' },
								{ id: 'square', name: '1/1' },
							]} />
						</Grid>
						<Grid item xs={6}></Grid>
						<GalleryInput
							type="medias"
							maxSelection={1}
							formats={['portrait', 'landscape', 'square']}
							{...parentProps}
						/>
					</Container>
					<Container title={'iframe'}>
						<Grid container>
							<Grid item xs={6}><EscapedTextInput source="iframe_url" /></Grid>
						</Grid>
					</Container>
					<Container title={'audios'}>
						<GalleryInput
							type="audios"
							maxSelection={3}
							{...parentProps}
						/>
					</Container>
					<Container title={'videos'}>
						<Grid item xs={6}>
							<SelectInput source="orientation_video" choices={[
								{ id: 'landscape', name: '16/9' },
								{ id: 'portrait', name: '9/16' },
								{ id: 'fiche', name: '4/3' },
							]} />
						</Grid>
						<Grid item xs={6}></Grid>
						<GalleryInput
							type="videos"
							maxSelection={1}
							{...parentProps}
						/>
					</Container>
					<Container title={'slideshows'}>
						<GalleryInput
							type="slideshows"
							maxSelection={1}
							{...parentProps}
						/>
					</Container>
				</Grid>
				<Grid item xs={4}>
					<Container title={'publication'}>
						<DateTimeInput source="created_at" disabled />
						<DateTimeInput source="updated_at" disabled />
					</Container>
				</Grid>
			</Grid>
		</SimpleForm>
	)
};

export default Form;