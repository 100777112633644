/**
 * This might (maybe?) also be implemented using the <Mutation> react-admin component (https://marmelab.com/react-admin/Actions.html#query-and-mutation-components)
 */

import React     from 'react';
import {connect} from 'react-redux';
import {
	SimpleForm,
	BooleanInput,
	crudUpdate
}                from 'react-admin';

const InListBooleanInput = ({crudUpdate, resource, source, record, basePath}) => {

	const handleChange = (e) => {

		crudUpdate(
			resource,
			record.id,
			Object.assign({}, record, {[source]: e.target.checked}),
			record,
			basePath,
			null
		);
	};

	return <>
		<SimpleForm
			form={`quick-${resource}-${source}-${record.id}`}
			toolbar={null}
			record={record}
			resource={resource}
			defaultValue={{[source]: record[source]}}
			style={{padding: 0}}
		>
			<BooleanInput
				source={source}
				label=""
				onChange={handleChange}
				onClick={e => e.stopPropagation()}
				style={{width: 'auto'}}
			/>
		</SimpleForm>
	</>;
};

const mapDispatchToProps = {
	crudUpdate
};

export default connect(null, mapDispatchToProps)(InListBooleanInput);
