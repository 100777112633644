import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';

const styles = theme => ({
	metaSideBar: {
		backgroundColor: '#303030'
	},
	mainContent: {

	},
	paddingLeft: {
		paddingLeft: '15px'
	},
	paddingRight: {
		paddingRight: '30px'
	},
	separ: {
		borderBottom: '1px dotted #cccccc',
		marginTop: '20px',
		marginBottom: '15px',
		marginRight: '30px'
	},
});

const StyledGrid = ({ classes, children, container, head, hr, xs, item, className, ...props }) => {
	const allClassNames = classnames(
		head && classes.paddingLeft,
		xs === 8 && classes.mainContent,
		xs === 6 && classes.paddingRight, 
		xs === 4 && classes.metaSideBar,
		hr && classes.separ,
		className
	);
	return (
		<Grid container={container} xs={xs} item={item} className={allClassNames}>
			{children && React.Children.map(children, child => child && React.cloneElement(child, Object.assign({}, props, child.props)))}
		</Grid>
	);
};

export default withStyles(styles)(StyledGrid);