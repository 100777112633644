import React from 'react';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import {
	MuiThemeProvider,
	createMuiTheme,
	withStyles,
	createStyles
} from '@material-ui/core/styles';
import { Notification } from 'react-admin'

import DefaultLoginForm from './LoginForm';

const styles = (theme) =>
	createStyles({
		main: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			height: '1px',
			alignItems: 'center',
			justifyContent: 'center',
			background: 'black url("./assets/login.jpg")',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		},
		card: {
			minWidth: 400,
			padding: '1em',
			backgroundColor: 'rgba(37, 37, 37, 0.5)',
			backdropFilter: 'blur(6px)',
		},
		ariaLogo: {
			margin: '2em',
			display: 'flex',
			justifyContent: 'center',
			'& svg': {
				height: '3em',
			}
		},
		onpLogo: {
			position: 'fixed',
			left: '1em',
			top: '1em',
			'& svg': {
				overflow: 'visible'
			}
		}
	});

class Login extends React.Component {
	theme = createMuiTheme(this.props.theme);
	render() {
		const {
			classes,
			className,
			staticContext,
			theme,
			match,
			location,
			history,
		} = this.props;

		return <MuiThemeProvider theme={{ ...this.theme, darkMode: true }}>
			<div className={classnames(classes.main, className)}>
				<Card className={classes.card}>
					<div className={classes.ariaLogo}>
						<svg xmlns="http://www.w3.org/2000/svg" width="130" height="57" viewBox="0 0 130 57">
							<g fill="#FFF" fillRule="evenodd">
								<path d="M106.868 55c3.773 0 8.147-3.037 10.033-7.115V37.412c-2.915-1.301-5.488-1.885-9.004-1.885-4.288 0-9.004 2.523-9.004 10.42 0 6.16 3.602 9.053 7.975 9.053m-6.133-38.29c2.65-1.077 6.648-1.165 6.648-1.165 9.496 0 16.637 3.701 16.637 13.74v12.93c0 11.28.686 11.367 3.858 11.54l1.715.087v1.822h-12.52v-5.987c-1.973 3.298-5.403 6.942-12.52 6.942-7.633 0-13.636-3.991-13.636-11.107 0-7.55 5.317-11.194 15.522-11.194 4.202 0 7.118.608 10.462 2.083v-9.545c0-5.9-2.727-9.704-9.458-10.4-3.705-.383-6.708.253-6.708.253" />
								<path d="M103.392 22.994c0-2.765-2.215-5.007-4.948-5.007-2.733 0-4.949 2.242-4.949 5.007 0 2.766 2.216 5.007 4.949 5.007 2.733 0 4.948-2.241 4.948-5.007M67.958 23.027c0-2.765-2.216-5.006-4.948-5.006-2.733 0-4.949 2.241-4.949 5.006 0 2.766 2.216 5.008 4.949 5.008 2.732 0 4.948-2.242 4.948-5.008M84.746 4.956C84.746 2.22 82.553 0 79.848 0c-2.706 0-4.899 2.219-4.899 4.956 0 2.738 2.193 4.957 4.899 4.957 2.705 0 4.898-2.219 4.898-4.957M70.864 55.649v-1.816l1.71-.087c3.162-.173 3.846-.26 3.846-11.503v-12.02c0-11.244-.684-11.33-3.846-11.504l-1.71-.086v-1.816h12.65v25.426c0 11.243.684 11.33 3.846 11.503l1.71.087v1.816H70.864zM7.526 28c2.733 0 4.948-2.242 4.948-5.007 0-2.765-2.215-5.007-4.948-5.007-2.733 0-4.948 2.242-4.948 5.007C2.578 25.758 4.793 28 7.526 28" />
								<path d="M25.984 47.883c-1.887 4.079-6.26 7.115-10.033 7.115-4.374 0-7.975-2.892-7.975-9.053 0-7.897 4.716-10.42 9.004-10.42 3.515 0 6.088.585 9.004 1.886v10.472zm34.28-32.034c-3.852 0-7.545.677-9.854 2.277v-1.574H38.55v1.726l1.624.082c3.005.165 3.655.247 3.655 10.933v13.63c0 10.435-.62 10.757-3.45 10.92-.693.03-1.91-.016-2.705-.053l-.713-.036c-3.173-.173-3.86-.26-3.86-11.54v-12.93c0-10.039-7.14-13.74-16.636-13.74 0 0-3.997.087-6.647 1.164 0 0 3.002-.636 6.707-.253 6.73.695 9.459 4.5 9.459 10.4V36.4c-3.345-1.475-6.26-2.083-10.462-2.083C5.317 34.317 0 37.962 0 45.511c0 7.116 6.003 11.107 13.635 11.107 7.118 0 10.548-3.645 12.52-6.943v5.988h31.322v-1.726l-2.437-.083c-3.818-.164-4.468-.246-4.468-10.932V26.165c0-6.884 4.232-8.756 7.785-9.708 3.153-.717 4.553-.093 4.553-.093-.748-.459-2.646-.515-2.646-.515z" />
							</g>
						</svg>

					</div>
					<DefaultLoginForm />
				</Card>
				<div className={classes.onpLogo}>
					<svg xmlns="http://www.w3.org/2000/svg" width="58" height="53" viewBox="0 0 58 53">
						<path fill="#FFF" fillRule="evenodd" d="M23.326 2.937c7.376 0 13.598 4.225 16.32 11.173H24.569c-.34 0-.565.222-.565.555v2.279c0 .334.226.557.565.557h4.659v23.786a21.33 21.33 0 0 1-4.828.561c-10.902 0-18.527-8.228-18.527-20.123 0-10.896 6.834-18.788 17.453-18.788zm17.03 14.683a24.33 24.33 0 0 1 .475 4.844c0 4.844-1.13 8.674-2.823 12.11-.17.338-.17.563.169.788l.339.227c.283.168.508.112.79-.17 3.954-3.942 6.609-8.786 6.609-14.701 0-.82-.053-1.626-.145-2.422 4.23 1.502 6.357 5.373 6.357 9.97 0 5.915-3.502 10.645-10.505 10.645h-7.116v-21.29h5.85zM29.293 44.643v7.666c0 .33.224.552.56.552h4.413c.336 0 .561-.221.561-.552V42.035h7.078C51.96 42.035 58 36.4 58 28.225c0-7.234-4.776-12.476-12.797-13.586C42.447 6.163 34.085 0 23.313 0 9.886 0 0 9.998 0 22.757c0 12.76 9.55 22.536 23.369 22.536 2.066 0 4.083-.238 5.925-.65z" />
					</svg>
				</div>
				<Notification />
			</div>
		</MuiThemeProvider>;
	}
}

const EnhancedLogin = withStyles(styles)(Login)

export default EnhancedLogin;