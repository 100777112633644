import React        from 'react';
import {Route}      from 'react-router-dom';
import * as namedResources from '../views';

const namedResourcesMapping = array => array.map(res => namedResources[res]);

/**
 * 	@tab 							which tab to activate at top of app,
 * 	@home 			(optional)		define tab's homepage (if none is defined, will land on first resource)
 * 		@path						actual route of homepage
 * 		@component					component to use as homepage for the tab
 * 	@resources 		(optional)		list of resources to display in left menu (length 0 or 1 will hide list and only display landing page)
 * 	@menuLayout 	(optional)		structure of left menu
 * 		@name 		(optional)		name of subgroup as parsable by react-admin's `translate` function (if none is declared, will put related content at root of menu)
 * 		@content 					list of resources to display in this subgroup
 */
	/*{
		tab: 'index',
		home: {
			path: 'index',
			component: tempTestHome,
		},
		resources: ['Themes', 'Tags'],
	},*/



export const siteStructure = [
	{
		tab: 'content',
		menuLayout: [
			{
				name: 'steps',
				content: ['Cards', 'Questions']
			},
			{
				name: 'groups',
				content: ['Stories', 'Questionnaires', 'Transitions']
			},
			{
				name: 'parents',
				content: ['Sessions', 'Courses']
			},
			{
				name: 'others',
				content: ['Artwork']
			},
			{
				name: 'index',
				content: ['Tags']
			}
		]
	},
	{
		tab: 'anecdotes',
		resources: ['Anecdotes']
	},
	{
		tab: 'medias',
		resources: ['MediaLibraries', 'AudioLibraries', 'VideoLibraries', 'Slideshows'],
	},
	{
		tab: 'grid',
		home: {
			path: namedResources['HomepageHighlights'].name,
			component: namedResources['HomepageHighlights'].edit,
		},
		resources: ['HomepageHighlights']
	},
	{
		tab: 'gamification',
		resources: ['InstantWinnerDates', 'Badges'],
	},
]
// map all string resource names to actual resource definition from `import * as namedResources from '../views'` 
// (just so that structure definition above can use 'MediaLibraries' for resource names instead of namedResources.MediaLibraries)
.map(group => {
	if(group.resources)
		group.resources = namedResourcesMapping(group.resources)
	if(group.menuLayout)
		group.menuLayout = group.menuLayout.map(subgroup => {
			if(subgroup.content)
				subgroup.content = namedResourcesMapping(subgroup.content)
			return subgroup
		})
	return group
})

// only define here the top tabs that need a homepage
export const customRoutes = siteStructure.map(group => {
	if (!group.home)
		return undefined

	const props = {
		path: `/${group.home.path}`,
		component: group.home.component,
	}
	return <Route exact {...props}/>
}).filter(route => !!route)

// define the root for each tab in AppBar
export const appBarRoutes = siteStructure.map(group => {
	if (group.home)
		return {
			path: group.home.path,
			tab: group.tab
		}
	else if (group.menuLayout)
		return {
			path: group.menuLayout[0].content[0].name,
			tab: group.tab
		}
	else
		return {
			path: group.resources[0].name,
			tab: group.tab
		}
})