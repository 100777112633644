import React from 'react';
import {
	Edit,
	SimpleForm,
	BooleanInput,
	required,
	maxLength,
	FormDataConsumer,
}  from 'react-admin';

import Container  from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import AudioField from '../../components/fields/audioField';
import EscapedTextInput from '../../components/fields/escapedTextInput'

const defaultValue = { active_fr: true, active_en: true };
const validateReq = [required()];

const MediaLibrarieEdit = ({ toolbar, redirect, drawerPath, reduxData, ...props}) => {
	const formName = `edit-${props.resource}-${props.id}`;
	return (
		<Edit {...props} undoable={false}>
			<SimpleForm form={formName} defaultValue={defaultValue} toolbar={toolbar} redirect={redirect}>
				<HeadingView resource={props.resource} view="edit" />
				<Grid container>
					<Grid item xs={12}>

						<Grid container head>
							<Grid item xs={6}>
								<EscapedTextInput source="internal_title" validate={validateReq} />
							</Grid>
							<Grid item xs={6}></Grid>
							<Grid item xs={12}>
								<BooleanInput source="active_en" defaultValue={true}/>
							</Grid>
						</Grid>

						<Container title={'info'}>
							<Grid container>
								<Grid item xs={6}>
									<EscapedTextInput source="title_fr" label="title_fr"/>
								</Grid>
								<Grid item xs={6}>
									<FormDataConsumer>
										{({formData, ...rest}) => formData.active_en &&
											<EscapedTextInput source="title_en" label="title_en"/>
										}
									</FormDataConsumer>
								</Grid>
								<Grid item xs={6}>
									<EscapedTextInput source="legend_fr" label="legend_fr"/>
								</Grid>
								<Grid item xs={6}>
									<FormDataConsumer>
										{({formData, ...rest}) => formData.active_en &&
											<EscapedTextInput source="legend_en"  label="legend_en"/>
										}
									</FormDataConsumer>
								</Grid>
								<Grid item xs={6}>
									<EscapedTextInput source="description_fr"  label="description_fr"/>
								</Grid>
								<Grid item xs={6}>
									<FormDataConsumer>
										{({formData, ...rest}) => formData.active_en &&
											<EscapedTextInput source="description_en"  label="description_en"/>
										}
									</FormDataConsumer>
								</Grid>
								<Grid item xs={6}>
									<EscapedTextInput source="artist" validate={[maxLength(15)]} label="artist"/>
								</Grid>
								<Grid item xs={12}>
									<AudioField source="url" label="resources.audioLibraries.fields.preview"/>
								</Grid>

							</Grid>
						</Container>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default MediaLibrarieEdit;
