export default {
	resources: {
		anecdotes: {
			name: 'Anecdotes',
			fields: {},
			containers: {
				info: 'Informations',
				assets: 'Assets',
				index: 'Index',
				publication: 'Publication'
			},
		},
		artworks: {
			name: 'Fiches "en savoir +"',
			fields: {
				internal_title : 'Nom de la fiche "en savoir +" (titre interne)',
				fInternalTitle: 'Rechercher dans le nom de la fiche',
				iframe_url: 'URL',
			},
			containers: {
				audios: 'Piste audio',
				images: 'Images',
				videos: 'Vidéos',
				info: 'Informations',
				index: 'Index',
				publication: 'Publication',
				iframe: "Vidéo externe - Url de l'iframe",
			},
		},
		audioLibraries: {
			name: 'Audio',
			fields: {
				preview: '',
				internal_title: 'Nom de la piste audio (titre interne)',
			},
			containers: {
				info: 'Information'
			},
		},
		badges: {
			name: 'Badges',
			fields: {
				label_fr: 'Nom',
				label_en: 'Nom - EN'
			},
			containers: {
				publication: 'Publication',
				info: 'Informations',
				image: 'Image du badges'
			}
		},
		cards: {
			name: 'Fiches',
			fields: {
				link_to_opera: 'Lien vers Opéra',
				chatbot_teaser_text: 'Extrait de texte pour le ChatBot',
				iframe_url: 'URL',
				iframe_artist: 'Artiste',
				internal_title: "Nom de la fiche (titre interne)",
				fInternalTitle: 'Rechercher dans le nom de la fiche'
			},
			containers: {
				iframe: "Vidéo externe - Url de l'iframe",
				images: 'Images',
				audios: 'Audios',
				vidoes: 'Videos',
				info: 'Informations',
				index: 'Index',
				publication: 'Publication',
				chatbot: 'Chatbot'
			}
		},
		courses: {
			name: 'Parcours',
			fields: {
				internal_title : "Nom du parcours (titre interne)",
				fInternalTitle : "Rechercher dans le nom du parcours",
			},
			containers: {
				info: 'Informations',
				images: 'Image principale du parcours',
				sessions: 'Liste des sessions',
				publication: 'Publication',
				index: 'Index',
			},
			interface: {
				addItems: 'Ajouter %{count} élément%{plural}'
			}
		},
		homepageHighlights: {
			name: 'Grille',
			fields: {},
		},
		instantWinnerDates: {
			name: 'Instant gagnant',
			containers: {
				info: 'Informations',
			},
			fields: {
				message_fr: 'Message',
				message_en: 'Message - EN',
				start_at: 'Date de début',
				end_at: 'Date de fin',
				rules_file_url_fr: 'Règlement',
				rules_file_url_en: 'Règlement - EN',
				max_winners: 'Nombre maximum de gagnants',
				winners_count: 'Nombre actuel de gagnants',
			},
			interface: {
				winners: 'gagnants',
				warning: "Attention, une fois enregistrées, les dates de début et de fin de l'instant gagnant en cours ne pourront plus être modifiées.",
				deleteActive: "Vous ne pouvez pas supprimer un Instant Gagnant s'il y a déjà eu des gagnants.",
				invalidDate: "Veuillez remplir le champ date et heure dans son intégralité.",
			},
		},
		mediaLibraries: {
			name: 'Images',
			fields: {
				internal_title: "Nom de l'image (titre interne)"
			},
			containers: {
				info: 'Informations',
				publication: 'Publication',
			},
		},
		possibleAnswers: {
			name: 'Réponses possibles',
			fields: {
				text_en: 'Texte - EN',
			},
			containers: {
				image: 'Image',
				audio: 'Audio',
				video: 'Video',
			},
		},
		questionnaires: {
			name: 'Questionnaires',
			fields: {
				internal_title : "Nom du questionnaire (titre interne)",
				fInternalTitle : "Rechercher dans le nom du questionnaire"
			},
			containers: {
				info: 'Informations',
				step: 'Liste des étapes du QCM',
				index: 'Index',
				publication: 'Publication'
			},
		},
		questions: {
			name: 'Questions',
			fields: {
				answer_type: 'Mode de réponse',
				answer_asset_type: 'Médias de réponse',
				question_asset_type: 'Média de question',
				timed_duration: 'Temps imparti à la réponse (en secondes)',
				duration_type: 'Durée de la question',
				internal_title : "Nom de la question (titre interne)",
				fInternalTitle : "Rechercher dans le nom de la question",
				fText : "Rechercher dans la question",
			},
			containers: {
				artworks: 'Fiche "en savoir +"',
				info: 'Informations',
				images: 'Image de la question',
				sessions: 'Liste des sessions',
				meta: 'Meta',
				publication: 'Publication',
				tags: 'Tags',
				question: 'Questions',
				chatbot: 'Chatbot',
				answers: 'Réponses',
				answerList: 'Liste des réponses possibles',
				goodAnswer: 'La bonne réponse',
				index: 'Index',
				youtube: "Youtube",
			}
		},
		sessions: {
			name: 'Sessions',
			fields: {
				internal_title : "Nom de la session (titre interne)",
				fInternalTitle : "Rechercher dans le nom de la session",
			},
			containers: {
				info: 'Informations',
				images: 'Image principale de la session',
				sessions: 'Liste des sessions',
				publication: 'Publication',
				index: 'Index',
				modules: 'Modules de la session',
			},
			interface: {
				addItems: 'Ajouter %{count} élément%{plural}'
			}
		},
		slideshows: {
			name: 'Diaporamas',
			fields: {
				image_count: 'Nombre d\'images',
			},
			containers: {
				info: 'Informations',
				images: 'Images',
			}
		},
		stories: {
			name: 'Stories',
			fields: {
				publication_started_at: 'Date de début de la publication',
				publication_ended_at: 'Date de fin de la publication',
				cards_count: 'Nombre de fiches',
				internal_title : "Nom de la story (titre interne)",
				fInternalTitle: "Rechercher dans le nom de la story",
			},
			containers: {
				info: 'Informations',
				step: 'Liste des Étapes',
				publication: 'Publication',
				index: 'Index',
			}
		},
		tags: {
			name: 'Tags',
			fields: {
				is_enabled_for_bot: 'Activé sur le bot',
				is_shown_on_grid: 'Utilisé sur la grille',
			},
			containers: {
				info: 'Informations',
				publication: 'Publication'
			},
		},
		themes: {
			name: 'Thèmes',
			fields: {},
			containers: {
				info: 'Informations',
				publication: 'Publication'
			},
		},
		transitions: {
			name: 'Transitions',
			fields: {
				internal_title : "Nom de la transition (titre interne)",
				fInternalTitle : "Rechercher dans le nom de la transition ",
			},
			containers: {
				info: 'Informations',
				images: 'Image',
				publication: 'Publication',
				index: 'Index',
				chatbot: 'Chatbot',
			},
		},
		users: {
			name: 'Utilisateurs',
			fields: {}
		},
		videoLibraries: {
			name: 'Vidéos',
			fields: {
				subtitle_file_url_fr: 'Sous-titres',
				subtitle_file_url_en: 'Sous-titres - EN',
				video_url: 'Vidéo',
				internal_title: 'Nom de la vidéo (titre interne)',
			},
			containers: {
				info: 'Informations',
				poster: 'Image de couverture',
			},
		},
	},
	interface: {
		add: 'ajouter',
		cancel: 'annuler',
		close: 'fermer',
		numberof: 'Nombre de %{resource}',
		playPause: 'Play / Pause',
		playRegion: 'Play Region',
		play: 'Play',
		pause: 'Pause',
		clear: 'clear',
		generateMarkers: 'générer les clips',
		remove: 'supprimer',
		save: 'Enregistrer',
		saveAndPreview: 'Enregistrer et prévisualiser',
		toggleGrid: 'Affichage grille',
		toggleList: 'Affichage liste',
		upload: 'uploader',
		topBar: {
			content: 'Contenu',
			index: 'Index',
			anecdotes: 'Anecdotes',
			medias: 'Medias',
			grid: 'Grille',
			gamification: 'Gamification',
		},
		menu: {
			subgroups: {
				steps: 'étapes',
				groups: 'modules',
				parents: 'sessions / parcours',
				others: 'éléments transverses',
			},
		},
		select: {
			waiting: {
				waiting_1: 'Attente 1',
				waiting_2: 'Attente 2',
				waiting_3: 'Attente 3',
				two_choice_waiting_1: 'Attente (2 réponses) V1',
				two_choice_waiting_2: 'Attente (2 réponses) V2',
				two_choice_waiting_3: 'Attente (2 réponses) V3',
				three_choice_waiting_1: 'Attente (3 réponses) V1',
				three_choice_waiting_2: 'Attente (3 réponses) V2',
				three_choice_waiting_3: 'Attente (3 réponses) V3',
			},
			good: {
				good_1: 'Bonne réponse 1',
				good_2: 'Bonne réponse 2',
				good_3: 'Bonne réponse 3',
			},
			bad: {
				bad_1: 'Mauvaise réponse 1',
				bad_2: 'Mauvaise réponse 2',
				bad_3: 'Mauvaise réponse 3',
			},
			answerType: {
				joystick: 'Joystick',
				image: 'Image',
				sound: 'Son',
				timer: 'Timé',
				blind_test: 'BlindTest',
				blind_test_progressive_audio: 'BlindTest Progressif Audio',
				blind_test_progressive_video: 'BlindTest Progressif Vidéo',
				melody: 'Maestro',
			},
			assetType: {
				videos: 'Vidéos',
				audios: 'Audios',
				medias: 'Images',
				none: '',
			},
			questionType: {
				timer: 'Timé',
				sound: 'Son',
				image: 'Image',
				joystick: 'Joystick',
			},
			durationType: {
				none: '',
				short: 'Court',
				long: 'Long',
				custom: 'Custom',
			},
			storyType: {
				versus: 'Versus',
				story: 'Story',
			}
		},
		login: {
			id: 'Adresse email',
			pwd: 'Mot de passe',
			login: 'Connexion'
		},
		drawers: {
			maximum_selection: 'Sélectionnez %{count} item%{plural} maximum',
			add_selection: 'Ajouter %{count} item%{plural}',
		},
		formats: {
			portrait: 'Portrait',
			landscape: 'Paysage',
			square: 'Carré',
			free: 'Libre',
			fiche: 'Fiche',
			vertical: 'Vertical'
		}
	},
	validation: {
		textLength: 'Maximum %{maxLength} caractères',
		richTextLength: 'Maximum %{maxLength} caractères, %{length} actuellement',
		tagExists: 'Le tag "%{label}" existe déjà',
		createRelationshipsOrderedInput: {
			empty: 'Ce contenu ne peut pas être vide',
			tooMany: 'Trop de contenu contribué (%{count} / %{max})',
			wrongItem: 'Mauvais type de contenu',
			tooFew: 'Pas assez de contenu contribué (%{count} / %{min})'
		},
		galleryInput: {
			empty: 'Ce contenu ne peut pas être vide',
			tooMany: 'Trop de contenu contribué (%{count} / %{max})',
			tooFew: 'Pas assez de contenu contribué (%{count} / %{min})'
		}
	}
};

// fallback keys cannot be 'ra' nor any root key defined in default export above (ie. 'resources' or 'interface')
export const fallback = {
	active: 'Actif',
	active_fr: 'Actif',
	active_en: 'Activer l\'anglais',
	artist: 'Crédits',
	audios: 'Pistes audio',
	created_at: 'Création',

	chatbot_comment_text_en: "Commentaire de l'IA - EN",
	chatbot_comment_text_fr: "Commentaire de l'IA",
	chatbot_comment_animation: "Animation de l'IA",

	chatbot_waiting_text_en: 'Texte d\'attente de l\'IA - EN',
	chatbot_waiting_text_fr: 'Texte d\'attente de l\'IA',
	chatbot_waiting_animation: 'Animation d\'attente de l\'IA',

	chatbot_teaser_text_en: 'Texte de commentaire de l\'IA - EN',
	chatbot_teaser_text_fr: 'Texte de commentaire de l\'IA',
	chatbot_success_animation: 'Animation Bonne réponse',
	chatbot_success_text_fr: 'Texte bonne réponse',
	chatbot_success_text_en: 'Texte bonne réponse - EN',
	chatbot_error_animation : 'Animation Mauvaise réponse',
	chatbot_error_text_fr: 'Texte mauvaise réponse',
	chatbot_error_text_en: 'Texte mauvaise réponse - EN',
	chatbot_help_text_fr: "Indice de l'IA",
	chatbot_help_text_en: "Indice de l'IA - EN",
	code: 'Code',
	answer_text_en: 'Texte de commentaire IA - EN',
	answer_text_fr: 'Texte de commentaire IA',
	description_en: 'Texte descriptif - EN',
	description_fr: 'Texte descriptif',
	duration: 'Durée',
	file: 'Fichier',
	format: 'Format',
	good_answer: 'Bonne réponse',
	index: 'Index',
	internal_title: 'Titre interne',
	fInternalTitle: 'Rechercher dans le titre interne',
	is_highlighted: 'Fiche oeuvre',
	is_locked_for_guests: 'Réservé aux membres',
	is_standalone: 'Standalone',
	label: 'Libellé',
	label_fr: 'Libellé',
	label_en: 'Libellé - EN',
	label_search: 'Rechercher dans le libellé',
	legend_en: 'Légende - EN',
	legend_fr: 'Légende',
	level : 'Niveau de difficulté',
	opera_show_id: 'En ce moment à l’Opéra (ID Laravel)',
	medias: 'Images',
	name: 'Nom',
	number: 'Nombre',
	orientation: 'Orientation des médias',
	orientation_video: 'Orientation vidéo',
	preview: 'Preview',
	position: 'Position',
	publication_started_at: 'Publication',
	publication_ended_at: 'Fin de publication',
	published: 'Publié',
	question_type: 'Type de question',
	question_fr: 'Question',
	question_en: 'Question - EN',
	rules:"Gestion des règles",
	size: 'Taille',
	slideshows: 'Diaporamas',
	slug_en: 'Slug - EN',
	slug_fr: 'Slug',
	subtitle_en: 'Sous-titre - EN',
	subtitle_fr: 'Sous-titre',
	tags: 'Tags',
	primary_tags: 'Tag principal',
	secondary_tags: 'Tags secondaires',
	tag_search: 'Rechercher par tag',
	text: 'Texte',
	text_en: 'Texte - EN',
	text_fr: 'Texte',
	text_search: 'Rechercher dans le texte',
	themes: 'Thèmes',
	title_en: 'Titre - EN',
	title: 'Titre',
	title_fr: 'Titre',
	title_search: 'Rechercher dans le titre',
	type: 'Type',
	timed_duration : 'Durée',
	time_slicing: "Découpage temps (x temps par mesure )",
	updated_at: 'Mise à jour',
	url: 'URL',
	videos: 'Vidéos',
	wave_melody: "Pistes audio ( Wave )",
	youtube_artist: "Artiste",
	youtube_description_en: "Description - EN",
	youtube_description_fr: "Description",
	youtube_id: "ID de la vidéo",


	"Not Found": "Erreur: Contenu inconnu",
	"Internal Server Error": "Erreur: Problème avec l'API",
	"Failed to fetch": "Erreur: API en cours de mise à jour",
	"Unauthorized": "Erreur: Permissions invalides, veuillez vous identifier"
};
