import React from 'react';

import {
	List,
	Datagrid,
	TextInput,
	NullableBooleanInput,
	Filter,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import DateTimeField from '../../components/fields/dateTimeField';
import InListBooleanInput from '../../components/fields/inlistBooleanInput';
import EscapedTextField from '../../components/fields/escapedTextField';
import { withStyles } from '@material-ui/core';

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
		<NullableBooleanInput label="published" source="fPublished" alwaysOn className={classes.field}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const TransitionGrid = withStyles(gridStyles)(({classes, ...props}) => (
	<Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<InListBooleanInput source="published"/>
		<DateTimeField source="updated_at" />
	</Datagrid>
))

const TransitionList = ({drawerPath, rowClick = 'edit', ...props}) => (
	<List
	filters={<PostFilter/>}
	exporter={false}
	{...props}
	perPage={getPerPage()}
	pagination={<PostPagination />}
	>
		<TransitionGrid rowClick={rowClick}/>
	</List>
);

export default TransitionList;
