import React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Edit from '../../components/fields/edit';
import Create from '../../components/fields/create';
import Form from './Form';

const edit = props => <Edit {...props} Form={Form} />;
const create = props => <Create {...props} Form={Form} />;

export { edit, create };
export { VerifiedUserIcon as icon };
export { default as list } from './list';
export const name = 'badges';
