import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import tokenFetcher from '../tokenFetcher'

export default async (type, params) => {

	if (type === AUTH_LOGIN) {
		const { username, password } = params;
		return tokenFetcher.fetchToken(username, password)
	}

	if (type === AUTH_LOGOUT) {
		tokenFetcher.logout();
		return Promise.resolve();
	}

	if (type === AUTH_ERROR) {
		const { status } = params;
		if (status === 401 || status === 403) {
			// tokenFetcher.logout();
			return Promise.reject()
		}
		return Promise.resolve();
	}

	if (type === AUTH_CHECK) {
		return tokenFetcher.isTokenValid() ? Promise.resolve() : tokenFetcher.fetchToken();
	}

	if (type === AUTH_GET_PERMISSIONS) {
		return tokenFetcher.isTokenValid()
			? Promise.resolve('admin')
			: tokenFetcher.fetchToken().then('admin')
	}

	return Promise.reject('Unknown method');
};
