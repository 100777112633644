import React from 'react';

import RichTextInput from './richTextInputReimplementation';
// TODO: should switch to react-admin RichTextInput when github PR has been merged (https://github.com/marmelab/react-admin/pull/3714)
// PR is scheduled for 3.0.0 release

import { withStyles } from '@material-ui/core/styles';
import { Labeled } from 'react-admin';
import { compose } from 'redux';
import { Fields } from 'redux-form';
import get from 'lodash/get';

import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { createStyles } from '@material-ui/core';

import useQuill from './useQuill';
import { KNOW_MORE_BLOT_NAME, DEFAULT_FORMATS } from './quillConfig';
import RoutedListDrawer from './routedListDrawer';



/**
 * 
 * @param {String} source          standard react-admin prop: which key to use in record object for field content
 * @param {String} relLinkSource   [optional] similar to `source`: which key to use in Artwork's record for linking. If not defined, no link option will be shown.
 * @param {Number} multiFieldIndex [optional] this component is likely to be declared more than once in the same form (WYSIWYG for text_fr & text_en). Each instance must have a different multiFieldIndex.
 * @param {String} label           [optional] label and placeholder for text field
 * 
 * @usage 

 <RichTextInputRelLinks
	source="text_fr"
	{...editProps}
	relLinkSource="slug_fr"
	multiFieldIndex={0}
/>

* 
 */


const toolBarStyle = {
	'root': {
		maxWidth: '100%',
	}
};

const ToolBar = withStyles(toolBarStyle)(({ withLinks, id, classes }) => (
	<div id={id}>
		{DEFAULT_FORMATS.map(format => (Object.keys(format).map(className => (
			<button
				key={`${className}-${format[className]}`}
				className={`ql-${className}`}
				value={format[className]}
			></button>
		))))}
		{withLinks &&
			<button className={`ql-${KNOW_MORE_BLOT_NAME}`}><InsertLinkIcon classes={classes} /></button>
		}
		<button className="ql-clean"></button>
	</div>
));


const RichTextInputWithRelLinks = ({ adminProps: { multiFieldIndex, relLinkSource, label, classes: { labelFull, labelEmpty, valueClass, labelActive, fieldRoot, ...classes }, record = {}, validate, ...props }, names: [source, relPath], ...fields }) => {
	const textField = get(fields, source)
	const relField = get(fields, relPath)
	const drawerControls = React.useRef({}); // ref to controls given by <RoutedDrawerView> to open / close the popin
	const toolbarId = `toolbar-${textField.meta.form}-${source}`;
	const domId = `editor-${textField.meta.form}-${source}`;
	const withLinks = !!relLinkSource

	const isRequired = props.isRequired || (validate && validate.some(fn => !!fn.isRequired))

	const createRelArray = (quill) => {
		const delta = quill.getContents()
		const idSet = new Set()
		delta.ops.forEach(({ attributes }) => {
			if (attributes && attributes[KNOW_MORE_BLOT_NAME])
				idSet.add(attributes[KNOW_MORE_BLOT_NAME].id)
		})
		const result = Array.from(idSet).sort()
		relField.input.onChange(result)
	}

	const { init, promiseControls } = useQuill({
		input: textField.input,
		domId,
		withLinks,
		onClick: () => drawerControls.current.open(),
		onLink: withLinks ? createRelArray : undefined,
	})

	const onCancel = promiseControls.current.reject

	const onAdd = (linkedRecord) => {
		if (typeof promiseControls.current.resolve === 'function') {
			promiseControls.current.resolve({
				source: linkedRecord[relLinkSource],
				id: linkedRecord.id
			});
		} else {
			console.error('WYSIWYG: Quill controls were not passed through the React Ref. This should not be possble.')
		}
	}



	return (
		<>
			{withLinks &&
				<RoutedListDrawer
					{...props}
					record={record}
					controls={drawerControls}
					onCancel={onCancel}
					onAdd={onAdd}
					sameRouteKey={multiFieldIndex}
				/>
			}

			<Labeled
				id={domId}
				label={label}
				source={source}
				resource={props.resource}
				isRequired={isRequired}
				meta={textField.meta}
				classes={{
					label: textField.meta.active ? labelActive : textField.input.value !== '' ? labelFull : labelEmpty,
					value: valueClass
				}}
				className={fieldRoot}
			>
				<>
					<ToolBar id={toolbarId} withLinks={withLinks} />
					<RichTextInput
						record={record}
						source={source}
						classes={classes}
						toolbar={`#${toolbarId}`}
						quillInit={init}
						validate={validate}
					/>
				</>
			</Labeled>
		</>
	);
};

const styles = theme => createStyles({
	'@global': {
		'.ql-container.ql-snow': {
			border: 'none',
		},
		'.ql-toolbar.ql-snow': {
			border: 'none',
			padding: '0',
			margin: '0.5rem 0',
			'& button': {
				padding: '3px 10px 3px 0',
				[`&.ql-bold, &.ql-italic, &.ql-clean`]: {
					'&.ql-active, &:hover': {
						color: theme.palette.primary.dark,
						'& svg *': {
							stroke: theme.palette.primary.dark,
						},
						'& svg rect': {
							stroke: 'none',
							fill: theme.palette.primary.dark,
						}
					}
				},
				[`&.ql-script, &.ql-${KNOW_MORE_BLOT_NAME}`]: {
					'&.ql-active, &:hover': {
						color: theme.palette.primary.dark,
						'& svg *': {
							fill: theme.palette.primary.dark,
						}
					}
				}
			},
		},
		'.ql-snow.ql-toolbar button': {},
		'.ql-container .ql-editor': {
			paddingBottom: '2px',
			[`& ${KNOW_MORE_BLOT_NAME}`]: {
				display: 'inline',
			},
			'& [data-attribute=popin]': {
				textDecoration: 'underline',
				color: theme.palette.primary.dark
			},
			'&::after': {
				backgroundColor: theme.palette.primary.dark,
			},
			'& p:not(:last-child)': {
				marginBottom: 0
			}
		}
	},
	fieldRoot: {
		minWidth: 'fill-available',
	},
	labelFull: {
		transform: 'translate(0, 1em) scale(.75)',
	},
	labelEmpty: {
		transform: 'translate(0, 4em) scale(1)',
	},
	labelActive: {
		transform: 'translate(0, 1em) scale(.75)',
		color: theme.palette.primary.dark
	},
	valueClass: {

	}
});

const ContainerWithFields = ({ source, multiFieldIndex = 0, relLinkSource, ...props }) => {
	const fieldNames = [source]
	if (relLinkSource)
		fieldNames.push(`relationships.artworks.ids[${multiFieldIndex}]`)
	return <Fields names={fieldNames} component={RichTextInputWithRelLinks} adminProps={{ ...props, multiFieldIndex, relLinkSource }} />
};

export default compose(
	withStyles(styles)
)(ContainerWithFields);

/**
 * 
 * validation function for <RichTextInputRelLinks> in react-admin <SimpleForm>
 * counts only textual characters and ignores HTML markup
 * 
 * @param {number} maxLength 
 * 
 * @usage

import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";

// ...

<Create>
	<SimpleForm>
		<RichTextInputRelLinks source="description_fr" {...props} validate={[maxLengthRichText(300)]} />
	</SimpleForm>
</Create>

 * 
 */
export const maxLengthRichText = (maxLength) => (value, allValues, props) => {

	const testEl = document.createElement('div')
	testEl.innerHTML = value
	const length = testEl.innerText.length

	if (length > maxLength)
		return props.translate(`validation.richTextLength`, { maxLength, length })
	return undefined
}
