import React from 'react';
import Wave from './wave';
import get from 'lodash/get'

const WaveSurferField = ({record = {}, source, getOnDelete}) => {
	let url = get(record, source + '[0].url');
	let title = get(record, source + '[0].internal_title');
	return (
		<div>
			<Wave url={url} title= {title} getOnDelete={getOnDelete}/>
		</div>
	);

};

export default WaveSurferField
