import React from 'react';
import { Create } from 'react-admin';
import Form from './Form';
import UniqueForm from '../../components/fields/uniqueForm';
import { sanitizeCreateProps } from '../../utils/sanitize';

/**
 * @params 
 * - drawerPath 	prop passed by the <RoutedDrawerView> this <Create> is rendered inside
 * - origin 		prop passed to <GalleryInput> who passes it along to the media <RoutedDrawerView> so that the secondary drawer can open from and return to this origin
 * - redirect		defined in CreateRelationshipsOrderedInput to control what to do after this <SimpleForm> is submitted
 */

const _defaultValue = { active_en: true };

const PossibleAnswerCreate = ({ redirect, toolbar, drawerPath, answer_type, answer_asset_type, defaultValue, ...props }) => {
	const adminProps = sanitizeCreateProps(props)
	return (
		<UniqueForm {...props}>
			{({ formName }) =>
				<Create {...adminProps}>
					<Form
						{...{
							redirect,
							toolbar,
							drawerPath,
							answer_type,
							answer_asset_type,
						}}
						form={formName}
						defaultValue={{ ..._defaultValue, ...defaultValue }}
						parentProps={props}
						view="create"
					/>
				</Create>
			}
		</UniqueForm>
	)
};

export default PossibleAnswerCreate;
