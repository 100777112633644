import React from 'react';
import { Pagination } from 'react-admin';

export const getPerPage = () => +localStorage.getItem('list-items-per-page') || 50

const PostPagination = ({setPerPage, perPage, ...props}) => {

	const localSetPerPage = (value) => {
		localStorage.setItem('list-items-per-page', value);
		setPerPage(value)
	}

	return <Pagination 
		rowsPerPageOptions={[25, 50, 100]} 
		setPerPage={localSetPerPage}
		perPage={getPerPage()}
		{...props} 
	/>
};

export default PostPagination