import React from 'react';
import { connect } from 'react-redux';
import { setListSelectedIds } from 'react-admin';

import RoutedDrawerView from '../routedDrawerView';
import ListBulkActions from './listBulkActions'
import ListActions from './listActions'

const RoutedListDrawer = ({ module, controls, origin, field, maxSelection, formats, setListSelectedIds, selectedIds, ...props }) => {

	const handleRowClick = id => {
		const newIds = [...selectedIds]

		if (newIds.includes(id)) {
			const index = newIds.findIndex(arrayId => arrayId === id)
			newIds.splice(index, 1)
		} else {
			newIds.push(id)
			newIds.sort()
		}
		setListSelectedIds(module.name, newIds)

		return false
	}

	return (
		<RoutedDrawerView reference={module.name} view={'list'} controls={controls} origin={origin} {...props}>
			{({ reduxData, controls, ...listProps }) => (
				<module.list
					{...listProps}
					editInDrawer={true}
					rowClick={handleRowClick}
					filter={{
						...(
							field.input.value && field.input.value.length && { excludeIds: field.input.value.map((element) => element && element.id) }
						)
					}}
					actions={
						<ListActions
							controls={controls}
							module={module}
							{...listProps}
						/>
					}
					bulkActionButtons={
						<ListBulkActions
							controls={controls}
							field={field}
							data={field.input.value}
							reduxData={reduxData}
							maxSelection={maxSelection}
							resource={module.name}
							formats={formats}
							setListSelectedIds={setListSelectedIds}
						/>
					}
				/>
			)}
		</RoutedDrawerView>
	)
}

const mapStateToProps = (state, props) => {
	const selectedIds = state.admin.resources[props.module.name].list.selectedIds
	return { selectedIds }
}

export default connect(mapStateToProps, { setListSelectedIds })(RoutedListDrawer);