import React      from 'react';
import {
	Create,
	SimpleForm,
	FileInput,
}                 from 'react-admin';
import VideoField from '../../components/fields/videoField';
import FullViewportDropzoneWrapper from '../../components/fields/fullViewportDropzoneWrapper';
import UniqueForm from '../../components/fields/uniqueForm';

const defaultValue = {
	active_fr: true,
	active_en: true,
};

const VideoCreate = ({drawerPath, redirect = 'list', toolbar, ...props}) => (
	<UniqueForm {...props}>
		{({ formName }) =>
			<Create {...props} undoable="false">
				<SimpleForm defaultValue={defaultValue} redirect={redirect} form={formName} toolbar={toolbar}>
					<FullViewportDropzoneWrapper>
						<FileInput source="file" accept="video/*" multiple>
							<VideoField source="url" poster="image_url"/>
						</FileInput>
					</FullViewportDropzoneWrapper>
				</SimpleForm>
			</Create>
		}
	</UniqueForm>
);

export default VideoCreate;
