import React from 'react';
import { connect } from 'react-redux';
import { FormDataConsumer } from 'react-admin';
import { change, unregisterField, getFormValues, FormName } from 'redux-form';
import get from 'lodash/get'

/**
 * This component allows for 
 * - hiding / displaying its children 
 * - resetting a field value (only if `source` is defined)
 * both actions are based on either 
 * - provided flag string (as key in `record[flag]`), 
 * - or test function (as `(record) => bool`)
 * 
 * @param {string} source [optional] standard ra source. If undefined, will only trigger display but not reset field.
 * @param {function} test [optional] (formData) => boolean. `true`: display children, `false`: reset field. Defaults to using `flag`
 * @param {string} flag [optional] key to formData value for default `test` props. Uses truthy / falsy coerced value. Overridden if `test` is defined.
 * @param {any} empty [optional] initial (not contributed) value of field, defaults to null
 * 
 * @example

<ConditionalField test={record => record.active_en} source="text_en">
	<TextInput source="text_en"/>
</ConditionalField>

 * 
 * @example

<ConditionalField flag="active_en">
	<TextInput source="text_en"/>
</ConditionalField>

 */

const getDefaultTest = (flag) => (formData) => !!formData[flag]

const ConditionalField = ({ children, test, flag, source, value, form, empty = null, ...props }) => {
	const defaultTest = test || getDefaultTest(flag)
	return (
		<FormDataConsumer {...props}>
			{({ formData, dispatch, ...rest }) => {
				if (!defaultTest(formData)) {
					if (source) {
						if(value !== empty)
							dispatch(change(form, source, empty))
						dispatch(unregisterField(form, source))
					}
					return <></>
				} 
				return <>
					{React.Children.map(children, child => React.cloneElement(child, Object.assign({ ...rest }, child.props)))}
				</>
			}}
		</FormDataConsumer>
	)
}

// get form value for field `source` (needs form name)
const mapStateToProps = (state, props) => {
	if(props.source) {
		const values = getFormValues(props.form)(state)
		return {value: get(values, props.source)}
	}
	return {}
}
const ConnectedConditionalField = connect(mapStateToProps)(ConditionalField)

// get current form name
export default (props) => (
	<FormName>
		{({ form }) => <ConnectedConditionalField form={form} {...props} />}
	</FormName>
)