import React from 'react';
import { Create } from 'react-admin';
import UniqueForm from '../../components/fields/uniqueForm';
import { sanitizeCreateProps } from '../../utils/sanitize';

const DefaultCreate = ({
	Form,
	toolbar,
	drawerPath,
	defaultValue,
	redirect = 'list',
	...props
}) => {
	const adminProps = sanitizeCreateProps(props)
	return (
		<UniqueForm {...adminProps}>
			{({ formName }) =>
				<Create {...adminProps} undoable="false">
					<Form
						{...{
							redirect,
							toolbar,
							drawerPath,
							defaultValue,
						}}
						view="create"
						parentProps={props}
						form={formName}
					/>
				</Create>
			}
		</UniqueForm>
	)
};

export default DefaultCreate