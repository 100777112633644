import frDefaults                   from 'ra-language-french';
import fr, {fallback as frFallback} from './fr';

const getHandler = (fallback) => {
	const getDefaultHandlerFunction = fn => (object, key) => {
		const result = Object.assign({}, fallback, object);
		return Reflect[fn](result, key);
	};
	const handler = {
		get: (object, key) => {
			if (key === '#isProxy') {
				return true;
			}
			if (Reflect.has(object, key)
				&& typeof object[key] === 'object'
				&& object[key] !== null
				&& !object[key]['#isProxy']
			) {
				const proxy = new Proxy(object[key], handler);
				Reflect.set(object, key, {...proxy}); // memoisation of proxy requires to break ref `{...proxy}`
				return proxy;
			}

			return Reflect.has(object, key)
				? Reflect.get(object, key)
				: fallback[key];
		},
		getOwnPropertyDescriptor: getDefaultHandlerFunction('getOwnPropertyDescriptor'),
		ownKeys: getDefaultHandlerFunction('ownKeys'),
	};
	return handler;
};
const getProxy = (dict, fallback) => new Proxy(dict, getHandler(fallback));

const messages = {
	fr: Object.assign({}, frDefaults, getProxy(fr, frFallback)),
};

export default locale => messages[locale];
