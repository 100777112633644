import React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { changeListParams } from 'react-admin'

import { Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';

/**
 * 
 * @TODO Current path matching method won't allow for stacking RoutedDrawers with <Edit> views for the same resource (e.g. .../questions/1/questions/3)
 *
 * @param {Object} controls  			React.useRef object for the parent to access RoutedDrawerView's `open`, `close` and `setPath` functions
 * @param {String} reference 			Resource name as defined in its index.js (e.g. `mediaLibraries`)
 * @param {String} view 				Type of view used as child of RoutedDrawerView ('edit', 'create' or 'list')
 * @param {Object} props	 			Props object as normally passed to an `<Edit>` or a `<Create>` component (should include `basePath`)
 * @param {Number} multiRouteIndex 		[optional] Props useful if you want to use the same Ref for multiple `controls` in multiple `<RoutedDrawerView>` 
 * 										(in which case, the ref's structure should be `React.useRef(types.map(() => ({ current: {} })))`)
 * @param {Number||String} sameRouteKey	[optional] If several Routes declared on the same page will end up w/ the same path, use this prop to differentiate them
 * @param {String} origin				[optional] URL of page to return to when drawer closes
 * @param {Number} editId				[optional] if the drawer contains an <Edit>, this is the ID of the record
 * @param {Function} onOpen				[optional] callback before Drawer opens
 * @param {Function} onClose			[optional] callback after Drawer closes
 * 
 * Details on the `controls` object:
 * @property {Function} open	opens the drawer (if view==='edit', `open` accepts a *required* parameter to indicate which record to fetch in <Edit>)
 * @property {Function} close	closes the drawer
 *
 * @usage the parent / adjacent <SimpleForm> should declare a form name `<SimpleForm form={formName}>` to preserve form data when Drawer opens
 * @example

 import { list as ImageList, name as imageResourceName } from '../MediaLibraries'
 import RoutedDrawerView from '../../components/fields/routedDrawerView'
 
 const ResourceEdit = props => {

	const drawerControls = React.useRef({})

	return <>
		<Button onClick={drawerControls.current.open}>
		<Edit {...props}>
			<SimpleForm form={formName}>
			</SimpleForm>
		</Edit>
		<RoutedDrawerView reference={imageResourceName} view={'list'} controls={drawerControls} {...props}>
			<ImageList/>
		</RoutedDrawerView>
	</>
};

 *
 */

const styles = {
	paper: {
		width: 'calc(100vw - 100px)'
	}
};

const StatefulDrawerWrapper = ({ path, reference, view, controls, drawerOpen, drawerClose, routeProps, state, reduxData, children, classes }) => {
	delete routeProps.staticContext;
	React.useEffect(() => {
		if (state.open !== !!routeProps.match) {
			if(window.ARIA_CONFIG.OWNER === 'Mazarine') 
				console.log(`On MOUNT: setting state of Drawer to ${!state.open} for ${reference}.${view} based on route matching.`)
			state.setOpen(!state.open);
		}
	}, [path])
	const [id, setId] = React.useState(null)
	React.useEffect(() => {
		const newId = routeProps.match && routeProps.match.params && routeProps.match.params[`${reference}Id`]
		if(
			newId 
			&& Number.isInteger(+newId) // match isn't 'create' nor 'list'
			&& view === 'edit' 
			&& id !== newId
		)
			setId(newId)
	}, [routeProps.match])
	
	// Do not mount <Edit> as long as react-routed hasn't returned a matched ID
	const canOpen = view === 'edit' ? !!id : true

	// Set drawerPath to actual path (w/ matched ID) so that RoutedDrawers can stack
	const drawerPath = view === 'edit' 
		? routeProps.match && routeProps.match.url 
		: path

	const {
		hasCreate,
		hasEdit,
		hasList,
		hasShow,
	} = reduxData.props;
	const childProps = {
		...routeProps,
		hasCreate,
		hasEdit,
		hasList,
		hasShow,
		id,
		match: routeProps.match || {},
		basePath: `/${reference}`,
		resource: reference,
		controls,
		title: ' ',
		reduxData,
		drawerPath,
	};
	
	return (
		<Drawer
			open={state.open && canOpen}
			classes={classes}
			ModalProps={{
				onBackdropClick: () => controls.current.close(),
				onEscapeKeyDown: () => controls.current.close(),
			}}
			SlideProps={{
				onEnter: drawerOpen,
				onExited: drawerClose,
			}}
		>
			{typeof children === 'function'
				? children(childProps)
				: React.cloneElement(children, Object.assign({}, childProps, children.props))
			}
		</Drawer>
	);
}

const RoutedDrawerView = ({
	classes,
	push,
	changeListParams,
	reference,
	reduxData,
	controls = { current: null },
	onOpen,
	onClose,
	children,
	basePath,
	id,
	multiRouteIndex,
	sameRouteKey,
	record,
	origin,
	view
}) => {
	const [open, setOpen] = React.useState(false);

	const getRoot = () => {
		// try to get the ID of the record being edited (by the <Edit> view in which RoutedDrawer is included)
		const recordId = record ? record.id : id 
		// if there was no ID, it's because we're in a <Create> view
		const routeId = recordId || 'create' 
		// when stacking multiple RoutedDrawer, we need ability to specify a root url (`origin`)
		return origin ? `${origin}` : `${basePath}/${routeId}`; 
	}

	const getPath = (root, id) => {
		const base = `${root}/${reference}`
		const key = sameRouteKey !== undefined ? `/${sameRouteKey}` : ''
		return view === 'edit'
			// if RoutedDrawer contains an <Edit> view, append /[id] (or var name for react-router matching)
			? `${base}${key}/${id || `:${reference}Id`}` 
			// else append /[view] for proper react-router Route matching 
			: `${base}${key}/${view}`
	}

	const initialRoot = getRoot()
	const root = initialRoot
	const path = getPath(initialRoot)

	// in cases where we need an unknown number of <RoutedDrawer> in the same page (e.g. iterating over array),
	// we can stack fake Ref objects inside a single real Ref object (see doc for `multiRouteIndex` at the top)
	// this is where we handle this case
	const controlsProxy = multiRouteIndex === undefined
		? new Proxy(controls, Reflect)
		: new Proxy(controls, {
			get: (obj, key) => obj.current[multiRouteIndex][key],
			set: (obj, key, value) => obj.current[multiRouteIndex][key] = value,
		});

	controlsProxy.current = {
		open: (id) => {
			push(getPath(root, id));
			setOpen(true);
		},
		close: () => {
			push(root);
			setOpen(false);
		}
	};

	// empty filter parameters for GET_LIST request (only useful when <RoutedDrawer> contains a <List> view)
	const resetFilter = () => { changeListParams(reference, {}) }

	const drawerOpen = () => {
		resetFilter()
		typeof onOpen === 'function' && onOpen();
	}

	const drawerClose = () => {
		resetFilter()
		typeof onClose === 'function' && onClose();
	}

	React.useEffect(resetFilter, [])

	return (
		<Route path={path}>
			{(routeProps) =>
				<StatefulDrawerWrapper
					path={path}
					reference={reference}
					view={view}
					controls={controlsProxy}
					drawerOpen={drawerOpen}
					drawerClose={drawerClose}
					routeProps={routeProps}
					state={{ open, setOpen }}
					reduxData={reduxData}
					children={children}
					classes={classes}
				/>
			}
		</Route>
	);
};

const mapStateToProps = (state, props) => ({
	reduxData: state.admin.resources[props.reference],
});

const mapDispatchToProps = {
	push,
	changeListParams,
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)(RoutedDrawerView);
