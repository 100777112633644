import React from 'react';
import { destroy } from 'redux-form';
import { connect } from 'react-redux';

/**
 * Use this component whenever you need to make sure you have a unique form name that won't conflict with currently submitting forms.
 * 
 * @TODO This component maps state to props on every render. This ends up happening on every form change (i.e. each letter being typed). **This is too heavy.**
 *       Ideally, we'd plug into the @@redux-form/INITIALIZE event and the @@redux-form/SET_SUMBIT_SUCCEEDED actions to always keep a `next-form-name` string for each resource available at all times.
 * 
 * @param {string} resource name of the resource as provided by react-admin
 * @param {number} id [optional] id of the record as provided by react-admin. If none is passed, this component will generate one.
 * 
 * @example

 <UniqueForm {...props}>
	{({ formName }) =>
		<Create {...props}>
			<SimpleForm form={formName}>
			</SimpleForm>
		</Create>
	}
</UniqueForm>

 * 
 */

const UniqueForm = ({ children, formName, destroyables, dispatch, ...props }) => {
	// remove submitted `create-` forms
	React.useEffect(() => {
		if(destroyables)
			destroyables.forEach(name => dispatch(destroy(name)))
	}, [destroyables])
	
	const newProps = {formName, ...props}

	const returnChild = (child, i = 0) => {
		if(typeof child === 'function')
			return <React.Fragment key={i}>{child(newProps)}</React.Fragment>
		else
			return <React.Fragment key={i}>{React.cloneElement(child, Object.assign({}, newProps, child.props))}</React.Fragment>
	}

	if (Array.isArray(children) && children.length)
		return children.map(returnChild)
	else if(children)
		return returnChild(children)
	else 
		return <></>
}

const formIsActive = formState => formState.isSubmitting || typeof formState.submitSucceeded !== 'undefined'

const mapStateToProps = (state, props) => {
	// <UniqueForm> can be bypassed if a `formName` prop is passed
	if(props.formName)
		return {formName: props.formName}

	// should have a resource
	if(!props.resource)
		console.error('<UniqueForm> component should be passed a `resource` string')

	// unique name is immediate
	const id = props.id
	if(id) 
		return { formName: `edit-${props.resource}-${id}` }

	// find first available form name
	const names = Object.keys(state.form).filter(name => name.startsWith(`create-${props.resource}`))
	let availableID = 1
	do {
		const formName = `create-${props.resource}-${availableID}`
		if(!names.includes(formName) || !formIsActive(state.form[formName]))
			break;
	} while (++availableID)

	// find former forms that aren't in use anymore
	const destroyables = names.filter(name => typeof state.form[name].submitSucceeded !== 'undefined')

	return {
		destroyables: destroyables.length ? destroyables : undefined,
		formName: `create-${props.resource}-${availableID}`,
	}
}


export default connect(mapStateToProps)(UniqueForm)