import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	maxLength,
	DateTimeInput,
	required,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid'
import ManyTypesRelationshipsOrderedInput from '../../components/fields/manyTypesRelationshipsOrderedInput';
// import Prompt from '../../components/parts/PromptDirtyForm' // This component isn't ready for prime time
import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";
import FormToolbar from '../../components/parts/FormToolbar';
import PrimaryTagsInput from '../../components/fields/tagFields/primaryTagInput';
import SecondaryTagsInput from '../../components/fields/tagFields/secondaryTagsInput';
import ConditionalField from '../../components/fields/conditionalField';
import { sanitizeFormProps } from '../../utils/sanitize';
import EscapedTextInput from '../../components/fields/escapedTextInput'

const validateText = [maxLengthRichText(500)];
const validateTitle = [maxLength(60)];
const validateReq = [required()];

const _defaultValue = {
	published: false,
	active_en: true,
	is_standalone: false,
	is_highlighted: false,
	is_locked_for_guests: false
};

const Form = ({ view, parentProps, redirect = 'list', defaultValue, ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
		toolbar={<FormToolbar />}
	>
		{/* <Prompt form={formName} /> */}{/* This component isn't ready for prime time */}
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true} />
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>
						<Grid item xs={6}>
							<EscapedTextInput source="slug_fr" disabled />
							<EscapedTextInput source="title_fr" validate={validateTitle} />
							<RichTextInputRelLinks source="description_fr" {...parentProps} validate={validateText} />
							<EscapedTextInput source="opera_show_id" />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="slug_en" disabled />
								<EscapedTextInput source="title_en" validate={validateTitle} />
								<RichTextInputRelLinks source="description_en" {...parentProps} validate={validateText} />
							</ConditionalField>
						</Grid>
					</Grid>
				</Container>
				<Container title={'images'}>
					<GalleryInput type="medias" maxSelection={1} formats={['portrait', 'landscape', 'square', 'fiche', 'vertical']} {...parentProps} />
				</Container>
				<Container title={'modules'}>
					<ManyTypesRelationshipsOrderedInput reference={['questionnaires', 'stories', 'transitions']} />
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<BooleanInput source="published" />
					<BooleanInput source="is_standalone" />
					<BooleanInput source="is_locked_for_guests" />
					<BooleanInput source="is_highlighted" />
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
					<DateTimeInput source="publication_started_at" />
					<DateTimeInput source="publication_ended_at" />
				</Container>

				<Container title={'index'}>
					<Grid >
						<PrimaryTagsInput source="relationships.primaryTags.ids[0]" exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']} />
						<SecondaryTagsInput source="relationships.secondaryTags.ids" exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']} />
					</Grid>
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
);

export default Form;