import React from 'react';

const AudioField = ({record = {}, source}) => {
	return (
		<audio controls src={record[source]}>
			Your browser does not support the <code>audio</code> element.
		</audio>
	);
};

export default AudioField