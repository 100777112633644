import React from 'react';
import { FunctionField } from 'react-admin'

/**
 * 
 * @param {String} sortBy Should be `qty_${source}` so that <Datagrid> can send the proper sort parameters to the API
 * @param {Object} record
 * @param {String} source
 * 
 */

const count = (record, source) => {
	if(record.relationships && record.relationships[source] && record.relationships[source].ids)
		return record.relationships[source].ids.length

	else if(record[source] && Array.isArray(record[source]))
		return record[source].length

	return 0
}

const LengthField = (props) => <FunctionField {...props} render={count} />

export default LengthField
