import React from 'react'
import { Toolbar, SaveButton, Button, translate } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import RoutedDrawerView from '../routedDrawerView';


const ModuleToolbar = translate(({ translate, controls, ...props }) => (
	<Toolbar {...props}>
		<SaveButton />
		<>
			<Button
				label='interface.cancel'
				onClick={() => controls.current.close()}
				style={{ marginLeft: '1em' }}
			>
				<CloseIcon />
			</Button>
		</>
	</Toolbar>
));

export default ({ module, controls, field, viewProps, ...props }) => {

	const redirect = (basePath, id, data) => {
		controls.current.close()
		return false
	};

	return (
		<RoutedDrawerView reference={module.name} view={'edit'} {...props} controls={controls}>
			{({ reduxData, controls, ...routedDrawerProps }) => {
				return (
					<module.edit
						toolbar={<ModuleToolbar controls={controls} />}
						redirect={redirect}
						{...routedDrawerProps}
						{...viewProps}
					/>
				)
			}}
		</RoutedDrawerView>
	)
}