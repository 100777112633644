import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	required,
	DateTimeInput,
	GET_LIST,
	translate,
} from 'react-admin';

import Container from '../../components/parts/Container';
import Grid from '../../components/parts/Grid';
import serverProvider from '../../providers/client';
import ConditionalField from '../../components/fields/conditionalField';
import HeadingView from "../../components/parts/HeadingView";
import EscapedTextInput from '../../components/fields/escapedTextInput';
import { sanitizeFormProps } from '../../utils/sanitize';

const validateReq = [required()];

const normalize = str => {
	if(!str)
		return ''
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()
}

const asyncValidate = (translate) => (values, dispatch, props, blurredFieldName) => {

	const locales = ['fr', 'en']

	const makeCrudPromise = (locale) => {
		const key = `label_${locale}`
		const active = `active_${locale}`

		if (!values[active] || !values[key] || normalize(values[key]) === normalize(props.initialValues[key]))
			return Promise.resolve({ total: 0, locale })

		return serverProvider(GET_LIST, 'tags', {
			filter: { 
				[`qLabel`]: values[key].trim(),
				lang: locale,
			},
		}).then(({ total, data }) => {
			const selfIsListed = data.some(record => record.id === props.record.id)
			return ({ 
				total: total - selfIsListed, 
				locale 
			})
		})
	}

	return Promise.all(locales.map(makeCrudPromise))
		.then(responses => {
			const errors = {}
			responses.forEach(({ total, locale }) => {
				if (total > 0) {
					const key = `label_${locale}`
					errors[key] = translate('validation.tagExists', { label: values[key], locale })
				}
			})
			if (Object.keys(errors).length > 0)
				throw errors
		})
}

const _defaultValue = { active_fr: true, active_en: true, published: true };

const Form = ({ translate, view, parentProps, redirect = 'list', defaultValue, ...props }) => (
	<SimpleForm
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		asyncValidate={asyncValidate(translate)}
		redirect={redirect}
		{...sanitizeFormProps(props)}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>
				<Container title={'info'}>
					<Grid container>
						<Grid item xs={12}>
							<BooleanInput source="active_en" />
						</Grid>
						<Grid item xs={6}>
							<EscapedTextInput source="label_fr" validate={validateReq} />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField source="label_en" flag="active_en">
								<EscapedTextInput source="label_en" validate={validateReq} />
							</ConditionalField>
						</Grid>

					</Grid>
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<BooleanInput source="published" />
					<BooleanInput source="is_enabled_for_bot" />
					<BooleanInput source="is_shown_on_grid" />
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
)

export default translate(Form);