import React from 'react';

import {
	List,
	Datagrid,
	TextInput,
	NullableBooleanInput,
	BooleanField,
	translate,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';

import { Filter } from 'react-admin';

import InListBooleanInput from '../../components/fields/inlistBooleanInput';
import LengthField from '../../components/fields/lengthField';
import FilterTagInput from '../../components/fields/tagFields/filterTagInput';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
	...theme.filters.tags,
})

const PostFilter = withStyles(filterStyles)(({classes: {container, field, ...classes}, ...props}) => (
	<Filter {...props} className={container}>
		<TextInput source="fInternalTitle" alwaysOn className={field}/>
		<NullableBooleanInput label="published" source="fPublished"  style={{"minWidth":"250px"}} alwaysOn className={field}/>
		<FilterTagInput label="tag_search" source="qTags" reference="tags" alwaysOn classes={classes}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const SessionListGrid = compose(
	translate,
	withStyles(gridStyles),
)(({classes, translate, ...props}) => {
	return <Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<BooleanField source="is_locked_for_guests" />
		<BooleanField source="is_highlighted" />
		<BooleanField source="is_standalone" />
		<InListBooleanInput source="published" />
		<LengthField source="questionnaires" sortBy="qty_questionnaires" label={translate('interface.numberof', { resource: translate('resources.questionnaires.name') })} />
		<LengthField source="stories" sortBy="qty_stories" label={translate('interface.numberof', { resource: translate('resources.stories.name') })} />
		<LengthField source="transitions" sortBy="qty_transitions" label={translate('interface.numberof', { resource: translate('resources.transitions.name') })} />
		<DateTimeField source="updated_at" />
	</Datagrid>
})


const SessionList = ({ drawerPath, rowClick = 'edit', ...props }) => (
	<List
		filters={<PostFilter />}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<SessionListGrid rowClick={rowClick}/>
	</List>
);

export default SessionList;
