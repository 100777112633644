import React from 'react';
import { Button } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import { translate } from 'react-admin';

const SelectButton = ({ icon = <AddIcon/>, translate, options = [], disabled }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = event => {
		if(options.length === 1)
			options[0].handleClose()
		else
			setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				label={'interface.add'}
				variant="contained" 
				color="secondary"
				aria-owns={anchorEl ? 'simple-menu' : null}
				aria-haspopup="true"
				onClick={handleClick}
				disabled={disabled}
			>
				{icon}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{options.map((option, i) =>
					<MenuItem
						key={i}
						onClick={() => {
							handleClose()
							option.handleClose()
						}}
					>
						{translate(`resources.${option.text}.name`)}
					</MenuItem>
				)}
			</Menu>
		</div>
	);
}

export default translate(SelectButton);