import { RELATIONSHIPS } from '../constants'
import { getApiUrl, convertHTTPResponse, parseHomepageHighlights, getNewValues, getPreviousValue, compareValues, getApiObjectKey, makeMediaObject, makeLibrariesPatch, makePostDataRequest } from '../utils'
import tokenFetcher from '../tokenFetcher'
import { GET_ONE } from 'react-admin'

export default (httpClient, serverProvider) => {

	const convertDataRequestToHTTP = (resource, params) => {
		// we maybe should re-implement this function where we manually add **all** of the PATCH keys to avoid sending unexpected data
		const payload = { ...params.data }; // need cloning otherwise will affect actual form content

		// Converting audio payload field to melody when question is Melody
		if (payload?.melody){
			payload.audios = payload.melody
			delete payload.melody
		}

		if (payload.pictures && payload.pictures.length)
			console.warn('UPLOADING OF FILES ISNT HANDLED IN "UPDATE"');

		// re-map relationships
		const relationships = payload.relationships
			? Object.keys(payload.relationships)
			: [];

		relationships.forEach(rel => {
			const newValues = getNewValues(payload, rel, resource)
			const prevValue = getPreviousValue(params.previousData, rel, resource)
			const shouldPatch = !compareValues(newValues, prevValue, rel, resource)
			const empty = newValues === false

			if (payload[rel])
				delete payload[rel]

			if (shouldPatch) {
				const key = getApiObjectKey(rel, resource)
				payload[key] = empty ? [] : newValues
			}
		})

		// [ARIA-660] remove the tags_id from all patch method
		if (payload.tags_id)
			delete payload.tags_id

		if (payload.relationships)
			delete payload.relationships
		if (payload.modules)
			delete payload.modules

		// re-build image object
		if (payload.medias) {
			const newMedias = makeMediaObject(payload);
			if (newMedias)
				payload.medias = newMedias;
			else
				delete payload.medias
		}

		// rebuild other library objects
		['audios', 'videos', 'slideshows'].forEach(res => {
			if (payload[res]) {
				const newRes = makeLibrariesPatch(payload, params.previousData, res)
				if (newRes)
					payload[res] = newRes
				else
					delete payload[res]
			}
		})

		// in order to display a 'custom' value in the Select (not accepted by the API), we just remove it here
		if(payload.duration_type === 'custom' && (resource === 'cards' || resource === 'questions')) {
			payload.duration_type = 'short'
		}
		// in order to put a null value in duration_type corresponding  on 'none' value, (not accepted by the API), we just add it here
		if(payload.duration_type === 'none' && (resource === 'cards' || resource === 'questions')) {
			payload.duration_type = null
		}

		// remove keys added by react-admin || dataProvider
		if (payload.hasOwnProperty('file') && payload.file.hasOwnProperty('url')) {
			payload.url = payload.file.url;
		}

		// remove ignored / individually-handled keys
		if (payload.file) delete payload.file;
		if (payload.score_max) delete payload.score_max;
		if (payload.subtitle_file_url_fr) delete payload.subtitle_file_url_fr;
		if (payload.subtitle_file_url_en) delete payload.subtitle_file_url_en;

		// remove keys that haven't changed (make use of PATCH method)
		// with this method, values of type 'Object' will remain (unless they actually are the same pointers which can happen w/ redux-form)
		// this is fine because objects should probably be rebuilt anyway (medias & relationships)
		Object.keys(payload)
			.filter(attribute => payload[attribute] === params.previousData[attribute])
			.forEach(attribute => delete payload[attribute]);

		if (Object.keys(payload).length === 0)
			return { empty: true }

		const options = {};
		options.method = 'PATCH';
		options.body = JSON.stringify(payload);
		options.headers = tokenFetcher.getHeaders();
		return {
			url: `${getApiUrl()}/${resource}/${params.id}?mode=contribution`,
			options
		};
	}

	const updateHomepageHighlights = (resource, params) => {
		const newData = { ...params.data };
		const payload = {}

		RELATIONSHIPS[resource].forEach(rel => {
			const newValues = getNewValues(newData, rel, resource)
			const key = getApiObjectKey(rel, resource)
			const empty = newValues === false
			payload[key] = empty ? [] : newValues
		})

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: tokenFetcher.getHeaders(),
		};
		const url = `${getApiUrl()}/${resource}?mode=contribution`

		return httpClient(url, options).then(({ json }) => parseHomepageHighlights(json))
	}

	const updateInstantWinnerDates = async (resource, params) => {
		const uploadRequest = makePostDataRequest(
			httpClient,
			`instantWinnerDates/${params.data.id}/rules/upload`,
			params,
			[['rules_file_url_fr', 'file_fr'], ['rules_file_url_en', 'file_en']]
		)
		return uploadRequest.then(() => updateDefault(resource, params))
	}

	const updateVideoLibraries = async (resource, params) => {
		const uploadRequest = makePostDataRequest(
			httpClient,
			`videoLibraries/${params.data.id}/subtitles/upload`,
			params,
			[['subtitle_file_url_fr', 'file_fr'], ['subtitle_file_url_en', 'file_en']]
		)
		return uploadRequest.then(() => updateDefault(resource, params))
	}

	const updateDefault = (resource, params) => {
		const { url, options, empty } = convertDataRequestToHTTP(resource, params);
		if (empty)
			return serverProvider(GET_ONE, resource, { id: params.id });
		return httpClient(url, options).then(response => convertHTTPResponse(response, resource, params));
	}

	return (resource, params) => {

		if (resource === 'homepageHighlights')
			return updateHomepageHighlights(resource, params)

		if (resource === 'instantWinnerDates')
			return updateInstantWinnerDates(resource, params)

		if (resource === 'videoLibraries')
			return updateVideoLibraries(resource, params)

		return updateDefault(resource, params)
	}

}
