import React from 'react';
import {
	SimpleForm,
	required,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import GalleryInput from "../../components/fields/galleryInput";
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const _defaultValue = { active_fr: true, active_en: true };
const validateReq = [required()];

const Form = ({ view, parentProps, redirect = 'list', defaultValue, ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		redirect={redirect}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={12}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
				</Grid>

				<Container title={'images'}>
					<Grid item xs={12}>
						<GalleryInput type="medias" maxSelection={6} formats={['portrait', 'landscape']} {...parentProps} />
					</Grid>
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
);

export default Form;

