import React from 'react';

import {
	List,
	Datagrid,
	Filter,
	TextInput,
	RichTextField,
	NullableBooleanInput,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import InListBooleanInput from "../../components/fields/inlistBooleanInput";
import HasItemsField from '../../components/fields/hasItemsField';
import { withStyles } from '@material-ui/core';
import DateTimeField from '../../components/fields/dateTimeField';
import FilterTagInput from '../../components/fields/tagFields/filterTagInput';
import EscapedTextField from '../../components/fields/escapedTextField';


const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
	...theme.filters.tags,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<TextInput label='title_search' source="fTitle" alwaysOn className={classes.field}/>
		<NullableBooleanInput label='published' source="fPublished" alwaysOn className={classes.field}/>
		<FilterTagInput label="tag_search" source="qTags" reference="tags" alwaysOn classes={classes}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const AnecdoteGrid = withStyles(gridStyles)(({classes, ...props}) => (
	<Datagrid {...props} rowClick="edit" >
		<EscapedTextField source="id"/>
		<EscapedTextField source="title_fr" className={classes.mainCell}/>
		<RichTextField source="description_fr" className={classes.abbrev}/>
		<InListBooleanInput source="published"/>
		<HasItemsField source="medias" />
		<HasItemsField source="videos" />
		<HasItemsField source="audios" />
		<DateTimeField source="updated_at" />
	</Datagrid>
))

const AnecdoteList = (props) => (
	<List
		filters={<PostFilter/>}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<AnecdoteGrid/>
	</List>
);

export default AnecdoteList;
