import React from 'react';

import {
	List,
	Datagrid,
	RichTextField,
	TextInput,
	NullableBooleanInput,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import {Filter}  from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import HasItemsField from "../../components/fields/hasItemsField";


const filterStyles = theme => ({
	container: theme.filters.container,
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
		<NullableBooleanInput label='published' source="fPublished" alwaysOn className={classes.field}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const ArtworkGrid = withStyles(gridStyles)(({classes, ...props}) => (
	<Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<RichTextField source="description_fr" className={classes.abbrev}/>
		<HasItemsField source="medias" />
		<HasItemsField source="videos" />
		<HasItemsField source="audios" />
		<HasItemsField source="slideshows" />
		<HasItemsField source="iframe_url" />
		<DateTimeField source="updated_at"/>
	</Datagrid>
))

const ArtworkList = ({ drawerPath, rowClick = 'edit', ...props}) => (
	<List
		  filters={<PostFilter/>}
		  exporter={false}
		  {...props}
		  perPage={getPerPage()}
		  pagination={<PostPagination />}
	>
		<ArtworkGrid rowClick={rowClick}/>
	</List>
);

export default ArtworkList;
