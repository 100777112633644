import { stringify } from 'query-string';
import { getApiUrl, parseRecord } from '../utils'
import { RELATIONSHIPS } from '../constants';

export default (httpClient, serverProvider) => {

	const convertDataRequestToHTTP = (resource, {pagination = {}, sort = {}, filter = {}, params = {}}) => {
		const { page = 1, perPage = 1 } = pagination;
		const { field = 'id', order = 'DESC' } = sort;
		const isQtyField = field.startsWith('qty_')
		const isRelField = ['medias', 'videos', 'audios', 'slideshows', ...RELATIONSHIPS[resource]].includes(field)
		const sortField = isQtyField ? field.substring(4) : field
		const sortKey = isQtyField || isRelField
			? 'relSortByQuantity' 
			: 'sort'
		const { excludeIds, qTags, q, ...otherFilters } = filter
		const query = {
			...(q && { fLabel: q }),
			...(excludeIds && excludeIds.length && { excludeIds: excludeIds.filter(id => !!id).join(',') }),
			...(qTags && qTags.length && {'qTags.id': qTags.join(',')}),
			...otherFilters,
			[sortKey]: sortField,
			sortOrder: order,
			per_page: perPage,
			page,
			...params,
		};
		const url = `${getApiUrl()}/${resource}?${stringify(query)}&mode=contribution`;
		return { url };
	}

	const convertHTTPResponse = (response, resource, params) => {
		const { json } = response;
		const result = {
			data: json.data,
			total: json.meta.total
		};
		result.data.map(record => parseRecord(resource, record));
		return result;
	}

	return (resource, payload) => {
		const { url, options } = convertDataRequestToHTTP(resource, payload);
		return httpClient(url, options).then(response => convertHTTPResponse(response, resource, payload));
	}
}