import React from 'react';
import { ReferenceField } from 'react-admin';
import DragAndDropContainer from '../dragAndDropArrayInput';
import RoutedEditDrawer from './routedEditDrawer';
import { Edit as EditIcon } from '@material-ui/icons';

const onOrder = (field, orderedRecords) => {
	const newValue = { ids: [], positions: [] }
	orderedRecords.forEach((item, i) => {
		newValue.ids.push(item.id)
		newValue.positions.push(i + 1);
	})
	field.input.onChange(newValue);
};

const onClickRemove = (field, id, setItemList) => {
	const oldValue = field.input.value || { ids: [], positions: [] };
	const removeIndex = oldValue.ids.findIndex(item => item === id);
	const newValue = {
		ids: [...oldValue.ids],
		positions: [...oldValue.positions],
	}
	newValue.ids.splice(removeIndex, 1);
	newValue.positions.splice(removeIndex, 1);
	field.input.onChange(newValue);
	setItemList(newValue)
};

const onClickEdit = (controls, id) => {
	controls.current.open(id)
}

const DraggableInnerComponent = ({ meta, id, module, itemProps, ...props }) => {

	return <>
		<ReferenceField {...props} record={{ item_id: id }} source="item_id" reference={module.name} linkType={false}>
			<module.item resource={module.name} {...itemProps} />
		</ReferenceField>
	</>
};

export default ({ module, field, itemList, setItemList, viewProps, itemProps, ...props }) => {
	const controls = React.useRef({});
	return <>
		<DragAndDropContainer
			onOrderChange={orderedRecords => onOrder(field, orderedRecords)}
			onClickRemove={({ id }) => onClickRemove(field, id, setItemList)}
			onClickEdit={({ record, source, id }) => onClickEdit(controls, id)}
			childrenData={itemList.map(item => ({
				meta: item,
				uniqueId: item.id,
				source: module.name,
				id: item.id,
				component: <DraggableInnerComponent {...props} id={item.id} itemProps={itemProps} module={module} />,
				icon: module.icon && <module.icon />,
				editIcon: <EditIcon />,
				valid: item.valid,
				disableEdit: !item.valid
			})
			)}
		/>
		<RoutedEditDrawer {...props} viewProps={viewProps} module={module} controls={controls} field={field} />
	</>
}