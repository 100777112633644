import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	ArrayInput,
	NumberInput,
	ReferenceInput,
	AutocompleteInput,
	translate,
} from 'react-admin';

import SimpleFormIterator from '../../components/fields/simpleFormIterator'
import GalleryInput from '../../components/fields/galleryInput'
import HeadingView from "../../components/parts/HeadingView";
import Grid from "../../components/parts/Grid";
import Container from "../../components/parts/Container";
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const _defaultValue = { 
	active_fr: true,
	active_en: true,
	published: false,
	type: 'thematic'
};

const Form = ({ translate, view, parentProps, defaultValue, redirect = "list", ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true} />
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>
						<Grid item xs={6}>
							<EscapedTextInput source="label_fr" />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="label_en" />
							</ConditionalField>
						</Grid>
						<Grid item xs={12}>
							<ArrayInput source="rules">
								<SimpleFormIterator>
									<NumberInput source="quantity" label={translate('interface.numberof', { resource: translate('resources.sessions.name') })} />
									<ReferenceInput
										source="tag_id"
										reference="tags"
										filter={{ published: true }}
										label="tags"
										sort={{ field: 'label', order: 'ASC' }}
									>
										<AutocompleteInput optionText="label_fr" />
									</ReferenceInput>
								</SimpleFormIterator>
							</ArrayInput>
						</Grid>
					</Grid>
				</Container>
				<Container title={'image'}>
					<GalleryInput type="medias" maxSelection={1} formats={['square']} {...parentProps} />
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<BooleanInput source="published" />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
)

export default translate(Form);
