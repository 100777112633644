import React                      from 'react';
import classnames                 from 'classnames';
import {NavLink}                  from 'react-router-dom';
import MenuItem                   from '@material-ui/core/MenuItem';
import {withStyles, createStyles} from '@material-ui/core/styles';

const styles = theme =>
	createStyles({
		root: {
			color: theme.palette.text.secondary,
			display: 'flex',
			alignItems: 'center',
		},
		active: {
			color: theme.palette.text.primary,
		},
		icon: {
			paddingRight: '1.2em',
			display: 'flex',
		},
		content: {
			flex: 1,
		}
	});

const MenuItemLink = ({
						  classes,
						  className,
						  primaryText,
						  leftIcon,
						  staticContext,
						  onClick,
						  ...props
					  }) => {

	const handleMenuTap = e => {
		onClick && onClick(e);
	};

	return (
		<MenuItem
			className={classnames(classes.root, className)}
			activeClassName={classes.active}
			component={NavLink}
			{...props}
			onClick={handleMenuTap}
		>
			{leftIcon && (
				<span className={classes.icon}>
					{React.cloneElement(leftIcon, {titleAccess: primaryText})}
				</span>
			)}
			<span className={classes.content}>{primaryText}</span>
		</MenuItem>
	);
};

export default withStyles(styles)(MenuItemLink);
