import React from 'react';
import { Edit } from 'react-admin';
import Form from './Form'
import { sanitizeEditProps, filterUndefinedValues } from '../../utils/sanitize';

/**
 * @params 
 * - drawerPath 	prop passed by the <RoutedDrawerView> this <Edit> is rendered inside
 * - origin 		(is this still the case?) prop passed to <GalleryInput> who passes it along to the media <RoutedDrawerView> so that the secondary drawer can open from and return to this origin
 * - redirect		defined in CreateRelationshipsOrderedInput to control what to do after this <SimpleForm> is submitted
 */

const PossibleAnswerEdit = ({ redirect, toolbar, drawerPath, answer_type, answer_asset_type, ...props }) => {
	const formName = `edit-${props.resource}-${props.id}`;
	const adminProps = sanitizeEditProps(props)
	const formProps = filterUndefinedValues({
		redirect,
		toolbar,
		drawerPath,
		answer_type,
		answer_asset_type,
	})
	return (
		<Edit {...adminProps} undoable={false}>
			<Form
				{...formProps}
				form={formName}
				parentProps={props}
				view="edit"
			/>
		</Edit>
	)
};

export default PossibleAnswerEdit;