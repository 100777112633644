// Data-display components for <list> view
import React from 'react';
import {
	List,
	Datagrid,
	CreateButton,
	CardActions,
	Filter,
	TextInput,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import AudioField from '../../components/fields/audioField';
import EditMediaInDrawer, { getOpen } from '../../components/fields/editMediaInDrawer';
import { name, edit } from './'
import { withStyles } from '@material-ui/core';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';

const ListActions = ({ basePath }) => {
	return (
		<CardActions>
			<CreateButton basePath={basePath} />
		</CardActions>
	);
};

const EditField = ({ getOpen, record }) => {
	return (
		<IconButton onClick={getOpen(record.id)} color="primary">
			<EditIcon />
		</IconButton>
	)
}

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({ classes, ...props }) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const AudioList = ({ classes, filter, drawerPath, rowClick = 'edit', editInDrawer = false, ...props }) => {

	const controls = React.useRef({})
	const getOpenWithControls = getOpen(controls)

	return (
		<>
			<List
				actions={<ListActions />}
				filters={<PostFilter />}
				filter={filter}
				exporter={false}
				{...props}
				perPage={getPerPage()}
				pagination={<PostPagination />}
			>
				<Datagrid rowClick={rowClick}>
					<EscapedTextField source="id"/>
					<EscapedTextField source="internal_title" className={classes.mainCell}/>
					<EscapedTextField source="artist" />
					<EscapedTextField source="legend_fr" className={classes.abbrev}/>
					<AudioField source="url" label="resources.audioLibraries.fields.preview" />
					<DateTimeField source="updated_at" />
					{editInDrawer &&
						<EditField getOpen={getOpenWithControls} />
					}
				</Datagrid>
			</List>
			{editInDrawer &&
				<EditMediaInDrawer name={name} edit={edit} controls={controls} drawerPath={drawerPath} />
			}
		</>
	);
};

export default withStyles(gridStyles)(AudioList)