import React from 'react';
import {
	Button,
	translate,
} from 'react-admin';

import { withStyles } from '@material-ui/core';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Checkbox from '@material-ui/core/Checkbox';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get'

/**
 * *****************************
 * Using <MediaGrid/> on its own
 * 
 * @param {Function} rowClick [optional] function to execute when a tile from the grid is clicked. If not set, tiles are links to the resources they display.
 * @param {String} imageSource [optional] string path in the `record` object to the url of the image to display as the background of the tile. Defaults to "file.url"
 * @param {String} titleSource [optional] string path in the `record` object to the main text of the tile. Defaults to "internal_title" || "title_fr"
 * @param {String} subtitleSource [optional] string path in the `record` object to the secondary text of the tile. Defaults to "artist"
 * 
 * @example

 <MediaGrid 
	rowClick={rowClick} 
	imageSource="file.url"
	titleSource="title_fr"
	subtitleSource="artist"
/>

 * **********************************************
 * Using <MediaGrid/> w/ <MediaGridToggleButton/>
 * 
 * @usage your <List> should bear a state to toggle between <MediaGrid> and another records iterator (like <Datagrid>).
 * This state and its setter need to be passed down to `<MediaGridToggleButton/>`
 * In addition, it seems that <List>, <MediaGrid> and <Datagrid> all need to have `key` set. See example below.
 * 
 * @example

const MediaLibrarieList = props => {

	const [grid, setGrid] = React.useState(getMediaGridStorage())
	
	const ListActions = () => (
		<CardActions>
			<MediaGridToggleButton grid={grid} setGrid={setGrid}/>
		</CardActions>
	);

	return <List
		key={`hacky-key-${grid}`}
		actions={<ListActions/>}
		{...props}
	>
		{grid 
			? <MediaGrid key="mediagrid" />
			: <Datagrid key="datagrid"></Datagrid>
		}
	</List>
};

 * ***************************************************************************
 * Using <MediaGrid/> w/ <MediaGridToggleButton/> within a <RoutedDrawerView/>
 * 
 * @usage RoutedDrawerView usually displays the <List> with a custom <CardActions>. Yet if you want to still access the toggle from within these custom actions,
 * you need to pass down the toggle state and its setter as props to the `{actions}` component. You'll also need a default <CardsActions> in case no `{actions}` is passed.
 * 
 * @example

const DefaultListActions = ({grid, setGrid}) => (
	<CardActions>
		<MediaGridToggleButton grid={grid} setGrid={setGrid}/>
	</CardActions>
);

const MediaLibrarieList = ({drawerPath, actions, ...props}) => {

	const [grid, setGrid] = React.useState(getMediaGridStorage())

	const StatefulListActions = React.cloneElement(actions || <DefaultListActions/>, {grid, setGrid})

	return <List
		key={`hacky-key-${grid}`}
		actions={StatefulListActions}
		{...props}
	>
		{grid 
			? <MediaGrid key="mediagrid" />
			: <Datagrid key="datagrid"></Datagrid>
		}
	</List>
};

 * 
 */

export const getMediaGridStorage = () => localStorage.getItem('media-grid-display-type');

export const MediaGridToggleButton = translate(({ translate, grid, setGrid }) => {
	const toggleDisplay = () => {
		localStorage.setItem('media-grid-display-type', grid);
		setGrid(grid => !grid);
	};
	return (
		<Button
			label={translate(grid ? 'interface.toggleList' : 'interface.toggleGrid')}
			onClick={toggleDisplay}
		>
			{grid
				? <ViewListIcon />
				: <ViewModuleIcon />
			}
		</Button>
	)
})

const MediaCard = ({
	checked: initialChecked,
	rowClick,
	record,
	basePath,
	classes,
	onToggleItem,
	imageSource,
	titleSource,
	subtitleSource,
	editInDrawer,
	getOpen,
}) => {

	const [checked, setChecked] = React.useState(initialChecked)
	React.useEffect(() => {
		setChecked(initialChecked)
	}, [initialChecked])

	const handleToggle = e => {
		setChecked(!checked)
		onToggleItem(record.id);
		e.stopPropagation();
	};

	const hasHandleClick = typeof rowClick === 'function'
	const handleClick = e => {
		if (hasHandleClick) {
			onToggleItem(record.id);
			rowClick(record.id, basePath, record);
			e.stopPropagation();
			e.preventDefault();
		}
	}

	const src = get(record, imageSource || 'file.url')

	const titleBarProps = {
		title: titleSource ? get(record, titleSource) : (record['internal_title'] || record['title_fr']),
		subtitle: <span>— {subtitleSource ? get(record, subtitleSource) : record['artist']}</span>,
		...(editInDrawer && {
			actionPosition: 'right',
			actionIcon: <IconButton onClick={getOpen(record.id)} color="primary">
				<EditIcon />
			</IconButton>
		}),
	}

	return (
		<GridListTile
			component={hasHandleClick ? 'span' : Link}
			key={record.id}
			to={linkToRecord(basePath, record.id)}
			className={classes.tile}
			onClick={handleClick}
		>
			<Checkbox
				color="primary"
				classes={{
					root: classes.checkbox,
					checked: classes.checked,
				}}
				checked={checked}
				onClick={handleToggle}
			/>
			<img className={classes.img} src={src} alt=""/>
			<GridListTileBar {...titleBarProps} />
		</GridListTile>
	);
};

const MediaGrid = function ({ classes, selectedIds, ids, data, ...props }) {

	return (
		<div className={classes.root}>
			<MuiGridList
				cellHeight={180}
				cols={4}
				className={classes.gridList}
			>
				{ids.map(id => {
					const record = data[id]
					return <MediaCard
						key={id}
						record={record}
						classes={classes}
						{...props}
						checked={selectedIds ? selectedIds.includes(record.id) : record.selected}
					/>
				})}
			</MuiGridList>
		</div>
	);
}

const styles = (theme) => {
	const margin = '10';
	const count = 6;
	return {
		root: {
			padding: `${margin}px`,
		},
		gridList: {
			margin: `${margin}px`,
			display: 'grid',
			gridGap: `${margin}px`,
			justifyContent: 'space-around',
			gridTemplateColumns: `repeat(auto-fill, minmax(auto, calc(calc(100% - calc(${margin}px * calc(${count} - 1))) / ${count})))`,
			gridAutoFlow: 'dense',
		},
		img: {
			width: '100%',
			height: '100%',
			transform: 'scale(1) translateY(-50%)',
			'objectFit': 'cover',
			transition: 'transform .2s',
			transformOrigin: '7em calc(-50% + 7em)',
		},
		tile: {
			cursor: 'pointer',
			height: '100%',
			'&:hover': {
				'& $checkbox': {
					opacity: 1,
				}
			}
		},
		icon: {
			color: 'white'
		},
		checkbox: {
			position: 'absolute',
			left: 0,
			zIndex: 1,
			transition: 'background-color .2s, opacity .2',
			backgroundColor: '#00000055',
			color: 'white',
			opacity: 0,
			'&:hover': {
				backgroundColor: '#00000055',
			},
			'&$checked': {
				opacity: 1,
				'& + img': {
					transform: 'scale(.8) translateY(-50%)',
				}
			}
		},
		checked: {} // reserved for `checkbox` state
	};
};

const mapStateToProps = (state, props) => {
	return {
		selectedIds: state.admin.resources[props.resource].list.selectedIds
	}
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
)(MediaGrid)