/**
 * @param {string} str aspect ratio string from the formats in the APIs settings, format is "width/height" w/ both values being integers
 * @return {float} parsed float value of this ratio
 * 
 * ⚠️ the formats settings can contain `null` as an aspect ratio (for the `free` format). This case isn't handled here.
 */

export default str => {
	const array = str.split('/').map(num => parseInt(num, 10));
	return array[0] / array[1];
};