import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import compose from 'recompose/compose';
import { translate } from 'react-admin';

import {withStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ImageCropsInput from './imageCropsInput';

/**
 * 
 * @param {Object} format subset of redux `settings.cloudinary.images_definition` store
 * @param {Object} record default react-admin record object
 * @param {String} source default react-admin source string, probably should be of type `medias[0]`
 * @param {Object} input  [optional] object from redux-form's `<Field>` prop: `field.input` (if no input is passed, this component will create it's own)
 * 
 * 
 * @example

<MultiFormatsImageCropsInput 
	source={`medias[${i}]`} 
	record={record} 
	formats={formats} 
	input={field.input} 
/>

 * 
 */

const findFormatInRecord = (format, record) => {
	if (!record.formats) {
		return {};
	}

	const foundFormat = record.formats.find(crop => crop && Object.keys(crop)[0] === format);
	return foundFormat === undefined ? {} : foundFormat[format];
};

const getFormatIndexesFromRecord = (formats, record) => {
	/**
	 * order of formats in data received from API is unknown, but we need to edit the proper form fields
	 * this function finds which indexes are already occupied by API response and assigns remaining indexes to remaining undeclared formats
	 */
	const possibleFormatIndexes = Object.keys(formats).map((format, i) => i);

	const formatIndexes = {};
	if (record.formats) {
		record.formats.forEach((object, i) => {
			const format = Object.keys(object)[0];
			formatIndexes[format] = i;
			const indexIndex = possibleFormatIndexes.findIndex(index => index === i);
			possibleFormatIndexes.splice(indexIndex, 1);
		});
	}
	Object.keys(formats).forEach(format => {
		if (typeof formatIndexes[format] === 'undefined') {
			formatIndexes[format] = possibleFormatIndexes.length ? possibleFormatIndexes.shift() : Object.keys(formatIndexes).length;
		}
	});
	return formatIndexes;
};


class MultiFormatsImageCropsInput extends React.Component {
	// react-admin type of component (can be directly plugged into react-admin forms)
	// and redux-form Field implementation

	/*
	 * 
	 * here a class component is required because we need to have the ability to 
	 * define the multiple <CropsInput/> outside of the render() while still
	 * having access to the props necessary for their declaration
	 * 
	 */

	constructor(props) {
		super(props);
		const { formats } = this.props;
		this.inputs = {}
		Object.keys(formats).forEach(format => this.inputs[format] = this.getCropsInput(format));
	}

	getReactCropInlineStyle () {
		const {height, width} = this.props.record
		const maxWidth = Math.min(width/height, 1) * 100

		return {
			image: {},
			wrapper: {
				maxWidth: `${maxWidth}%`,
			}
		}
	}

	getCropsInput(format) {
		const { translate, formats, record, classes } = this.props;
		const {
			crop_h: height,
			crop_w: width,
			crop_x: x,
			crop_y: y,
		} = findFormatInRecord(format, record);
		const initialCrop = format === 'free' ? { height, width, x, y } : { height, x, y };

		return ({ field, onChange, onBlur, onFocus }) => {
			return (
				<Grid item xs={6} classes={{item: classes.gridItem}}>
					<p className={classes.reactCropFormat}>{translate(`interface.formats.${format}`)} {formats[format].aspectRatio}</p>
					<ImageCropsInput
						src={record.url}
						aspectRatio={formats[format].aspectRatio}
						cropInPixels={initialCrop}
						onChange={field ? field.input.onChange : onChange}
						onFocus={field ? field.input.onFocus : onFocus}
						onBlur={field ? field.input.onBlur : onBlur}
						classes={classes.reactCropImage}
						inlineStyle={this.getReactCropInlineStyle()}
						size={{ width: record.width, height: record.height }}
					/>
				</Grid>
			)
		};
	}

	render() {
		const { formats, record, source, classes, input } = this.props;
		const formatIndexes = getFormatIndexesFromRecord(formats, record);
		return (
			<div className={classes.reactCropContainer}>
				<Grid container>
					{Object.keys(formats).map((format, i) => {
						if (input) {
							// use a passed down onChange function (from a Field on 'medias')
							const onChange = (newValue) => {
								const updatedValue = input.value
								const imageIndex = input.value.findIndex(image => image && image.id === record.id)
								const formatIndex = formatIndexes[format]
								if(!updatedValue[imageIndex].formats[formatIndex])
									updatedValue[imageIndex].formats[formatIndex] = {}
								updatedValue[imageIndex].formats[formatIndex][format] = newValue
								input.onChange(updatedValue)
							}
							const Input = this.inputs[format]
							return <Input key={format} {...input} onChange={onChange}/>

						} else {
							// create fields here, within the component
							return <Field
								key={format}
								name={`${source}.formats[${formatIndexes[format]}].${format}`}
								component={this.inputs[format]}
							/>
						}
					})}
				</Grid>
			</div>
		);
	}
}

const DEBUG_HACK_REDUX_INITIAL_STATE = {
	settings: {
		cloudinary: {
			images_definition: {
				square: { aspectRatio: '1/1' },
				landscape: { aspectRatio: '16/9' },
				portrait: { aspectRatio: '9/16' },
				fiche: { aspectRatio: '4/3' },
				vertical: { aspectRatio: '4/5' },
				free: { aspectRatio: null },
			}
		},
	}
}

const mapStateToProps = (state, props) => {
	let formats
	const storeFormats = DEBUG_HACK_REDUX_INITIAL_STATE.settings.cloudinary.images_definition
	if (props.formats) {
		formats = {}
		props.formats.forEach(format => formats[format] = storeFormats[format])
	} else
		formats = storeFormats
	return ({ formats })
}

const styles = theme => createStyles({
	gridItem: {
		padding: '0 10px',
		textAlign: 'center',
	},
	reactCropImage: {},
	reactCropFormat: {
		'textAlign': 'center'
	},
	reactCropContainer: {},
	'@global': {
		'.ReactCrop__crop-selection': {
			boxShadow: `0 0 0 9999em ${theme.palette.primary.transparency}`
		}
	}
});

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	translate,
)(MultiFormatsImageCropsInput);
