import React from 'react';

import {
	List,
	Datagrid,
	LongTextInput,
	Filter,
	NullableBooleanInput,
	RichTextField,
} from 'react-admin';

import HasItemsField from '../../components/fields/hasItemsField';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { withStyles } from '@material-ui/core';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const CardGrid = withStyles(gridStyles)(({classes, ...props}) => (
	<Datagrid {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<RichTextField source="text_fr" className={classes.abbrev}/>
		<HasItemsField source="medias" />
		<HasItemsField source="videos" />
		<HasItemsField source="audios" />
		<HasItemsField source="slideshows" />
		<HasItemsField source="iframe_url" />
		<DateTimeField source="updated_at" />
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<LongTextInput source="fInternalTitle" alwaysOn className={classes.field}/>
		<NullableBooleanInput label="published" source="fPublished" alwaysOn className={classes.field}/>
	</Filter>
));



const CardList = ({drawerPath, rowClick = 'edit', ...props}) => (
	<List
		filters={<PostFilter/>}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<CardGrid rowClick={rowClick}/>
	</List>
);

export default CardList;
