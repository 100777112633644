import React, {useCallback, useEffect, useRef, useState} from 'react';
import WaveSurfer from 'wavesurfer.js';
import {Button} from "react-admin";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import ArrowBackIosIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIosIcon from '@material-ui/icons/ChevronLeft';
import PauseIcon from '@material-ui/icons/Pause';
import DeleteIcon from '@material-ui/icons/Delete';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ClearIcon from '@material-ui/icons/Clear';
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers/index.js'
import {FormDataConsumer} from 'react-admin';
import MusicTempo from "music-tempo";
import nextMarkerWaveSurfer from '../../../utils/nextMarkerWaveSurfer'
import {withStyles} from '@material-ui/core';

const PrevNextButton = ({next = true, waveSurfer, className}) => {

	const clickPrevNext = () => {
		if (waveSurfer) {
			const currentTime = Number(waveSurfer.getCurrentTime().toFixed(2))
			let nextMarkerTime = nextMarkerWaveSurfer(waveSurfer, next, currentTime)
			if (nextMarkerTime !== Number.POSITIVE_INFINITY && nextMarkerTime !== 0) {
				if (nextMarkerTime === Number.NEGATIVE_INFINITY) {
					nextMarkerTime = 0
				}
				const skipInterval = Number((nextMarkerTime - currentTime).toFixed(2))
				waveSurfer.skip(skipInterval);
			}
		}
	}
	return (
		<Button
			label=""
			onClick={clickPrevNext}
			variant="contained"
			color="secondary"
			style={{marginTop: '1rem', marginLeft: '1rem'}}
			className={className}
		>
			{next ? <ArrowBackIosIcon/> : <ArrowForwardIosIcon/>}

		</Button>
	);
}
const PlayPauseButton = ({className, waveSurfer, initIsPlaying = false, playRegion = false}) => {
	const [isPlaying, setIsPlaying] = useState(initIsPlaying);
	const clickPlayPauseHandler = () => {
		if (waveSurfer) {
			if (playRegion) {
				const currentTime = Number(waveSurfer.getCurrentTime().toFixed(2))
				const nextMarkerTime = nextMarkerWaveSurfer(waveSurfer, true, currentTime)
				waveSurfer.play(currentTime, nextMarkerTime)
				setIsPlaying(true)
				waveSurfer.on('pause', function () {
					setIsPlaying(false)
				});
			} else {
				if (waveSurfer.isPlaying() === true) {
					setIsPlaying(false)
				} else {
					setIsPlaying(true)
				}
				waveSurfer.playPause();
			}

		}
	}
	const style = {marginTop: '1rem'}
	playRegion && (style.marginLeft = '1rem')
	return (
		<Button
			label={playRegion ? 'interface.playRegion' : (isPlaying ? 'interface.pause' : 'interface.play')}
			onClick={clickPlayPauseHandler}
			variant="contained"
			color="secondary"
			style={style}
			className={className}
		>
			{playRegion ? <PlaylistPlayIcon/> : (isPlaying ? <PauseIcon/> : <PlayArrowIcon/>)}

		</Button>
	);
};
const GenerateMarkersButton = ({className, getOnClick}) => {
	return (
		<Button
			label={'interface.generateMarkers'}
			onClick={getOnClick}
			variant="contained"
			color="secondary"
			style={{marginTop: '1rem', marginLeft: '1rem'}}
			className={className}
		>
			<HourglassEmptyIcon/>
		</Button>
	);
};
const DeleteWaveButton = ({className, record, getOnClick, id}) => {
	const itemId = record ? record.id : id;

	return (
		<Button
			label=""
			onClick={getOnClick(itemId)}
			variant="contained"
			color="secondary"
			style={{marginTop: '1rem', marginLeft: '1rem'}}
			className={className}
		>
			<DeleteIcon/>
		</Button>
	);
};
const CleanMarkerButton = ({className, getOnClick}) => {
	return (
		<Button
			label={'interface.clear'}
			onClick={getOnClick}
			variant="contained"
			color="secondary"
			style={{marginTop: '1rem', marginLeft: '1rem'}}
			className={className}
		>
			<ClearIcon/>
		</Button>
	);
}

const WaveStyles = {
	audioTitle: {
		marginBottom: '1em',
		display: 'inline-block'
	}
}
const Wave = withStyles(WaveStyles)(({url, getOnDelete, title, classes}) => {
	const waveformRef = useRef(null),
		[waveSurfer, setWaveSurfer] = useState(null),
		// level define number of clips for each difficulty : (6, 8, 10)
		defaultLevel = 10,
		// timeSlicing define slicing strategy _ time in seconds _ ( 2, 3, 4, 6, 8, 9, 12, 16 )
		defaultTimeSlicing = 6,
		[level, setLevel] = useState(defaultLevel),
		[timeSlicing, setTimeSlicing] = useState(defaultTimeSlicing),
		levels = {1: 6, 2: 8, 3: defaultLevel},
		getFormattedLevel = (id) => levels[id] ? levels[id] : defaultLevel,
		getFormattedTimeSlicing = (time) => [2, 3, 4, defaultTimeSlicing, 8, 9, 12, 16].includes(parseInt(time)) ? parseInt(time) : defaultTimeSlicing
	let regions = []

	const initMarker = {
		time: 0,
		label: 'Begin',
		tooltip: true,
		color: '#00ffcc',
		position: 'bottom',
		draggable: true,
	}

	// convert local regions to markers and put them in the waveform
	const putMarkersInWave = useCallback(ws => {
		const localWaveSurfer = ws ?? waveSurfer
		if (localWaveSurfer && regions.length > 0) {
			localWaveSurfer.markers.clear()
			for (let i = 1; i < regions.length + 1; i++) {
				localWaveSurfer.addMarker({
					time: regions[i - 1].start,
					label: i === 1 ? 'Begin' : i - 1,
					tooltip: true,
					color: i === 1 ? '#00ffcc' : '#FF4C44',
					position: 'bottom',
					draggable: true,
				});
			}
			// add end marker
			if(regions.length > 1){
				localWaveSurfer.addMarker({
					time: regions[regions.length - 1].end,
					label:'End',
					tooltip: true,
					color: '#00ffcc',
					position: 'bottom',
					draggable: true,
				});
			}
		}
	}, [regions, waveSurfer])

	const generateMarkersAndRegionsInWave = async () => {

		const calcTempo = (buffer) => {
			let audioData = [];
			// Take the average of the two channels
			if (parseInt(buffer.numberOfChannels) === 2) {
				const channel1Data = buffer.getChannelData(0);
				const channel2Data = buffer.getChannelData(1);
				const length = channel1Data.length;
				for (let i = 0; i < length; i++) {
					audioData[i] = (channel1Data[i] + channel2Data[i]) / 2;
				}
			} else {
				audioData = buffer.getChannelData(0);
			}
			const mt = new MusicTempo(audioData);
			const beats = mt.beats.map(x => Number(x.toFixed(2)));
			const currentBeginMarker = waveSurfer.markers.markers.find(marker => marker.label === 'Begin')
			let newBeginMarkerIndex = 0
			// calculate new begin tempo index from user currentBeginMarker ( the closest )
			beats.reduce(function (prev, curr, index) {
				if (Math.abs(curr - currentBeginMarker.time) < Math.abs(prev - currentBeginMarker.time)) {
					newBeginMarkerIndex = index
					return curr
				} else {
					return prev
				}
			});
			const validBeats = beats.slice(newBeginMarkerIndex)
			const sections = [];
			for (let i = 0; i < validBeats.length; i++) {
				if (sections.length >= level) {
					break
				}
				if (i % timeSlicing === 0 && validBeats[i + timeSlicing] !== undefined) {
					const start = validBeats[i];
					const end = validBeats[i + timeSlicing];

					sections.push({
						start,
						end,
						duration: Number((end - start).toFixed(2))
					})
				}
			}
			regions = sections
		}


		const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
		//let source = audioCtx.createBufferSource();

		try {
			const response = await fetch(url)
			if (!response.ok) {
				return []
			}
			const arrayBufferData = await response.arrayBuffer()
			// eslint-disable-next-line no-unused-vars
			const decodeAudio = await audioCtx.decodeAudioData(arrayBufferData, (buffer) => {
				calcTempo(buffer)
			})
			// Play sound in browser, not useful for this project
			// source.buffer = decodedAudio
			// source.connect(audioCtx.destination);
			// source.start(audioCtx.currentTime);
			// source.start(0);
			// source.stop(0);
			// source.loop = true;
		} catch (error) {
			return []
		}
		putMarkersInWave()
		return regions
	}

	const initRegion = {start: 0, end: 0, duration: 0}

	useEffect(() => {
		if (url) {
			const waveSurfer = WaveSurfer.create({
				container: waveformRef.current,
				waveColor: '#A8DBA8',
				progressColor: '#3B8686',
				height: 300,
				forceDecode: true,
				mediaControls: true,
				normalize: true,
				minPxPerSec: 30,
				scrollParent: true,
				/*fillParent: false,
				minValue: -1,
				maxValue: 1,*/
				plugins: [
					MarkersPlugin.create({
						markers: [initMarker]
					})
				]
			});

			waveSurfer.load(url);
			setWaveSurfer(waveSurfer);
			putMarkersInWave(waveSurfer)

			return () => {
				waveSurfer.destroy();
			};
		}
	}, [url]);


	return (
		<>
			<span className={classes.audioTitle}>{title}</span>
			<div id="timeline" ref={waveformRef}/>
			{url && <PlayPauseButton initIsPlaying={false} waveSurfer={waveSurfer}/>}
			{url && <PrevNextButton next={false} waveSurfer={waveSurfer}/>}
			{url && <PrevNextButton next={true} waveSurfer={waveSurfer}/>}
			{url && <PlayPauseButton initIsPlaying={false} waveSurfer={waveSurfer} playRegion={true}/>}

			<FormDataConsumer>
				{({formData, dispatch, ...rest}) => {
					setLevel(getFormattedLevel(formData.level))
					setTimeSlicing(getFormattedTimeSlicing(formData.time_slicing))
					regions = formData?.melody_data?.markers ?? []
					if (waveSurfer) {
						waveSurfer.un('marker-drop')
						waveSurfer.on('marker-drop', marker => {
							convertMarkersToRegionsData()
						})
					}

					if(url === undefined){
						formData.melody_data = {markers: [initRegion], time_slicing: formData.time_slicing}
						regions = [initRegion]
					}

					const convertMarkersToRegionsData = () => {
						const currentMarkers = waveSurfer.markers.markers?.sort((a, b) => a.time - b.time) ?? []
						const markersRegions = []
						for (let i = 0; i < currentMarkers.length - 1; i++) {
							if (currentMarkers[i + 1] !== undefined) {
								const start = Number((currentMarkers[i].time).toFixed(2));
								const end = Number((currentMarkers[i+1].time).toFixed(2));
								markersRegions.push({start, end, duration: Number((end - start).toFixed(2))})
							}
						}
						formData.melody_data = {markers: markersRegions, time_slicing: formData.time_slicing}
						regions = markersRegions
					}

					//TODO: see why dispatch dont work
					//dispatch(change(REDUX_FORM_NAME, 'melody_data', {markers: [initRegion], time_slicing: formData.time_slicing }))
					/*dispatch({type: 'CHANGE_FORM_DATA',payload: { melody_data: { markers: [{}] } },})*/
					//dispatch(change(form, source, empty))

					const updateMarkersAndRegionsDataInForm = async () => {
						const newRegions = await generateMarkersAndRegionsInWave()
						formData.melody_data = {markers: newRegions, time_slicing: formData.time_slicing}
						regions = newRegions
					}

					const clearMarkers = () => {
						waveSurfer.markers.clear()
						waveSurfer.addMarker(initMarker);
						formData.melody_data = {markers: [initRegion], time_slicing: formData.time_slicing}
						regions = [initRegion]
					}

					const deleteWaveButtonHandler = itemId => () => {
						formData.melody_data = {markers: [initRegion], time_slicing: formData.time_slicing}
						regions = [initRegion]
						getOnDelete(itemId)()
					}
					return (<>
						{url && <GenerateMarkersButton getOnClick={updateMarkersAndRegionsDataInForm}/>}
						{url && <CleanMarkerButton getOnClick={clearMarkers}/>}
						{url && <DeleteWaveButton getOnClick={deleteWaveButtonHandler}/>}
					</>)


				}}
			</FormDataConsumer>
		</>

	);

})

export default Wave
