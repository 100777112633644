import { getApiUrl, convertHTTPResponse, parseHomepageHighlights } from '../utils'

export default (httpClient, serverProvider) => {

	const convertDataRequestToHTTP = (resource, params) => {
		if (resource === 'badges')
			return { url: `${getApiUrl()}/${resource}/${params.id}?include=rules&mode=contribution` };

		return { url: `${getApiUrl()}/${resource}/${params.id}?mode=contribution` };
	}

	const getOneHomepageHighlights = (resource, payload) => {
		const url = `${getApiUrl()}/${resource}?mode=contribution`;
		const options = { method: 'GET' }
		return httpClient(url, options)
			.then(({ json }) => parseHomepageHighlights(json))
	}

	return (resource, payload) => {

		if (resource === 'homepageHighlights')
			return getOneHomepageHighlights(resource, payload)

		const { url, options } = convertDataRequestToHTTP(resource, payload);
		return httpClient(url, options).then(response => convertHTTPResponse(response, resource, payload));
	}

}