import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './providers/client';
import authProvider from './providers/auth';
import * as MODULES from './views';
import settingsReducer from './redux/settingsReducer';
import locationReducer from './redux/locationReducer';

import layout from './components/parts/Layout';
import Theme from './components/styles/theme';
import { customRoutes } from './routing/routes';
import Login from './components/parts/Login';
import i18nProvider from './i18n';


// which displays first at login
const { Cards: firstModule, ...otherModules } = MODULES

const App = () => (
	<Admin dataProvider={dataProvider}
		theme={Theme}
		authProvider={authProvider}
		locale="fr"
		i18nProvider={i18nProvider}
		title="Opéra de Paris — Académie Digitale"
		loginPage={Login}
		appLayout={layout}
		customRoutes={customRoutes}
		customReducers={{
			settings: settingsReducer,
			location: locationReducer,
		}}
		initialState={{
			settings: {
				cloudinary: {
					images_definition: {
						square: { aspectRatio: '1/1' },
						landscape: { aspectRatio: '16/9' },
						portrait: { aspectRatio: '9/16' },
						fiche: { aspectRatio: '4/3' },
						vertical: { aspectRatio: '4/5' },
						free: { aspectRatio: null },
					}
				},
			}
		}}
	// initialState settings should be fetched from API /settings at first, then stored in localStorage for subsequent sessions
	// https://reacttraining.com/react-router/web/example/preventing-transitions
	>
		{permissions => permissions
			? [
				<Resource {...firstModule} />,
				...Object.values(otherModules).map(module => <Resource {...module} />)
			]
			: []
		}
	</Admin>
);

export default App;
