import React from 'react';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Edit from '../../components/fields/edit';
import Create from '../../components/fields/create';
import Form from './Form';

const edit = props => <Edit {...props} Form={Form} />;
const create = props => <Create {...props} Form={Form} />;

export { edit, create };
export { LibraryBooksIcon as icon };
export { default as list } from './list';
export { default as item } from './item';
export const name = 'cards';
