import React from 'react';
import {
	SimpleForm,
	NumberInput,
	DateTimeInput,
	Toolbar,
	SaveButton,
	BooleanInput,
	DeleteWithConfirmButton,
	translate,
	required,
} from 'react-admin';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core';

import Grid from '../../components/parts/Grid';
import Container from '../../components/parts/Container';
import HeadingView from '../../components/parts/HeadingView';
import FileInput from '../../components/fields/fileInput';
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const validDate = (value) => {
	const testDate = typeof value === 'string' ? new Date(value) : value
	if(!testDate instanceof Date || isNaN(testDate.valueOf()))
		return "Merci de renseigner la date et l'heure."
	return undefined
}

const validateReq = [required()];
const validateDate = [required(), validDate];

const _defaultValue = { active_en: true };

const toolbarStyles = theme => ({
	toolbar: {
		'&>*': {
			marginRight: '1em',
		},
	},
})

const CustomToolbar = withStyles(toolbarStyles)(({classes, ...props}) => {
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton />
			{props.record.winners_count === 0 &&
				<DeleteWithConfirmButton />
			}
		</Toolbar>
	)
})


const Form = ({ translate, view, redirect = 'list', defaultValue, parentProps, ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
		toolbar={<CustomToolbar />}
	>
		<HeadingView resource={props.resource} view={view} />

		<Grid container head>
			<Grid item xs={12}>
				<BooleanInput source="active_en" defaultValue={true} />
			</Grid>
		</Grid>
		<Container title={'info'}>
			<Grid container head>
				<Grid item xs={6}>
					<EscapedTextInput source="message_fr" />
				</Grid>
				<Grid item xs={6}>
					<ConditionalField flag="active_en">
						<EscapedTextInput source="message_en" />
					</ConditionalField>
				</Grid>
				<Grid item xs={12}>
					<DateTimeInput source="start_at" validate={validateDate} />
				</Grid>
				<Grid item xs={12}>
					<DateTimeInput source="end_at" validate={validateDate} />
				</Grid>
				<Grid item xs={6}>
					<NumberInput source="max_winners" options={{ fullWidth: true }} validate={validateReq} />
					<NumberInput source="winners_count" options={{ fullWidth: true }} disabled/>
				</Grid>
				<Grid item xs={12}></Grid>
				<Grid item xs={6}>
					<FileInput source="rules_file_url_fr" accept=".pdf,application/pdf" validate={validateReq}/>
				</Grid>
				<Grid item xs={6}>
					<ConditionalField flag="active_en">
						<FileInput source="rules_file_url_en" accept=".pdf,application/pdf" validate={validateReq}/>
					</ConditionalField>
				</Grid>
				<Grid item xs={12}>
					<>
						<FormHelperText error>{translate('resources.instantWinnerDates.interface.warning')}</FormHelperText>
					</>
				</Grid>
			</Grid>
		</Container>
	</SimpleForm>
);

export default translate(Form);