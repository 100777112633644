import React from 'react';
import { connect } from 'react-redux';
import { Button, FieldTitle } from 'react-admin';
import { Field, FormName } from 'redux-form';

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FormHelperText from '@material-ui/core/FormHelperText';


import * as MODULES from '../../../views';

import RoutedCreateDrawer from './routedCreateDrawer';
import DragAndDropComponent from './dragAndDropComponent';

/**
 * 
 * @param {string || array<string>}	reference	name of the single resource to create / reorder
 * @param {string} label	[optional] label to display above the field (if none provided, will use `reference` as a key to the standard field key in i18n file)
 * @param {object} itemProps	[optional] props to be passed to the <item> views in the drag and drop 
 * @param {boolean} isRequired	[optional] used in validation to toggle minimum number of items
 * @param {number} maxSelection	[optional] maximum number of items that can be added (used in validation && disabling "add" button)
 * @param {array<any>} revalidateDependencies	[optional] dependency array as would be passed to a `React.useEffect` whose changes will trigger a re-validation of the items
 * 
 * 
 * 
 * @usage the parent <SimpleForm> should declare a form name `<SimpleForm form={formName}>` to preserve form data when Drawer opens
 * 
 * @example

<SimpleForm {...props} form={formName}>
	<CreateRelationshipsOrderedInput reference={['questionnaires', 'stories']}/>
</SimpleForm>

 * 
 * @TODO implement EDIT capability for already created elements
 * 
 */

const CreateRelationshipsOrderedInput = ({ adminProps: { label, maxSelection, reference, viewProps, itemProps, items, isRequired, revalidateDependencies, dispatch, disabled, ...props }, ...field }) => {
	const controls = React.useRef({});
	const isMaxSelection = Array.isArray(field.input.value.ids) && (field.input.value ? field.input.value.ids.length : 0) >= maxSelection;
	const module = Object.values(MODULES).find(res => res.name === reference)

	const [itemList, setItemListState] = React.useState([])

	const setItemList = (value) => {
		const newArray = (value.ids || [])
			.map((id, i) => ({
				id,
				position: value.positions[i] || i + 1,
				valid: items.validity[id] !== false
			}))
			.sort((a, b) => a.position - b.position)
			.map((item, i) => {
				item.position = i + 1
				return item
			})

		setItemListState(newArray)
	}

	React.useEffect(() => {
		setItemList(field.input.value)
	}, [
		field.input.value.ids ? field.input.value.ids.join(',') : '',
		field.input.value.positions ? field.input.value.positions.join(',') : '',
		field.input.value.ids ? field.input.value.ids.map(id => items.validity[id]).join(',') : '',
	])

	React.useEffect(() => {
		// hack to force redux-form to re-validate field
		field.input.onChange({ ...field.input.value, _hack: Date.now() })
		setItemList(field.input.value)
	}, revalidateDependencies)

	return <>
		<div style={{ marginTop: '16px' }}>
			<FieldTitle resource={props.resource} isRequired={isRequired} source={field.input.name} label={label} />
			{field.meta.error &&
				<FormHelperText error>{field.meta.error}</FormHelperText>
			}
		</div>
		<RoutedCreateDrawer module={module} controls={controls} field={field} setItemList={setItemList} viewProps={viewProps} {...props} />

		<DragAndDropComponent module={module} field={field} itemList={itemList} setItemList={setItemList} viewProps={viewProps} itemProps={itemProps} {...props} />

		<Button
			label={'interface.add'}
			onClick={() => controls.current.open()}
			variant="contained"
			color="secondary"
			disabled={disabled || isMaxSelection}
		>
			<PlaylistAddIcon />
		</Button>
	</>
}

const getValidate = (items, validateInput, maxSelection, minSelection, allowEmpty) => (value, record, props, name) => {
	if (!allowEmpty && (!value || !value.ids || value.ids.length === 0))
		return props.translate(`validation.createRelationshipsOrderedInput.empty`)
	if (value && value.ids && value.ids.length > maxSelection)
		return props.translate(`validation.createRelationshipsOrderedInput.tooMany`, { max: maxSelection, count: value.ids.length })
	if (value && value.ids && value.ids.some(id => items.validity[id] === false))
		return props.translate(`validation.createRelationshipsOrderedInput.wrongItem`)
	if (!value || !value.ids || value.ids.length < minSelection)
		return props.translate(`validation.createRelationshipsOrderedInput.tooFew`, { min: minSelection, count: (value && value.ids && value.ids.length) || 0 })
	if (typeof validateInput === 'function')
		return validateInput({ items, value, record, translate: props.translate })
	return undefined
}

const ContainerWithField = ({ reference, validateItem, validateInput, allowEmpty, maxSelection = Infinity, minSelection = 0, items, revalidateDependencies, ...props }) => {

	const validity = React.useMemo(() => {
		const validity = {}
		if (typeof validateItem === 'function')
			items.ids.forEach(id => validity[id] = items.states[id] ? !!validateItem(items.states[id]) : true)
		return validity
	}, [items.memoKeys.join(','), ...revalidateDependencies]);

	items.validity = validity

	const fieldValidate = React.useMemo(() => {
		return getValidate(items, validateInput, maxSelection, minSelection, allowEmpty)
	}, [items.memoKeys.join(','), ...revalidateDependencies]);

	return <Field
		name={`relationships.${reference}`}
		component={CreateRelationshipsOrderedInput}
		adminProps={{ ...props, reference, items, maxSelection, minSelection, revalidateDependencies }}
		validate={fieldValidate}
	/>
};

const mapStateToProps = (state, { formName, ...props }) => {
	const itemsState = {};
	const itemsMemoKeys = [];
	const itemsId = (state.form[formName] && state.form[formName].values.relationships && state.form[formName].values.relationships[props.reference] && Array.isArray(state.form[formName].values.relationships[props.reference].ids)
		? [...state.form[formName].values.relationships[props.reference].ids]
		: []
	).sort()
	itemsId.forEach(id => {
		const item = state.admin.resources[props.reference].data[id];
		itemsState[id] = item;
		if (item)
			itemsMemoKeys.push(`${id}-${+!!item.text_fr}-${+!!(item.medias && item.medias.length)}-${+!!(item.audios && item.audios.length)}-${+!!(item.videos && item.videos.length)}`);
		else
			itemsMemoKeys.push(`${id}-undefined`);
	});
	return {
		items: {
			ids: itemsId,
			states: itemsState,
			memoKeys: itemsMemoKeys, // memoize based on changes in object w/o JSON.stringify
		}
	}
}

const ConnectedContainerWithField = connect(mapStateToProps)(ContainerWithField);

export default (props) => (
	<FormName>
		{({ form }) => <ConnectedContainerWithField formName={form} {...props} />}
	</FormName>
)

