import React from 'react';
import {
	SimpleForm,
	DateTimeInput,
	BooleanInput,
	required,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import ManyTypesRelationshipsOrderedInput from '../../components/fields/manyTypesRelationshipsOrderedInput';
import SelectInput from '../../components/fields/styledSelectInput';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const validateReq = [required()];

const _defaultValue = {
	active_en: true,
	is_standalone: false,
	is_locked_for_guests: false
};

const Form = ({ view, parentProps, defaultValue, redirect = 'list', ...props }) => (
	<SimpleForm
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
		{...sanitizeFormProps(props)}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true}/>
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>
						<Grid item xs={6}>
							<SelectInput source="type" choices={[
								{ id: 'V', name: 'interface.select.storyType.versus' },
								{ id: 'S', name: 'interface.select.storyType.story' },
							]} />
						</Grid>
					</Grid>
				</Container>
				<Container title={'step'}>
					<ManyTypesRelationshipsOrderedInput reference={'cards'} />
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
);

export default Form;