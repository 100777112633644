import React, {useState} from 'react';
import { Field } from 'redux-form';
import { Button } from 'react-admin';

// import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'; // TODO: ideal icon to use, under Material UI v4
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as mediaModule from '../../../views/MediaLibraries';
import * as audioModule from '../../../views/AudioLibraries';
import * as videoModule from '../../../views/VideoLibraries';
import * as slideshowModule from '../../../views/Slideshows';
import RoutedListDrawer from './routedListDrawer';
import RoutedEditDrawer from './routedEditDrawer';
import MediaArrayInput from './mediaArrayInput'

const modules = {
	medias: mediaModule,
	audios: audioModule,
	videos: videoModule,
	slideshows: slideshowModule,
	melody: audioModule
};


const GalleryInput = ({ adminProps: { origin, maxSelection, formats, ...props }, ...field }) => {
	const listDrawerControls = React.useRef({});
	const editDrawerControls = React.useRef({});
	const module = modules[field.input.name]

	const liveRecord = Object.assign({}, props.record, { [field.input.name]: field.input.value })

	return (
		<>
			{field.meta.error &&
				<FormHelperText error>{field.meta.error}</FormHelperText>
			}

			<RoutedListDrawer
				module={module}
				controls={listDrawerControls}
				origin={origin}
				field={field}
				maxSelection={maxSelection}
				formats={formats}
				{...props}
			/>

			<RoutedEditDrawer
				module={module}
				controls={editDrawerControls}
				origin={origin}
				{...props}
			/>

			<MediaArrayInput
				field={field}
				formats={formats}
				record={liveRecord}
				controls={editDrawerControls}
			/>

			<Button
				label={'interface.add'}
				onClick={() => listDrawerControls.current.open()}
				variant="contained"
				color="secondary"
				style={{marginTop:'1rem'}}
			>
				<PlaylistAddIcon />
			</Button>
		</>
	);
};

const getValidate = (allowEmpty, minSelection, maxSelection) => (value, record, props, name) => {
	const isArray = Array.isArray(value)
	if(!allowEmpty && (!isArray || value.length === 0))
		return props.translate(`validation.galleryInput.empty`)
	if(isArray && value.length > maxSelection)
		return props.translate(`validation.galleryInput.tooMany`, {max: maxSelection, count: value.length})
	if(!isArray || value.length < minSelection)
		return props.translate(`validation.galleryInput.tooFew`, {min: minSelection, count: (isArray && value.length) || 0})
	return undefined
}

const ContainerWithField = ({ type, allowEmpty, minSelection = 0, maxSelection = Infinity, ...props }) => {
	const fieldValidate = React.useMemo(() => {
		getValidate(allowEmpty, minSelection, maxSelection)
	}, [allowEmpty, minSelection, maxSelection])

	return <Field
		name={type}
		component={GalleryInput}
		adminProps={{...props, maxSelection}}
		validate={fieldValidate}
	/>
};

export default ContainerWithField;
