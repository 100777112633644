import React from 'react'
import { connect } from 'react-redux';
import { setListSelectedIds, translate, CardActions, Button } from 'react-admin';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { list as ArtworkList, name as artworkResourceName } from '../../../views/Artwork';
import RoutedDrawerView from '../routedDrawerView';

const ArtworkListActions = ({ controls }) => (
	<CardActions>
		<Button label='interface.close' onClick={controls.current.close}>
			<CloseIcon />
		</Button>
	</CardActions>
);

const ArtworkListBulkActions = translate(({ translate, setListSelectedIds, selectedIds, reduxData, controls, onAdd }) => {
	const count = selectedIds.length;
	const onClick = () => {
		onAdd(reduxData.data[selectedIds[0]]);
		controls.current.close();
		setListSelectedIds(artworkResourceName, []);
	};
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{count > 1 &&
				<span style={{ color: 'red', marginRight: '10px' }}>
				{translate('interface.drawers.maximum_selection', {
					count: 1,
					plural: ''
				})}
			</span>
			}

			<Button
				label={translate('interface.drawers.add_selection', {
					count,
					plural: count > 1 ? 's' : ''
				})}
				onClick={onClick}
				disabled={count > 1}
				variant="outlined"
			>
				<AddIcon />
			</Button>
		</div>
	);
});

const RoutedListDrawer = ({ sameRouteKey, setListSelectedIds, selectedIds, record, controls, onCancel, onAdd, ...props }) => {

	const handleRowClick = id => {
		const newIds = [...selectedIds]

		if(newIds.includes(id)) {
			const index = newIds.findIndex(arrayId => arrayId === id)
			newIds.splice(index, 1)
		} else {
			newIds.push(id)
			newIds.sort()
		}
		setListSelectedIds(artworkResourceName, newIds)

		return false
	}

	return (
		<RoutedDrawerView {...props} reference={artworkResourceName} view={'list'} controls={controls} onClose={onCancel} id={props.id || record.id} sameRouteKey={sameRouteKey}>
			{({ reduxData, controls, ...routedDrawerViewProps }) => {
				const listProps = Object.assign({}, routedDrawerViewProps, {
					rowClick: handleRowClick,
					actions: <ArtworkListActions controls={controls} />,
					bulkActionButtons: <ArtworkListBulkActions setListSelectedIds={setListSelectedIds} controls={controls} reduxData={reduxData} onAdd={onAdd} />,
				})
				return <ArtworkList {...listProps} />
			}}
		</RoutedDrawerView>
	)
}


const mapStateToProps = (state) => {
	const selectedIds = state.admin.resources[artworkResourceName].list.selectedIds
	return { selectedIds }
}

export default connect(mapStateToProps, { setListSelectedIds })(RoutedListDrawer);