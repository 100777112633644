import React from 'react';
import { Edit } from 'react-admin';
import { sanitizeEditProps } from '../../utils/sanitize';

const DefaultEdit = ({
	Form,
	toolbar,
	drawerPath,
	redirect = 'list',
	...props
}) => {
	const adminProps = sanitizeEditProps(props)
	const formName = `edit-${props.resource}-${props.id}`;
	return (
		<Edit {...adminProps} undoable={false}>
			<Form
				{...{
					redirect,
					toolbar,
					drawerPath,
				}}
				view="edit"
				parentProps={props}
				form={formName}
			/>
		</Edit>
	);
};

export default DefaultEdit