export const RELATIONSHIPS = {
	anecdotes: ['themes'],
	artworks: [],
	audioLibraries: [],
	badges: ['rules'],
	cards: ['artworks'],
	courses: ['themes', 'primaryTags', 'secondaryTags', 'sessions'],
	homepageHighlights: ['sessions', 'courses'],
	instantWinnerDates: [],
	mediaLibraries: [],
	possibleAnswers: [],
	questionnaires: ['themes', 'questions', 'questions.possibleAnswers', 'questions.goodAnswer'],
	questions: ['possibleAnswers', 'goodAnswer'],
	sessions: ['themes', 'primaryTags', 'secondaryTags', 'questionnaires', 'stories', 'transitions'],
	slideshows: [],
	stories: ['themes', 'cards'],
	tags: [],
	themes: [],
	transitions: ['themes'],
	users: ['questionnaires', 'badges'],
	videoLibraries: [],
};

export const API_TO_BO_RES_NAMES = new Map([
	['anecdote', 'anecdotes'],
	['artwork', 'artworks'],
	['audioLibrary', 'audioLibraries'],
	['badge', 'badges'],
	['card', 'cards'],
	['course', 'courses'],
	['mediaLibrary', 'mediaLibraries'],
	['possibleAnswer', 'possibleAnswers'],
	['questionnaire', 'questionnaires'],
	['question', 'questions'],
	['session', 'sessions'],
	['slideshow', 'slideshows'],
	['story', 'stories'],
	['tag', 'tags'],
	['theme', 'themes'],
	['transition', 'transitions'],
	['videoLibrary', 'videoLibraries'],
])