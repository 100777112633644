import React from 'react';
import CreateRelationshipsOrderedInput from './createRelationshipsOrderedInput';
import { FormDataConsumer } from 'react-admin';

const PossibleAnswersInput = ({ formData, ...props }) => {

	const validateItem = (record) => {

		// record of possibleAnswer wasn't fetched yet
		if (Object.keys(record).length === 1)
			return true

		const hasMedia = type => record[type] && record[type].length

		switch (formData.answer_type) {
			case 'J' :
			case 'B' :
			case 'BT' :
			case 'BTPA' :
			case 'BTPV' :
				return !!record.text_fr && !hasMedia('medias') && !hasMedia('audios') && !hasMedia('videos')
			case 'I':
				return !record.text_fr && hasMedia('medias') && !hasMedia('audios') && !hasMedia('videos')
			case 'S':
				return !record.text_fr && !hasMedia('medias') && (
					(formData.answer_asset_type === 'V' && !hasMedia('audios') && hasMedia('videos'))
					|| (formData.answer_asset_type === 'A' && hasMedia('audios') && !hasMedia('videos'))
				)
			default:
				return true
		}
	}

	const disabled = !formData.answer_type || (formData.answer_type === 'S' && !formData.answer_asset_type)

	const maxSelectionForBTP = formData.type === 'QBPV' ? 2 : 3

	const maxSelection = formData.answer_type === 'S' && formData.answer_asset_type === 'V' && formData.orientation === 'landscape' ? 2 : maxSelectionForBTP

	return (
		<CreateRelationshipsOrderedInput
			{...props}
			reference="possibleAnswers"
			minSelection={2}
			maxSelection={maxSelection}
			viewProps={{ answer_type: formData.answer_type, answer_asset_type: formData.answer_asset_type }}
			itemProps={{ answer_type: formData.answer_type }}
			validateItem={validateItem}
			revalidateDependencies={[formData.answer_type, formData.answer_asset_type, formData.orientation]}
			disabled={disabled}
		/>
	)
}

export default ({...props}) => (
	<FormDataConsumer {...props}>
		{({ dispatch, ...rest }) => (
			<PossibleAnswersInput {...rest} />
		)}
	</FormDataConsumer>
)
