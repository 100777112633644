import React from 'react'
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compose } from 'recompose';
import { Button } from 'react-admin';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { DeleteForever, SwapVert, Warning, Link as LinkIcon } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Function} onOrderChange	Function that will receive `childrenData` as reordered by the user after a "drag & drop" action
 * @param {Function} onClickRemove	Function that will receive the `childrenData` item for which the "Delete" button was clicked
 * @param {Function} onClickEdit	[optional] Adds as edit button. Set to `true`: link to <Edit> form of the resource. Set to a function, called on click with `childrenData` item
 * @param {Array} childrenData		Array of data, each items creates a <Draggable> div. Order of the array is preserved. Takes the following parameters:
 * 		@param {Object} meta			Object that will be returned on callbacks (`onOrderChange` and `onClickRemove`)
 * 		@param {String} uniqueId		String that uniquely identifies the item. Using `meta.id` is fine as long as the array contains items of a *single* resource
 * 		@param {Object} component		Component displayed within the <Draggable>
 * 		@param {Object} icon			[optional] Component displayed as the <Draggable> icon on the left (in the graspable part)
 * 		@param {String} source			String used for routing in Edit link (see onClickEdit) and passed in `childrenData` otherwise
 * 		@param {String} editIcon		[optional] Component displayed as the "edit" icon on the right (just before the delete button)
 * 		@param {Boolean} disableRemove	[optional] disable remove button
 * 		@param {Boolean} disableEdit	[optional] disable edit button
 * 		@param {Boolean} valid			[optional] related to validation: whether to display a warning in <item>
 *
 * @usage
```
const DraggableInnerComponent = ({ meta }) => <></>

const onChange = newOrderedList => {}

<DragAndDropContainer
	onOrderChange={onChange}
	onClickRemove={dragAndDropOnRemove}
	onClickEdit={true}
	childrenData={orderedList.map(meta => ({
		meta,
		uniqueId: meta.id,
		source: childrenResourceName,
		component: <DraggableInnerComponent/>,
		icon: <resource.icon/>,
	}))}
/>
```
 */

const DragAndDropArrayInput = ({ push, classes, childrenData, onOrderChange, droppableId = 'droppable', onClickRemove, onClickEdit }) => {
	const [order, setOrder] = React.useState([])
	React.useEffect(() => {
		setOrder(childrenData)
	}, [
		childrenData.map(child => child.uniqueId).join(','),
		childrenData.map(child => child.valid).join(','),
	])

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)

		return result
	}

	const onDragEnd = ({ source, destination }) => {
		// dropped outside the list || hasn't moved
		if (!destination || source.index === destination.index)
			return

		const newOrder = reorder(
			order,
			source.index,
			destination.index
		)

		setOrder(newOrder)
		onOrderChange(newOrder.map(childData => childData.meta))
	}

	const getListStyle = isDraggingOver => ({
		padding: 8
	})

	return <DragDropContext onDragEnd={onDragEnd}>
		<Droppable droppableId={droppableId}>
			{provided => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
					style={getListStyle()}
				>
					{order.map(({ meta, uniqueId, component, source, id, icon, editIcon, valid, disableRemove, disableEdit }, i) => (
						<Draggable draggableId={uniqueId} key={uniqueId} index={i}>
							{(provided, snapshot) => (
								<div ref={provided.innerRef}
									{...provided.draggableProps}
									className={classes.draggable}
									style={provided.draggableProps.style}>
									<AppBar position="static" className={`${classes.appBar} ${snapshot.isDragging ? classes.appBarDragging : ''}`}>
										<div className={`${classes.handle} ${valid !== false ? '' : classes.invalidItemHandle}`} {...provided.dragHandleProps}>
											{valid !== false
												? icon || <SwapVert />
												: <Warning />
											}
										</div>
										<Toolbar className={classes.toolbar}>
											<Typography variant="caption" classes={{ root: classes.itemContent }}>
												{React.cloneElement(component, Object.assign({ meta, id, source }, component.props))}
											</Typography>

											<ItemButton
												disabled={disableEdit}
												onClick={onClickEdit && typeof onClickEdit === 'function'
													? () => onClickEdit({ meta, source, id })
													: () => push(`/${source}/${id}`)
												}
											>
												{editIcon || <LinkIcon />}
											</ItemButton>

											<ItemButton disabled={disableRemove} onClick={() => onClickRemove({ meta, source, id })}>
												<DeleteForever />
											</ItemButton>
										</Toolbar>
									</AppBar>

								</div>
							)}
						</Draggable>
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</DragDropContext>
}

const ItemButton = ({ children, onClick, disabled }) => (
	<Button
		label=""
		onClick={onClick}
		color="secondary"
		disabled={disabled}
	>
		{children}
	</Button>
)


const styles = theme => createStyles({
	draggable: {
		margin: '0 0 8px 0',
	},
	appBar: {
		border: 'solid #000 1px',
		backgroundColor: '#FFFFFF',
		position: 'relative',
		padding: 0,
	},
	appBarDragging: {
		border: `solid ${theme.palette.primary.main} 1px`,
	},
	toolbar: {
		padding: '10px calc(1em + 10px) 10px calc(3em + 10px)',
	},
	itemContent: {
		flexGrow: 1,
		color: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	handle: {
		height: '100%',
		width: '3em',
		marginRight: '10px',
		position: 'absolute',
		zIndex: '1',
		'&:before': {
			position: 'absolute',
			left: 0,
			top: 0,
			bottom: 0,
			width: '3em',
			backgroundImage: 'radial-gradient(black 1px, transparent 1px)',
			backgroundPosition: '0 0, 2px 2px',
			backgroundSize: '6px 6px',
			content: '""',
		},
		'&>*': {
			fill: 'black',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			background: 'white',
		}
	},
	invalidItemHandle: {
		'&>*': {
			fill: theme.palette.primary.main
		}
	},
});

export default compose(
	withStyles(styles),
	connect(undefined, { push })
)(DragAndDropArrayInput)
