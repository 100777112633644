import React from 'react';
import { Toolbar, SaveButton, Button, translate } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import RoutedDrawerView from '../routedDrawerView';

const ModuleToolbar = translate(({ translate, controls, ...props }) => (
	<Toolbar {...props}>
		<SaveButton />
		<>
			<Button
				label='interface.cancel'
				onClick={() => controls.current.close()}
				style={{ marginLeft: '1em' }}
			>
				<CloseIcon />
			</Button>
		</>
	</Toolbar>
));

export default ({module, controls, field, setItemList, viewProps, ...props}) => {

	const redirect = (basePath, id, data) => {
		// close drawer
		controls.current.close()

		// compute new value
		const oldValue = field.input.value || {}
		if(!oldValue.ids)
			oldValue.ids = []
		if(!oldValue.positions)
			oldValue.positions = []
		const newValue = {
			...oldValue,
			ids: oldValue.ids.concat([id]),
			positions: oldValue.positions.concat([Math.max(...oldValue.positions, 0) + 1]),
		}

		// set value in redux-form
		field.input.onChange(newValue)

		// set value in react state
		setItemList(newValue)

		// prevent react-admin redirection
		return false
	};

	return (
		<RoutedDrawerView reference={module.name} view={'create'} {...props} controls={controls}>
			{({ reduxData, controls, ...routedDrawerProps }) => {
				return (
					<module.create
						redirect={redirect}
						toolbar={<ModuleToolbar controls={controls} />}
						{...routedDrawerProps}
						{...viewProps}
					/>
				)
			}}
		</RoutedDrawerView>
	)
}