import React                      from 'react';
import {connect}                  from 'react-redux';
import compose                    from 'recompose/compose';
import {withStyles, createStyles} from '@material-ui/core/styles';
import classnames                 from 'classnames';
import {getResources, translate}  from 'ra-core';
import DefaultIcon                from '@material-ui/icons/ViewList';
import AddIcon from '@material-ui/icons/Add';

import MenuItemLink  from './MenuItemLink';
import List          from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

const styles = theme => {
	return createStyles({
	main: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	root: {
		width: '100%',
		maxWidth: 360,
	},
	nested: {
		paddingLeft: '24px',
		color: theme.palette.text.primary
	},
	activeText: {
		color: theme.palette.grey.contrastText,
		fontWeight: 'bold',
	},
	activeItem: {
		backgroundColor: theme.palette.grey.main,
		'&:hover':{
			backgroundColor: theme.palette.grey.main,
			'& $activeText': {
				color: theme.palette.grey.contrastText,
			}
		}
	},
	subheader: {
		transition: 'opacity .5s',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	subheaderclosed: {
		opacity: 0,
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		margin: '-4px 0',
		'&:hover $create': {
			opacity: 1,
		}
	},
	menuItemLink: {
		flex: 1
	},
	create: {
		opacity: 0,
		transition: 'opacity .5s',
	},
	icon: {
		
	}
})};

const Menu = ({
				  classes,
				  className,
				  dense,
				  hasDashboard,
				  onMenuClick,
				  open,
				  pathname,
				  resources,
				  translate,
				  logout,
				  layout,
				  ...props
			  }) => {
	const mapResource = resource => (
		<div className={classnames(
				classes.item,
				layout.menuItem === resource.name && classes.activeItem
			)}
			key={resource.name}
		>
			<MenuItemLink
				className={classnames(
					classes.nested, 
					className, 
					classes.menuItemLink,
					layout.menuItem === resource.name && classes.activeText
				)}
				to={`/${resource.name}`}
				primaryText={translate(`resources.${resource.name}.name`)}
				leftIcon={
					resource.icon ? <resource.icon className={classes.icon}/> : <DefaultIcon className={classes.icon}/>
				}
				onClick={onMenuClick}
				dense={dense}
			/>
				<div className={classes.create}>
					{resource.create && 
						<Link to={`/${resource.name}/create`} >
							<IconButton size="small" color="primary">
								<AddIcon />
							</IconButton>
						</Link>
					}
				</div>
		</div>
	);

	if (!layout || (!layout.resources && !layout.menuLayout) || (layout.resources && layout.resources.length < 2)) {
		return <></>;
	}

	if (layout.menuLayout) {
		return (
			<div className={classnames(classes.main, className)} {...props}>
				{layout.menuLayout.map((subgroup, i) => {
					return subgroup.name ?
						<div key={subgroup.name}>
							<List
								component="nav"
								subheader={<ListSubheader component="div" className={`${classes.subheader} ${open ? classes.subheaderopen : classes.subheaderclosed}`}>{translate(`interface.menu.subgroups.${subgroup.name}`)} </ListSubheader>}
								className={classes.root}
							>
								{subgroup.content.map(mapResource)}
							</List>
						</div>
						:
						subgroup.content.map(mapResource);
				})}
			</div>
		);
	}

	return (
		<div className={classnames(classes.main, className)} {...props}>
			{layout.resources.map(mapResource)}
		</div>
	);
};

Menu.defaultProps = {
	onMenuClick: () => null,
};

const mapStateToProps = state => ({
	open: state.admin.ui.sidebarOpen,
	resources: getResources(state),
	pathname: state.router.location.pathname, // used to force redraw on navigation
	layout: state.location.currentPageGroup
});

const enhance = compose(
	translate,
	connect(
		mapStateToProps,
		{}, // Avoid connect passing dispatch in props,
		null,
		{
			areStatePropsEqual: (prev, next) =>
				prev.resources.every(
					(value, index) => value === next.resources[index] // shallow compare resources
				) &&
				prev.pathname === next.pathname &&
				prev.open === next.open,
		}
	),
	withStyles(styles)
);

export default enhance(Menu);
