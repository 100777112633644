import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	maxLength,
	DateTimeInput,
	required
} from 'react-admin';

import Container from '../../components/parts/Container';
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid'
import FormToolbar from "../../components/parts/FormToolbar";
import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";
import ConditionalField from '../../components/fields/conditionalField';
import HeadingView from "../../components/parts/HeadingView";
import EscapedTextInput from '../../components/fields/escapedTextInput'
import SelectInput from '../../components/fields/styledSelectInput';
import { sanitizeFormProps } from '../../utils/sanitize';

const validateTitle = [maxLength(60)];
const validateReq = [required()];

const _defaultValue = {
	active_en: true,
	orientation: 'portrait',
	orientation_video : 'landscape'
};

const Form = ({ heading, parentProps, view, defaultValue, redirect = 'list', ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		redirect={redirect}
		toolbar={<FormToolbar/>}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true} />
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>

						{/*Fr fields*/}

						<Grid item xs={6}>
							<EscapedTextInput source="title_fr" validate={validateTitle} />
							<RichTextInputRelLinks source="description_fr" {...parentProps} validate={[maxLengthRichText(500)]} />
						</Grid>

						{/*En fields*/}

						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="title_en" validate={validateTitle} />
							</ConditionalField>
							<ConditionalField flag="active_en">
								<RichTextInputRelLinks source="description_en" {...parentProps} validate={[maxLengthRichText(500)]} />
							</ConditionalField>
						</Grid>
					</Grid>
				</Container>
				<Container title={'images'}>
					<Grid item xs={6}>
						<SelectInput source="orientation" choices={[
							{ id: 'landscape', name: '16/9' },
							{ id: 'portrait', name: '9/16' },
						]} />
					</Grid>
					<Grid item xs={6}></Grid>
					<GalleryInput type="medias" maxSelection={1} formats={['landscape', 'portrait']} {...parentProps} />
				</Container>
				<Container title={'slideshows'}>
					<GalleryInput type="slideshows" maxSelection={1} {...parentProps} />
				</Container>
				<Container title={'audios'}>
					<GalleryInput type="audios" maxSelection={3} {...parentProps} />
				</Container>
				<Container title={'videos'}>
					<Grid item xs={6}>
						<SelectInput source="orientation_video" choices={[
							{ id: 'landscape', name: '16/9' },
							{ id: 'portrait', name: '9/16' },
							{ id: 'fiche', name: '4/3' },
						]} />
					</Grid>
					<Grid item xs={6}></Grid>
					<GalleryInput type="videos" maxSelection={1} {...parentProps} />
				</Container>
				<Container title={'iframe'}>
					<Grid item xs={6}><EscapedTextInput source="iframe_url" /></Grid>
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
);

export default Form;
