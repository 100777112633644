import React              from 'react';
import ReactDOM           from 'react-dom';
import './index.css';
import App                from './App';


fetch(`${process.env.PUBLIC_URL}/config.json`)
.then(response => {
	if (!response.ok)
		throw new Error('HTTP error, status = ' + response.status);
	return response.json()
}).then(env => {
	window.ARIA_CONFIG = env
	ReactDOM.render(<App/>, document.getElementById('root'));
})
