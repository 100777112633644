import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	required,
	DateTimeInput,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const validateReq = [required()];
const _defaultValue = { 
	published: true,
	active_fr: true 
};

const Form = ({ view, parentProps, redirect = 'list', defaultValue, ...props }) => (
	<SimpleForm
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
		{...sanitizeFormProps(props)}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>
				<Container title={'info'}>
					<Grid container>
						<Grid item xs={12}>
							<BooleanInput source="active_en" defaultValue={true} />
						</Grid>
						<Grid item xs={6}>
							<EscapedTextInput source="label_fr" validate={validateReq} />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="label_en" validate={validateReq} />
							</ConditionalField>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<BooleanInput source="published" />
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
)

export default Form;
