import React from 'react';

import {
	ImageField,
	LinearProgress,
} from 'react-admin';
import AudioField from "../../components/fields/audioField";
import VideoField from '../../components/fields/videoField';
import EscapedTextField from '../../components/fields/escapedTextField';

export default ({ record, showMedia, answer_type }) => {
	if (record.medias && record.medias[0])
		return <>
			<EscapedTextField source="medias[0].internal_title" record={record} />
			{showMedia !== false &&
				<ImageField source="medias[0].url" record={record} />
			}
		</>
	else if (record.audios && record.audios[0])
		return <>
			<EscapedTextField source="audios[0].internal_title" record={record} />
			{showMedia !== false &&
				<AudioField source="audios[0].url" record={record} />
			}
		</>
	else if (record.videos && record.videos[0])
		return <>
			<EscapedTextField source="videos[0].internal_title" record={record} />
			{showMedia !== false &&
				<VideoField source="videos[0].url" poster="videos[0].image_url" record={record} />
			}
		</>
	else if (record.text_fr)
		return <EscapedTextField source="text_fr" record={record} />
	else
		return <LinearProgress />

};