export default (waveSurfer, next, currentTime) => {
	const currentMarkers = waveSurfer.markers.markers
	let nextMarkerTime = next === true ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
	for (let i = 0; i < currentMarkers.length; i++) {
		let markerTime = Number(currentMarkers[i].time.toFixed(2));
		if ( (next === true && markerTime > currentTime && markerTime < nextMarkerTime) ||
			(next === false && markerTime < currentTime && markerTime > nextMarkerTime)){
			nextMarkerTime = markerTime;
		}
	}
	return Number(nextMarkerTime.toFixed(2))
};
