import React from 'react';

import {
	SimpleForm,
	BooleanInput,
	translate,
	maxLength,
	required,
} from 'react-admin';

import GalleryInput from '../../components/fields/galleryInput'
import Container from "../../components/parts/Container";
import Grid from '../../components/parts/Grid'
import EscapedTextInput from '../../components/fields/escapedTextInput'
import HeadingView from "../../components/parts/HeadingView";
import ConditionalField from '../../components/fields/conditionalField';
import { sanitizeFormProps } from '../../utils/sanitize';

const validateText = [maxLength(35), required()];

const Form = ({ translate, drawerPath, answer_type, answer_asset_type, view, parentProps, ...props }) => (
	<SimpleForm {...sanitizeFormProps(props)}>
		<HeadingView resource={props.resource} view={view} />
		<Grid container head>
			<Grid item xs={12}>
				<BooleanInput source="active_en" defaultValue={true} />
			</Grid>
		</Grid>
		<Grid container>
			<Grid item xs={12}>

				{(answer_type === 'J' || answer_type === 'B' || answer_type === 'BT' || answer_type === 'BTPA' || answer_type === 'BTPV') &&
					<Grid container head>
						<Grid item xs={6}>
							<EscapedTextInput source="text_fr" validate={validateText} />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="text_en" validate={validateText} />
							</ConditionalField>
						</Grid>
					</Grid>
				}

				{answer_type === 'I' &&
					<Container {...parentProps} title={'image'}>
						<GalleryInput type="medias" maxSelection={1} formats={['portrait', 'landscape']} origin={drawerPath} {...parentProps} />
					</Container>
				}

				{answer_type === 'S' && answer_asset_type === 'A' &&
					<Container {...parentProps} title={'audio'}>
						<GalleryInput type="audios" maxSelection={1} origin={drawerPath} {...parentProps} />
					</Container>
				}
				{answer_type === 'S' && answer_asset_type === 'V' &&
					<Container {...parentProps} title={'video'}>
						<GalleryInput type="videos" maxSelection={1} origin={drawerPath} {...parentProps} />
					</Container>
				}

			</Grid>
		</Grid>
	</SimpleForm>
)

export default translate(Form);
