import {LOCATION_CHANGE}   from 'react-router-redux';
import {siteStructure}     from '../routing/routes';

/**
 * 
 * This Reducer is called upon URL changes (action `LOCATION_CHANGE`)
 * and updates the redux state to determine the current active tab in the top bar
 * as well as what to display in the side menu on the left
 * 
 */

// current group from siteStructure is the one that includes pathname in either list of resources or as home page or in menuLayout
const findGroup = pathname => {
	const current = pathname.split('/')[1];
	if(current === 'login' || current === '')
		return siteStructure[0]

	const currentPageGroup = siteStructure.find(group => {
		return (group.tab && group.tab === current)
			|| (group.resources && group.resources.find(resource => resource.name === current))
			|| (group.menuLayout && group.menuLayout.find(subgroup => subgroup.content.find(resource => resource.name === current)));
	});

	if(currentPageGroup)
		currentPageGroup.menuItem = current

	return currentPageGroup;
};

export default (previousState = {}, {type, payload}) => {
	if (type === LOCATION_CHANGE) {
		return {
			currentPageGroup: findGroup(payload.pathname)
		};
	}
	return previousState;
};
