import tokenFetcher from '../tokenFetcher'
import { getApiUrl } from '../utils'

export default (httpClient, serverProvider) => {

	const convertDataRequestToHTTP = (resource, params) => {
		const options = {};
		const url = `${getApiUrl()}/${resource}/${params.id}`;
		options.method = 'DELETE';
		options.headers = tokenFetcher.getHeaders();
		return {
			url,
			options
		};
	}

	const convertHTTPResponse = (response, resource, params) => ({ data: params.previousData || {id: params.id} });

	return (resource, payload) => {
		const { url, options } = convertDataRequestToHTTP(resource, payload);
		return httpClient(url, options).then(response => convertHTTPResponse(response, resource, payload));
	}

}