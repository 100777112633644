import { DELETE } from 'react-admin'

export default (httpClient, serverProvider) => {
	return (resource, payload) => {
		if (!payload.ids || !payload.ids.length) {
			return Promise.reject('Improper payload for DELETE_MANY: needs {ids: []} array not to be empty');
		}
		let ids = payload.ids;
		if (typeof payload.ids[0] === 'object') {
			ids = ids.map(object => object.id);
		}
		return Promise.all(ids.map(id => serverProvider(DELETE, resource, { id })))
			.then(data => ({ data: data.map(item => item.data.id) }));
	}
}