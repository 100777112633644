import sortMediaArray from '../utils/sortMediaArray'
import tokenFetcher from './tokenFetcher'
import { API_TO_BO_RES_NAMES } from './constants'

export const getApiUrl = () => {
	return `https://${window.ARIA_CONFIG.BASE_URL}/${window.ARIA_CONFIG.API_URL}`
}

export const convertHTTPResponse = (response, resource, params) => {
	const { headers, json } = response;
	const result = { data: json.data };
	result.data = parseRecord(resource, result.data);
	return result;
}

export const parseRecord = (resource, record) => {

	// Converting audio payload field to melody when question is Melody
	if (record?.audios && record?.answer_type === 'M'){
		record.melody = record.audios
		record.audios = []
	}

	// creating `file` object for mediaLibraries
	if (resource === 'mediaLibraries') {
		record.file = {
			url: record.url,
			title: record.title
		};
	}

	// parsing all `relationship` attributes into ID arrays
	if (record.relationships) {
		Object.keys(record.relationships).forEach(rel => {
			const ids = record.relationships[rel].map(entry => entry.id)
			const parsedRel = { ids }
			if (record.relationships[rel].length && record.relationships[rel][0].position !== undefined) {
				parsedRel.positions = record.relationships[rel].map(entry => entry.position)
			}
			record.relationships[rel] = parsedRel

			// artworks need a two dimentional array because several ≠ WYSIWYG can contribute to it
			// when receiving from server, array of IDs is put into Array[0]
			// forms will populate both dimensions, and a flattened version will be sent
			// this means all PATCH request should contain this key

			// in one specific instance (questions), artworks can be used as a regular relationship
			// in which case they are added w/ <ManyTypesRelationshipsInput> which doesn't understand the two dimensional array

			// 🚧 if "questions" come to need a WYSIWYG w/ 'artworks' capabilities, we'll have to rethink this
			if (rel === 'artworks' && resource !== 'questions') {
				record.relationships[rel].ids = [record.relationships[rel].ids]
			}
		})
	}

	if (resource === 'badges') {
		record.rules && record.rules.forEach(rule => rule.tag_id = rule.tag.id)
	}

	return record;
};

export const parseHomepageHighlights = ({ data }) => {
	const relationships = {}
	data.filter(item => !!item)
		.forEach(item => {
			const resourceName = API_TO_BO_RES_NAMES.get(item.content_type)
			delete item.content_type

			relationships[resourceName] || (relationships[resourceName] = { ids: [], positions: [] })
			relationships[resourceName].ids.push(item.id)
			relationships[resourceName].positions.push(item.position)
		})
	return {
		data: {
			id: 1,
			relationships,
		}
	}
}

// [{id, position}, {id, position}] => sort by position
const sortObjectsByPos = (objects) => objects.sort(({ position: posA }, { position: posB }) => posA - posB) // will mutate argument

// {ids: [], positions: []} => sort by position into [{id, position}, {id, position}]
const doubleArrayToObjects = ({ ids, positions }) => {
	if (!positions)
		return ids.map(id => ({ id }))
	const objects = ids.map((id, i) => ({ id, position: positions[i] }))
	sortObjectsByPos(objects)
	return objects
}

// {ids: [], positions: []} => [id, id]
const doubleArrayToArray = (arg) => {
	const objects = doubleArrayToObjects(arg)
	return objects.map(({ id }) => id)
}

const noNullInArray = (array) => array.filter(id => !!id)

const noNullInObjects = (array) => array.filter(({ id }) => !!id)

const objectsToRules = (objects) => objects.map(({ quantity, tag_id }) => ({ quantity, tag_id }))

const unrefArray = (array) => array.length === 0 ? [] : [...array]

// flatten array and makes sure each item is unique
const mergeArrays = (arrays) => Array.from(new Set(arrays.flat()))

const canSimpleCompare = object => typeof object !== 'object'

// true if arrays of identical IDs
const compareArrays = (arrayA, arrayB) => arrayA.length === arrayB.length && arrayA.every((item, i) => item === arrayB[i])

// true if both arrays have same ids and same positions in the same order
const compareObjects = (argA, argB) => {
	const objectsA = unrefArray(argA)
	const objectsB = unrefArray(argB)
	sortObjectsByPos(objectsA)
	sortObjectsByPos(objectsB)
	return objectsA.length === objectsB.length && objectsA.every((item, i) => item.position === objectsB[i].position && objectsB[i].id === item.id)
}


const compareBadgeRules = (argA, argB) => {
	const arrayA = unrefArray(argA)
	const arrayB = unrefArray(argB)
	arrayA.sort(({ tag_id: tagA }, { tag_id: tagB }) => tagA - tagB)
	arrayB.sort(({ tag_id: tagA }, { tag_id: tagB }) => tagA - tagB)
	return arrayA.length === arrayB.length && arrayA.every((item, i) => item.quantity === arrayB[i].quantity && item.tag_id === arrayB[i].tag_id)
}

const exists = object => object !== false && object !== undefined && object !== null

/**
 * The many ways the API works (instead of 1 common structure for everything) makes the following SWITCH / CASE a good choice for understandability / maintainability of the following:
 * - getNewValues
 * - getPreviousValue
 * - compareValues
 * - getApiObjectKey
 * **BUT**
 * The relationships w/ `sessions` have 2 ≠ ways of working. So depending on the `resource` they're linked to, they're processed differently.
 * This is because the API sends and expects ≠ things for the same resource.
 * **THUS**
 * We jump over the `return` statement based on the `resource`, don't add a `break` statement, and directly follow with the alternative case for `sessions`.
 * This makes the order of the `case` list very important.
 * If more cases like this arise, we might need to restructure the following functions.
 */

const log = (...args) => window.ARIA_CONFIG.OWNER === 'Mazarine' && console.log(...args)

export const getNewValues = (payload, rel, resource) => {
	// ⚠️ `sessions` has 2 cases, order is important. (See above for more info)
	switch (rel) {
		case 'artworks':
			// payload.relationships[rel].ids => [[ids], [ids]] => `${rel}_id` = [ids]
			return exists(payload.relationships)
				&& exists(payload.relationships[rel])
				&& exists(payload.relationships[rel].ids)
				&& noNullInArray(mergeArrays(payload.relationships[rel].ids))
		case 'tags':
		case 'primaryTags':
		case 'secondaryTags':
		case 'themes':
			// payload.relationships[rel].ids => [id] => `${rel}_id` = [ids]
			log('getNewValues => unrefArray', rel, payload)
			return exists(payload.relationships)
				&& exists(payload.relationships[rel])
				&& exists(payload.relationships[rel].ids)
				&& noNullInArray(unrefArray(payload.relationships[rel].ids))
		case 'possibleAnswers':
		case 'questions':
		case 'cards':
		case 'sessions':
			// payload.relationships[rel] => [ids], [pos] => `${rel}_id` = [ids]
			if (resource !== 'homepageHighlights') {
				log('getNewValues => doubleArrayToArray', rel, payload)
				return exists(payload.relationships)
					&& exists(payload.relationships[rel])
					&& exists(payload.relationships[rel].ids)
					&& noNullInArray(doubleArrayToArray(payload.relationships[rel]))
			}
		case 'questionnaires':
		case 'transitions':
		case 'stories':
		case 'courses':
		case 'sessions':
			// payload.relationships[rel] => [ids], [pos] => [rel] = [{id, pos}]
			log('getNewValues => doubleArrayToObjects', rel, payload)
			return exists(payload.relationships)
				&& exists(payload.relationships[rel])
				&& exists(payload.relationships[rel].ids)
				&& noNullInObjects(doubleArrayToObjects(payload.relationships[rel]))
		case 'goodAnswer':
			// payload[rel] => id => `${rel}_id` = id
			log('getNewValues => payload', rel, payload)
			const id = payload[rel]
			return typeof id === 'number' && id
		case 'rules':
			// payload[rel] => [{tag_id, quantity}] => [rel] = [{tag_id, quantity}]
			log('getNewValues => objectsToRules', rel, payload)
			return exists(payload[rel])
				&& objectsToRules(payload[rel])
		default:
			return []
	}
}

export const getPreviousValue = (previous, rel, resource) => {
	// ⚠️ `sessions` has 2 cases, order is important. (See above for more info)
	switch (rel) {
		case 'artworks':
			// previous.relationships[rel].ids => [[ids], [ids]] => [ids]
			return exists(previous.relationships)
				&& exists(previous.relationships[rel])
				&& exists(previous.relationships[rel].ids)
				&& noNullInArray(mergeArrays(previous.relationships[rel].ids))
		case 'tags':
		case 'primaryTags':
		case 'secondaryTags':
		case 'themes':
		case 'possibleAnswers':
		case 'questions':
		case 'cards':
		case 'sessions':
			// previous.relationships[rel] => [ids]
			if (resource !== 'homepageHighlights') {
				log('getPreviousValue => unrefArray', rel, previous)
				return exists(previous.relationships)
					&& exists(previous.relationships[rel])
					&& exists(previous.relationships[rel].ids)
					&& noNullInArray(unrefArray(previous.relationships[rel].ids))
			}
		case 'questionnaires':
		case 'transitions':
		case 'stories':
		case 'courses':
		case 'sessions':
			// previous.relationships[rel] => [{id, pos}]
			log('getPreviousValue => doubleArrayToObjects', rel, previous)
			return exists(previous.relationships)
				&& exists(previous.relationships[rel])
				&& exists(previous.relationships[rel].ids)
				&& noNullInObjects(doubleArrayToObjects(previous.relationships[rel]))
		case 'goodAnswer':
			// previous[`${rel}_id`] => id
			log('getPreviousValue => previous', rel, previous)
			const id = previous[`${rel}_id`]
			return typeof id === 'number' && id
		case 'rules':
			// previous[rel] => {tag_id, quantity} => [rel] = {tag_id, quantity}
			log('getPreviousValue => objectsToRules', rel, previous)
			return exists(previous[rel])
				&& objectsToRules(previous[rel])
		default:
			return []
	}
}

export const compareValues = (newValues, prevValue, rel, resource) => {
	// ⚠️ `sessions` has 2 cases, order is important. (See above for more info)

	// if one (of new & prev) exists and not the other, things are different!
	const newExists = exists(newValues)
	const prevExists = exists(prevValue)
	if (newExists !== prevExists)
		return false

	// if variables are content and not pointers (i.e. not typeof 'object')
	if (canSimpleCompare(newValues) && canSimpleCompare(prevValue))
		return newValues === prevValue

	// otherwise, actually check
	switch (rel) {
		case 'artworks':
			// order doesn't matter so we sort before we compare
			newValues.sort()
			prevValue.sort()
		case 'tags':
		case 'primaryTags':
		case 'secondaryTags':
		case 'themes':
		case 'possibleAnswers':
		case 'questions':
		case 'cards':
		case 'sessions':
			// [ids]
			if (resource !== 'homepageHighlights') {
				log('compareValues => compareArrays', rel, newValues, prevValue)
				return compareArrays(newValues, prevValue)
			}
		case 'questionnaires':
		case 'transitions':
		case 'stories':
		case 'courses':
		case 'sessions':
			// [{id, pos}]
			log('compareValues => compareObjects', rel, newValues, prevValue)
			return compareObjects(newValues, prevValue)
		case 'goodAnswer':
			// id
			log('compareValues => newValues', rel, newValues, prevValue)
			return newValues === prevValue
		case 'rules':
			log('compareValues => compareBadgeRules', rel, newValues, prevValue)
			return compareBadgeRules(newValues, prevValue)
		default:
			return true
	}
}

export const getApiObjectKey = (rel, resource) => {
	// ⚠️ `sessions` has 2 cases, order is important. (See above for more info)
	switch (rel) {
		case 'artworks':
		case 'tags':
		case 'primaryTags':
		case 'secondaryTags':
		case 'themes':
		case 'possibleAnswers':
		case 'questions':
		case 'cards':
		case 'sessions':
		case 'goodAnswer':
			if (!['homepageHighlights'].includes(resource))
				return `${rel}_id`
		case 'questionnaires':
		case 'transitions':
		case 'stories':
		case 'rules':
		case 'courses':
		case 'sessions':
			return `${rel}`
		default:
			return false
	}
}

export const makeMediaObject = (payload) => {
	if (!exists(payload.medias) || payload.medias.length === 0)
		return false
	const newMedias = {};
	sortMediaArray(payload.medias)
	payload.medias.forEach((media, i) => {
		if (media === null)
			return
		if (!media.formats) {
			newMedias[media.id] = {
				formats: {
					free: {
						crop_x: null,
						crop_y: null,
						crop_w: null,
						crop_h: null
					}
				}
			};
		} else {
			// TODO: this will always construct and send a new media object, even if ID, formats, and crops haven't changed
			newMedias[media.id] = { formats: {} }
			media.formats.forEach((object, i) => {
				const format = Object.keys(object)[0];
				const { crop_h, crop_w, crop_x, crop_y } = object[format];
				newMedias[media.id].formats[format] = { crop_h, crop_w, crop_x, crop_y };
			});
		}
		newMedias[media.id].position = i + 1;
	});
	return newMedias;
}

export const makeLibrariesPatch = (payload, previous, res) => {
	sortMediaArray(payload[res])
	if (exists(previous[res])) {
		sortMediaArray(previous[res])
		const oldData = previous[res].map(({ id }, i) => ({ id, position: i + 1 }))
		const newData = payload[res].map(({ id }, i) => ({ id, position: i + 1 }))
		if (compareObjects(oldData, newData))
			return false
		else
			return newData.map(({ id }, i) => id)
	} else
		return payload[res].map(({ id }, i) => id)
}

const buildFormDataUpdateBody = (payload, previous, fields) => {

	// if params.data.subtitle_file_url_locale is a string, it hasn't changed. If null it was deleted. If object, it requires uploading.
	const formData = new FormData();
	let none = 0

	fields.forEach(([formField, uploadName]) => {
		if (payload[formField]) {
			if (typeof payload[formField] === 'object') formData.append(uploadName, payload[formField].rawFile);
			else none++
		} else {
			if (previous && previous[formField]) formData.append(uploadName, null);
			else none++
		}
	})

	if (none === fields.length)
		return false

	return formData
}

export const buildFormDataUpdateRequest = (endpoint, params, fields) => {
	const rulesPostBody = buildFormDataUpdateBody(params.data, params.previousData, fields)

	if (!rulesPostBody)
		return { empty: true }

	const url = `${getApiUrl()}/${endpoint}?mode=contribution`
	const options = {
		body: rulesPostBody,
		method: 'POST',
		headers: tokenFetcher.getHeaders(),
	};

	fields.forEach(([formField]) => {
		if (params.data[formField])
			delete params.data[formField];
	})

	return { url, options }
}

export const makePostDataRequest = (httpClient, endpoint, params, fields) => {
	const { url, options, empty } = buildFormDataUpdateRequest(endpoint, params, fields)

	if (empty)
		return Promise.resolve()

	return httpClient(url, options)
}
