/**
 * Dictionnary of the names each resource has in the FrontStage project (e.g. "artworks" in the BackOffice and the API is called "knowmore" in FrontStage)
 */

export default {
	questions: 'question',
	sessions: 'session',
	courses: 'course',
	cards: 'card',
	transitions: 'transition',
	artworks: 'knowmore'

}