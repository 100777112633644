import React from 'react'
import get from 'lodash/get'
import { TextField } from 'react-admin'
import parseHTML from '../../utils/parseHTML'

export default ({record, source, ...props}) => {
	const escapedRecord = Object.assign({}, record, {
		[source]: parseHTML(get(record, source))
	})
	return <TextField record={escapedRecord} source={source} {...props} />
}