import { getApiUrl, parseRecord } from '../utils'

export default (httpClient) => {

	const convertDataRequestToHTTP = (resource, params) => {
		return {
			url: `${getApiUrl()}/${resource}?qId=${params.ids.join(',')}&mode=contribution`,
		};
	}

	return (resource, payload) => {

		if (!payload.ids || !payload.ids.length)
			return Promise.reject('Improper payload for GET_MANY: needs {ids: []} array not to be empty');

		let { ids } = payload;

		if (typeof ids[0] === 'object')
			ids = ids.map(object => object.id);

		const { url, options } = convertDataRequestToHTTP(resource, payload);
		return httpClient(url, options).then(({ json }) => ({
			data: json.data.map(data => parseRecord(resource, data))
		}));
	}
}