import React from 'react';
import { uniqBy } from 'lodash';
import { Button, translate } from 'react-admin';
import { connect } from 'react-redux';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import stringToRatio from '../../../utils/stringToRatio';
import { compose } from 'recompose';

const ListBulkActions = ({ storeFormats, selectedIds, field, data, reduxData, controls, maxSelection, resource, formats, setListSelectedIds, translate }) => {
	const count = selectedIds.length;
	const alreadyAddedCount = Array.isArray(field.input.value) ? field.input.value.filter(media => media !== null).length : 0
	const totalCount = alreadyAddedCount + count;
	const isMaxSelection = totalCount > maxSelection;
	const onClick = () => {
		const additionalData = selectedIds.map(id => {
			const newRecord = reduxData.data[id]
			if (field.input.name === 'medias') {

				// center on largest axis, fill on smallest axis
				const { width, height } = newRecord
				newRecord.formats = []
				formats.sort().forEach((format, i) => {
					// float value of ratio defined as width / height. False if format==="free".
					const ratio = storeFormats[format].aspectRatio ? stringToRatio(storeFormats[format].aspectRatio) : false
					// whether image is proportionnaly wider than ratio
					const tooWide = width / height > ratio
					const crop_h = ratio
						? (tooWide ? height : width / ratio)
						: height / 2
					const crop_w = ratio
						? (tooWide ? height * ratio : width)
						: width / 2

					newRecord.formats[i] = {
						[format]: {
							crop_h,
							crop_w,
							crop_x: (width - crop_w) / 2,
							crop_y: (height - crop_h) / 2,
						}
					}
				})
			}
			return newRecord
		});
		const oldData = data || []
		const newArray = uniqBy(oldData.concat(additionalData), 'id');
		newArray.filter(item => !!item)
			.forEach((item, i) => {
				if(item)
					item.position = i + 1
			});
		controls.current.close();
		field.input.onChange(newArray)

		// Unselect all checkboxes
		setListSelectedIds(resource, []);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{isMaxSelection &&
				<span style={{ color: 'red', marginRight: '10px' }}>
					{translate('interface.drawers.maximum_selection', {
						count: maxSelection,
						plural: maxSelection > 1 ? 's' : ''
					})}
				</span>
			}
			<Button
				label={translate('interface.drawers.add_selection', {
					count,
					plural: count > 1 ? 's' : ''
				})}
				onClick={onClick}
				disabled={isMaxSelection}
				variant="outlined"
			>
				<PlaylistAddIcon />
			</Button>
		</div>
	);
};

const DEBUG_HACK_REDUX_INITIAL_STATE = {
	settings: {
		cloudinary: {
			images_definition: {
				square: { aspectRatio: '1/1' },
				landscape: { aspectRatio: '16/9' },
				portrait: { aspectRatio: '9/16' },
				fiche: { aspectRatio: '4/3' },
				vertical: { aspectRatio: '4/5' },
				free: { aspectRatio: null },
			}
		},
	}
}

const mapStateToProps = state => {
	const storeFormats = DEBUG_HACK_REDUX_INITIAL_STATE.settings.cloudinary.images_definition
	return ({ storeFormats })
}

export default compose(
	connect(mapStateToProps),
	translate,
)(ListBulkActions)