import React from 'react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import stringToRatio from '../../utils/stringToRatio';

const getComparisonKeys = crop => ['height', 'width', 'x', 'y']
	.filter(param => Object.keys(crop).includes(param)) // only test keys specified above
	.filter(param => typeof crop[param] === 'number') // ignore if value in crop is undefined

const cropsAreDifferent = (referenceCrop, newCrop) => {
	const referenceKeys = getComparisonKeys(referenceCrop)

	if(!referenceKeys.length)
		return true

	return referenceKeys.reduce((acc, curr) => acc || referenceCrop[curr] !== newCrop[curr], false); // return true if arrays have at lest 1 value mismatched
}

const noNan = number => Number.isNaN(number) ? undefined : number

const percentToPixels = (size, crop) => ({
	crop_h: noNan( (size.height * crop.height / 100) ),
	crop_w: noNan( (size.width  * crop.width  / 100) ),
	crop_x: noNan( (size.width  * crop.x      / 100) ),
	crop_y: noNan( (size.height * crop.y      / 100) ),
})

const pixelsToPercent = (size, crop) => ({
	height: noNan( ((crop.crop_h || crop.height) / size.height * 100) ),
	width:  noNan( ((crop.crop_w || crop.width)  / size.width * 100)  ),
	x:      noNan( ((crop.crop_x || crop.x)      / size.width * 100)  ),
	y:      noNan( ((crop.crop_y || crop.y)      / size.height * 100) ),
})

/**
 * ⚠️the `pixelCrop` values returned by the `<ReactCrop>` functions are based on **current size** of image, and not actual size of image *file*. Incorrect if image is resized by CSS.
 * 
 * @param {String} src url from react-admin record
 * @param {String} aspectRatio [optional] string formula for aspect ratio, as found in redux store `settings.cloudinary.image_definition`
 * @param {Object} cropInPixels [optional] object defining a crop. Structure is as returned by the API (but you should omit one dimension if aspect ratio isn't set to 'free'). Units are pixels (as returned by API).
 * @param {Function} onChange function from `<Field>` props (`field.input.onChange`)
 * @param {Object} size actual width and height of the image file in pixels
 * 
 * @example
<ImageCropsInput
	src={record.url}
	aspectRatio={formats[format].aspectRatio}
	initialCropInPercent={initialCrop}
	onChange={field ? field.input.onChange : onChange}
	classes={classes.reactCropImage}
	inlineStyle={ImageCropsInput.reactCropInlineStyle}
	size={{ width: record.width, height: record.height }}
/>
 * 
 */

class ImageCropsInput extends React.PureComponent {

	constructor(props) {
		super(props);
		if(props.cropInPixels) {
			const convertedCrop = pixelsToPercent(props.size, props.cropInPixels)
			convertedCrop.unit = '%'
			this.initialCropInPercent = convertedCrop
		} else {
			this.initialCropInPercent = {}
		}
		this.state = { crop: {} };
		this.onCropComplete = this.onCropComplete.bind(this)
		this.onCropChange = this.onCropChange.bind(this)
	};

	async onCropComplete(pixelCrop, percentCrop) {
		const shouldUpdate = cropsAreDifferent(this.initialCropInPercent, percentCrop);
		if (shouldUpdate) {
			const convertedCrop = percentToPixels(this.props.size, percentCrop)
			this.props.onChange(convertedCrop);
		}
	};

	onCropChange = (pixelCrop, percentCrop) => {
		const shouldUpdate = cropsAreDifferent(this.state.crop, percentCrop);
		if(!Object.keys(this.state.crop).length || shouldUpdate) {
			this.setState({ crop: percentCrop });
		}
	};

	onDragStart = (e) => {
		e.stopPropagation()
		if(this.props.onFocus)
			this.props.onFocus()
	};

	onDragEnd = (e) => {
		e.stopPropagation()
		if(this.props.onBlur)
			this.props.onBlur()
	};

	render() {
		const { crop } = this.state;
		const { src, aspectRatio, classes, inlineStyle } = this.props

		const formatRatio = aspectRatio
			? stringToRatio(aspectRatio)
			: undefined;

		const initial = {
			aspect: formatRatio,
			...(!Object.keys(crop).length ? this.initialCropInPercent : crop)
		}

		return (
			<ReactCrop
				src={src}
				crop={initial}
				onComplete={this.onCropComplete}
				onChange={this.onCropChange}
				className={`react-crop react-crop-${aspectRatio} ${classes ? classes : ''}`}
				style={inlineStyle.wrapper}
				imageStyle={inlineStyle.image}
				onDragStart={this.onDragStart}
				onDragEnd={this.onDragEnd}
				// keepSelection={true}
			/>
		);
	}
}

export default ImageCropsInput;