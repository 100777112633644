import React from 'react';
import { MenuItemLink, translate } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import { appBarRoutes } from '../../routing/routes';
import AppBar from './AppBarWrapper'

import { connect } from 'react-redux';
import compose from 'recompose/compose';

const styles = theme => {
	const envChipBgColor = window.ARIA_CONFIG.OWNER === 'Mazarine'
		? theme.chip.recette[800]
		: window.ARIA_CONFIG.API_ENV === 'PREPROD'
			? theme.chip.preprod[800]
			: window.ARIA_CONFIG.API_ENV === 'STAGING'
				? theme.chip.recette[800]
				: theme.palette.primary.main
	return createStyles({
		title: {
			flex: 1,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		spacer: {
			flex: 1,
		},
		chip: {
			backgroundColor: envChipBgColor,
			marginLeft: 10,
			marginRight: 10,
		},
		menuButton: {
			color: '#a1a1a1',
			textTransform: 'uppercase',
			fontSize: 14,
		},
		activeTab: {
			color: '#FFF',
			fontWeight: 'bold'
		}
	})
};

const CustomAppBar = ({ translate, classes, layout, ...props }) => (
	<AppBar {...props}>
		<Chip size="small" label={`Environnement : ${window.ARIA_CONFIG.API_ENV} ${window.ARIA_CONFIG.OWNER}`} className={classes.chip} color="primary" />
		<Toolbar>
		</Toolbar>
		<span className={classes.spacer} />
		{appBarRoutes.map(({ tab, path }) => <MenuItemLink
			className={`${classes.menuButton} ${layout && layout.tab === tab ? classes.activeTab : ''}`}
			key={tab}
			to={`/${path}`}
			primaryText={translate(`interface.topBar.${tab}`)}
		/>
		)}
	</AppBar>
);

const mapStateToProps = state => ({
	layout: state.location.currentPageGroup,
	locale: state.locale, // force redraw on locale change
});

export default compose(
	connect(mapStateToProps, {}),
	withStyles(styles),
	translate,
)(CustomAppBar);
