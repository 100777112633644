import React from 'react';
import get from 'lodash/get';

const VideoField = ({record = {}, source, poster, muted = true, preload = 'none', ...props}) => {
	const attributes = {
		muted,
		preload,
	};
	if (poster) {
		attributes.poster = get(record, poster);
	}
	const style = {
		objectFit: 'contain',
		maxHeight: '250px',
		maxWidth: '250px',
	};

	delete props.basePath;

	return (
		<video controls {...attributes} style={style} {...props}>
			<source src={get(record, source)} type="video/mp4"/>
			Sorry, your browser doesn't support embedded videos.
		</video>
	);
};

export default VideoField