import React from 'react';
import {
	FormDataConsumer,
	ReferenceArrayInput,
	AutocompleteArrayInput,
} from 'react-admin';
import get from 'lodash/get'

/**
 * @param {string} source react-admin style source, like for any other field or input (should point to an array of integers)
 * @param {array<string>} exclude array of strings of react-admin style sources pointing to the multiple arrays of integers to exclude from the Autocomplete list
 * 
 * @usage
<SecondaryTagsInput 
	source="relationships.secondaryTags.ids" 
	exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']}
/>
 */

const SecondaryTagsInput = ({ source, exclude = [], ...props }) =>
	<FormDataConsumer {...props}>
		{({ formData, dispatch, ...rest }) => {
			const excludeIds = exclude.map(path => get(formData, path)).flat()
			return (
				<ReferenceArrayInput
					{...rest}
					source={source}
					reference="tags"
					filter={{ published: true, excludeIds }}
					label="secondary_tags"
					sort={{ field: 'label', order: 'ASC' }}
					allowEmpty
				>
					<AutocompleteArrayInput
						optionText="label_fr"
						shouldRenderSuggestions={value => value.trim().length > 2 }
						limitChoicesToValue={true}
					/>
				</ReferenceArrayInput>
			)
		}}
	</FormDataConsumer>

export default SecondaryTagsInput