import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';

import { 
    LoadingIndicator,
    UserMenu,
    Headroom,
    toggleSidebar as toggleSidebarAction 
} from 'react-admin';

const styles = theme =>
    createStyles({
        logo: {
            overflow: 'visible',
            height: '2rem',
            margin: '0 1rem',
        },
        toolbar: {
            paddingRight: 24,
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    });

const AppBar = ({
    children,
    classes,
    className,
    logo,
    logout,
    open,
    title,
    toggleSidebar,
    userMenu,
    width,
    ...rest
}) => (
        <Headroom>
            <MuiAppBar
                className={className}
                color="secondary"
                position="static"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <svg className={classes.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 41.5">
                        <g fill="#FFF" fillRule="evenodd">
                            <path d="M106.9,39.5c3.8,0,8.1-3,10-7.1V21.9c-2.9-1.3-5.5-1.9-9-1.9c-4.3,0-9,2.5-9,10.4C98.9,36.6,102.5,39.5,106.9,39.5 M100.7,1.2c2.7-1.1,6.6-1.2,6.6-1.2c9.5,0,16.6,3.7,16.6,13.7v12.9c0,11.3,0.7,11.4,3.9,11.5l1.7,0.1v1.8h-12.5v-6c-2,3.3-5.4,6.9-12.5,6.9c-7.6,0-13.6-4-13.6-11.1c0-7.5,5.3-11.2,15.5-11.2c4.2,0,7.1,0.6,10.5,2.1v-9.5c0-5.9-2.7-9.7-9.5-10.4C103.7,0.5,100.7,1.2,100.7,1.2"/>
                            <path d="M103.4,7.4c0-2.8-2.2-5-4.9-5s-4.9,2.2-4.9,5c0,2.8,2.2,5,4.9,5C101.2,12.5,103.4,10.2,103.4,7.4 M68,7.5c0-2.8-2.2-5-4.9-5c-2.7,0-4.9,2.2-4.9,5c0,2.8,2.2,5,4.9,5C65.7,12.5,68,10.2,68,7.5 M84.7-10.6c0-2.7-2.2-5-4.9-5c-2.7,0-4.9,2.2-4.9,5c0,2.7,2.2,5,4.9,5C82.6-5.6,84.7-7.9,84.7-10.6 M70.9,40.1v-1.8l1.7-0.1c3.2-0.2,3.8-0.3,3.8-11.5v-12c0-11.2-0.7-11.3-3.8-11.5l-1.7-0.1V1.3h12.7v25.4c0,11.2,0.7,11.3,3.8,11.5l1.7,0.1v1.8C89.1,40.1,70.9,40.1,70.9,40.1z M7.5,12.5c2.7,0,4.9-2.2,4.9-5s-2.2-5-4.9-5s-4.9,2.2-4.9,5S4.8,12.5,7.5,12.5"/>
                            <path d="M26,32.3c-1.9,4.1-6.3,7.1-10,7.1c-4.4,0-8-2.9-8-9.1C8,22.5,12.7,20,17,20c3.5,0,6.1,0.6,9,1.9C26,21.9,26,32.3,26,32.3z M60.3,0.3c-3.9,0-7.5,0.7-9.9,2.3V1H38.5v1.7l1.6,0.1c3,0.2,3.7,0.2,3.7,10.9v13.6c0,10.4-0.6,10.8-3.5,10.9c-0.7,0-1.9,0-2.7-0.1l-0.7,0c-3.2-0.2-3.9-0.3-3.9-11.5V13.7C33.1,3.7,26,0,16.5,0c0,0-4,0.1-6.6,1.2c0,0,3-0.6,6.7-0.3C23.3,1.6,26,5.4,26,11.3v9.5c-3.3-1.5-6.3-2.1-10.5-2.1C5.3,18.8,0,22.4,0,30c0,7.1,6,11.1,13.6,11.1c7.1,0,10.5-3.6,12.5-6.9v6h31.3v-1.7L55,38.3c-3.8-0.2-4.5-0.2-4.5-10.9V10.6c0-6.9,4.2-8.8,7.8-9.7c3.2-0.7,4.6-0.1,4.6-0.1C62.2,0.4,60.3,0.3,60.3,0.3z"/>
                        </g>
                    </svg>
                    {Children.count(children) === 0 ? (
                        <Typography
                            variant="title"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                            children
                        )}
                    <LoadingIndicator />
                    {cloneElement(userMenu, { logout })}
                </Toolbar>
            </MuiAppBar>
        </Headroom>
    );

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <UserMenu />,
};

const enhance = compose(
    connect(
        state => ({
            locale: state.i18n.locale, // force redraw on locale change
        }),
        {
            toggleSidebar: toggleSidebarAction,
        }
    ),
    withStyles(styles),
    withWidth()
);

export default enhance(AppBar);