import { getApiUrl } from './utils'

class TokenFetcher {
	constructor() {
		this.timeout = false

		this.newTokenRequest = this.newTokenRequest.bind(this)
		this.refreshTokenRequest = this.refreshTokenRequest.bind(this)
		this.fetchToken = this.fetchToken.bind(this)
		this.clearTimeout = this.clearTimeout.bind(this)
		this.clearStorage = this.clearStorage.bind(this)
		this.clearToken = this.clearToken.bind(this)
		this.isTokenValid = this.isTokenValid.bind(this)
		this.getHeaders = this.getHeaders.bind(this)
		this.logoutRequest = this.logoutRequest.bind(this)
		this.logout = this.logout.bind(this)
		this.startTimeout = this.startTimeout.bind(this)
		this.getToken = this.getToken.bind(this)

		this.startTimeout()
	}

	async fetchToken(username, password) {
		this.clearTimeout()

		const request = this.isTokenValid()
			? this.refreshTokenRequest()
			: this.newTokenRequest(username, password)

		return new Promise((resolve, reject) => fetch(request)
			.catch(reject)
			.then(async (response) => {
				if (!response || !response.status || response.status < 200 || response.status >= 300) {
					this.clearStorage()
					reject((response && response.statusText) || 'No response from server');
				} else {
					// parse
					const { access_token, token_type, expires_in } = await response.json();
					// store
					const expires_at = Date.now() + expires_in * 60 * 1000
					localStorage.setItem('token', access_token);
					localStorage.setItem('token_type', token_type);
					localStorage.setItem('expires_at', expires_at);
					// refresh 10sec before expiration
					this.startTimeout()
					// return
					resolve()
				}
			})
		)
	};

	getToken() {
		if (this.isTokenValid())
			return {
				token: localStorage.getItem('token'),
				type: localStorage.getItem('token_type'),
			};

		else
			this.logout();
	}

	newTokenRequest(username, password) {
		return new Request(`${this.getAuthUrl()}/login`, {
			method: 'POST',
			body: JSON.stringify({ email: username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
	}

	refreshTokenRequest() {
		return new Request(`${this.getAuthUrl()}/refresh`, {
			method: 'GET',
			headers: this.getHeaders(),
		});
	}

	logoutRequest() {
		return new Request(`${this.getAuthUrl()}/logout`, {
			method: 'GET',
			headers: this.getHeaders(),
		});
	}

	startTimeout() {
		this.clearTimeout()
		const expires_at = localStorage.getItem('expires_at')
		const now = Date.now()
		if (expires_at && expires_at > now)
			this.timeout = setTimeout(this.fetchToken, expires_at - now - 59000)
	}

	clearTimeout() {
		this.timeout && clearTimeout(this.timeout);
	}

	clearStorage() {
		localStorage.removeItem('token');
		localStorage.removeItem('token_type');
		localStorage.removeItem('expires_at');
	}

	clearToken() {
		this.clearStorage()
		this.clearTimeout()
	}

	logout() {
		fetch(this.logoutRequest())
		this.clearToken()
	}

	isTokenValid() {
		return Date.now() < +localStorage.getItem('expires_at') || 0;
	}

	getHeaders() {
		return new Headers({ Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') })
	}

	getAuthUrl() {
		return `${getApiUrl()}/admin/auth`
	}
}

export default new TokenFetcher()