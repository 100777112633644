import React from 'react';
import {
	List,
	Datagrid,
	ImageField,
	CreateButton,
	CardActions,
	Filter,
	TextInput,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import MediaGrid, { MediaGridToggleButton, getMediaGridStorage } from '../../components/parts/MediaGrid'
import EditMediaInDrawer, { getOpen } from '../../components/fields/editMediaInDrawer';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { name, edit } from './'
import { withStyles } from '@material-ui/core';



const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
	</Filter>
));

const DefaultListActions = ({ grid, setGrid, basePath }) => {
	return (
		<CardActions>
			<MediaGridToggleButton grid={grid} setGrid={setGrid} />
			<CreateButton basePath={basePath} />
		</CardActions>
	);
};

const EditField = ({ getOpen, record }) => {
	return (
		<IconButton onClick={getOpen(record.id)} color="primary">
			<EditIcon />
		</IconButton>
	)
}

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const MediaLibrariesGrid = withStyles(gridStyles)(({classes, getOpenWithControls, editInDrawer, ...props}) => (
	<Datagrid key="datagrid" {...props}>
		<EscapedTextField source="id"/>
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<EscapedTextField source="legend_fr" className={classes.abbrev}/>
		<EscapedTextField source="artist" />
		<ImageField label="image" source="file.url" />
		<DateTimeField source="updated_at" />
		{editInDrawer &&
			<EditField getOpen={getOpenWithControls}/>
		}
	</Datagrid>
))

const MediaLibrarieList = ({ classes, filter, drawerPath, rowClick = 'edit', editInDrawer = false, actions, ...props }) => {

	const [grid, setGrid] = React.useState(getMediaGridStorage())
	const StatefulListActions = React.cloneElement(actions || <DefaultListActions />, { grid, setGrid })

	const controls = React.useRef({})
	const getOpenWithControls = getOpen(controls)

	return (
		<>
			<List
				key={`hacky-key-${grid}`}
				actions={StatefulListActions}
				filters={<PostFilter />}
				filter={filter}
				exporter={false}
				{...props}
				perPage={getPerPage()}
				pagination={<PostPagination />}
			>
				{grid
					? <MediaGrid
						key="mediagrid"
						rowClick={rowClick}
						imageSource="file.url"
						subtitleSource="artist"
						editInDrawer={editInDrawer}
						getOpen={getOpenWithControls}
					/>
					: <MediaLibrariesGrid 
						rowClick={rowClick} 
						getOpenWithControls={getOpenWithControls}
						editInDrawer={editInDrawer}
					/>
				}
			</List>
			{editInDrawer &&
				<EditMediaInDrawer name={name} edit={edit} controls={controls} drawerPath={drawerPath} />
			}
		</>
	);
};

export default MediaLibrarieList;
