import React        from 'react';
import Typography   from '@material-ui/core/Typography';
import Grid         from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {translate}  from 'react-admin';
import compose      from 'recompose/compose';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
	heading: {
		fontSize: '15px',
		fontWeight: 'bold',
	},
	root: {
		margin: '15px'
	},
	title: {
		backgroundColor: '#FFFFFF',
	},
	detail: {
		marginLeft: '30px'
	},
	detailContainer: {
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		width: '100%'
	}
});

const LayoutContainer = ({children, expanded, title, classes, translate, ...props}) => {
	return (
		<Grid className={classes.root} item>
			<ExpansionPanel className={classes.title} defaultExpanded={expanded === undefined ? true : expanded}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant='subheading' className={classes.heading}> {translate(`resources.${props.resource}.containers.${title}`)}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.detail}>
					<div className={classes.detailContainer} >
						{React.Children.map(children, child => child && React.cloneElement(child, Object.assign({}, props, child.props)))}
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</Grid>
	);
};

export default compose(
	withStyles(styles),
	translate
)(LayoutContainer);

