import React from 'react'
import { FileInput, TextField, FormDataConsumer } from 'react-admin'

import get from 'lodash/get'

import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import { withStyles } from '@material-ui/core';

const FileField = ({ record }) => {
	if (record.rawFile && record.rawFile.name)
		return <TextField source="name" record={record.rawFile} />
	return <TextField source="name" record={{ name: record.split('/').pop() }} />
}

const linkStyles = theme => ({
	icon: {
		fontSize: theme.spacing.unit * 2,
		marginLeft: theme.spacing.unit,
		position: 'relative',
		bottom: theme.spacing.unit / 4,
	},
})

const Link = withStyles(linkStyles)(({record, source, classes}) => {

	const value = get(record, source)

	if(!value || record.rawFile || value.rawFile)
		return <></>

	return (
		<Button href={value} target="_blank" rel="noopener">
			{value.split('/').pop()}
			<LaunchIcon className={classes.icon}/>
		</Button>
	)
})

// TODO: Invalid prop `file` of type `string` supplied to `FileInputPreview`, expected `object`.
const FileInputWithPreview = props => (
	<>
		<FileInput {...props}>
			<FileField />
		</FileInput>
		<FormDataConsumer>
			{({ formData, dispatch, ...rest }) => (
				<Link record={formData} source={props.source}/>
			)}
		</FormDataConsumer>
	</>
)

export default FileInputWithPreview