import { createMuiTheme } from '@material-ui/core/styles';
import { grey, orange, green } from '@material-ui/core/colors';

async function loadFonts() {
	const font = new FontFace('PFCentroSansPro', 'url(./assets/PFCentroSansPro/PFCENTROSANSPRO-REGULAR.OTF)', { style: 'normal', weight: 400 });
	await font.load();
	document.fonts.add(font);
	document.body.classList.add('fonts-loaded');
}
loadFonts()

const CustomTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#FF4C44',
			transparency: '#FF4C4480',
		},
		secondary: grey,
		error: { main: '#FF0038' },
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	chip: {
		preprod: orange,
		recette: green,
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'PFCentroSansPro',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Arial',
			'sans-serif',
		].join(','),
	},
	Headroom: {
		backgroundColor: 'red',
	},
	datagrid: {
		mainCell: {
			minWidth: '200px',
		},
		abbrev: {
			display: "-webkit-box",
			["-webkit-line-clamp"]: 3,
			["-webkit-box-orient"]: "vertical",
			overflow: "hidden",
		},
	},
	filters: {
		container: {

		},
		field: {
			flex: 1,
			minWidth: "300px",
		},
		tags: {
			label: {},
			labelShrink: {},
			chipContainer: { minWidth: "300px" },
			inputRoot: {},
		}
	},
	overrides: {
		Filter: {
			form: {
				width: '100%',
			},
			'&>*': {
				flex: 1,
			}
		},
		ChipInput: {
			chipContainer: {
				minHeight: 'auto',
			},
		},
		MuiButton: { // override the styles of all instances of this component
			root: { // Name of the rule
				color: 'white', // Some CSS
			},
		},
		MuiFormControl: { // || ChipInput
			root: {
				marginTop: '0',
				marginBottom: '8px',
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: '#eeeeee',
				padding: '10px'
			},
			elevation1: {
				boxShadow: 'none',
			},
			rounded: {
				borderRadius: '0',
			}
		},
		ListToolbar: {
			gutters: {
				backgroundColor: 'yellow'
			}
		},
		MuiTableHead: {
			root: {
				backgroundColor: '#303030'
			}
		},
		MuiTable: {
			root: {
				marginTop: '30px'
			}
		},
		MuiTableSortLabel: {
			root: {
				padding: '20px',
				color: '#a1a1a1',
				fontSize: '13px',
				textTransform: 'uppercase',
				textAlign: 'left',
				"&:hover": {
					color: "#FFFFFF"
				},
				"&:active": {
					color: "#FFFFFF"
				},
			},
			active: {
				color: "#FFFFFF",
				fontWeight: "bold"
			}
		},
		MuiTableRow: {
			head: {
				height: '80px'
			}
		},

		// Do it better 

		MuiSelect: {
			selectMenu: {

			}
		},
		MuiInput: {
			root: {

			}
		},
		MuiExpansionPanelDetails: {
			root: {
				padding: '0'
			}
		},
		MuiExpansionPanelSummary: {
			root: {
				paddingLeft: '30px'
			}
		},
		MuiListItem: {
			root: {
				paddingTop: "3px",
				paddingBottom: "3px"
			}
		},
		MuiButton: {
			root: {
				color: "#000000",
				minWidth: "20px"
			},
			sizeSmall: {
				minWidth: "25px"
			}
		},
		MuiTableSortLabel: {
			root: {
				paddingLeft: "0",
				color: "#FFFFFF",
				textTransform: "uppercase",
				'&:hover, &:focus': {
					color: '#FFFFFF'
				}
			},
			active: {
				color: "#FFFFFF",
			}
		},
	},
});

export default CustomTheme;
