import React, {useState} from 'react';
import {
	SimpleForm,
	BooleanInput,
	DateTimeInput,
	NumberInput,
	translate,
	maxLength,
	required,
	FormDataConsumer,
} from 'react-admin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import HeadingView from "../../components/parts/HeadingView";
import Container from '../../components/parts/Container';
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid';
import SelectFromForm from '../../components/fields/selectFromForm';
import FormToolbar from '../../components/parts/FormToolbar';
import SelectInput from '../../components/fields/styledSelectInput';
import AnswerTypeSelectInput, {questionId} from '../../components/fields/answerTypeSelectInput';
import PossibleAnswersInput from '../../components/fields/possibleAnswersInput';
import ManyTypesRelationshipsOrderedInput from '../../components/fields/manyTypesRelationshipsOrderedInput';
import ConditionalField from '../../components/fields/conditionalField';
import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";
import EscapedTextInput from '../../components/fields/escapedTextInput';
import { sanitizeFormProps } from '../../utils/sanitize';

const validateText = [required(), maxLengthRichText(500)];
const validateTitle = [required(), maxLength(60)];
const validateReq = [required()];

const _defaultValue = {
	level: 2,
	duration_type: 'short',
	active_en: true,
};

const Form = ({ translate, dispatch, heading, parentProps, record, defaultValue, redirect = 'list', view, ...props }) => {

	if (record.audios && record.audios.length)
		record.question_asset_type = 'A'
	else if (record.medias && record.medias.length)
		record.question_asset_type = 'I'
	else if (record.videos && record.videos.length)
		record.question_asset_type = 'V'
	else
		record.question_asset_type = 'none'

	if (typeof record.duration === 'number')
		record.duration_type = 'custom'



	return (
		<SimpleForm
			{...sanitizeFormProps(props)}
			defaultValue={{ ..._defaultValue, ...defaultValue }}
			record={record}
			redirect={redirect}
			toolbar={<FormToolbar />}
		>
			<HeadingView resource={props.resource} view={view} />
			<Grid container>
				<Grid item xs={8}>
					<Grid container head>
						<Grid item xs={6}>
							<EscapedTextInput source="internal_title" validate={validateTitle}/>
						</Grid>
						<Grid item xs={6}></Grid>
						<Grid item xs={12}>
							<BooleanInput source="active_en" defaultValue={true} />
						</Grid>
					</Grid>

					<Container title={'info'}>
						<Grid container>
							<Grid item xs={6}>
								<RichTextInputRelLinks source="text_fr" label="question_fr" {...parentProps} validate={validateText} />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<RichTextInputRelLinks source="text_en" label="question_en" {...parentProps} validate={validateText} />
								</ConditionalField>
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<SelectInput source="level" choices={[
									{ id: 1, name: '1' },
									{ id: 2, name: '2' },
									{ id: 3, name: '3' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>

							<Grid item xs={6}>
								<FormDataConsumer {...props}>
									{({ formData, dispatch, ...rest }) => {

										const ATSIChangeHandler = (obj,currentValue,oldValue,fieldName) => {

											dispatch(change(props.form, 'type', questionId[currentValue]))
											if(currentValue!=='BT' && currentValue!=='BTPA' && currentValue!=='BTPV' && (formData.duration_type === null || formData.duration_type === 'none') ){
												if (typeof formData.duration === 'number'){
													dispatch(change(props.form, 'duration_type', 'custom'))
												}else{
													dispatch(change(props.form, 'duration_type', 'short'))
												}
											}

										}

										return ( <AnswerTypeSelectInput source="answer_type" validate={validateReq} onChange={ATSIChangeHandler} {...rest} /> )
									}}
								</FormDataConsumer>
								<ConditionalField source="answer_asset_type" test={({ answer_type }) => answer_type === 'S'}>
									<SelectInput
										source="answer_asset_type"
										choices={[
											{ id: 'V', name: 'interface.select.assetType.videos' },
											{ id: 'A', name: 'interface.select.assetType.audios' },
										]}
										validate={validateReq}
									/>
								</ConditionalField>
								<ConditionalField source="question_asset_type" test={({ answer_type }) => answer_type === 'J'}>
									<SelectInput
										source="question_asset_type"
										choices={[
											{ id: 'none', name: 'interface.select.assetType.none' },
											{ id: 'I', name: 'interface.select.assetType.medias' },
											{ id: 'A', name: 'interface.select.assetType.audios' },
											{ id: 'V', name: 'interface.select.assetType.videos' },
										]}
									/>
								</ConditionalField>

								<ConditionalField source="orientation_video" test={({ answer_type, question_asset_type, answer_asset_type }) =>
									(question_asset_type === 'V' && answer_type === 'J') || (answer_asset_type === 'V' && answer_type === 'S')
								}>
									<SelectInput
										source="orientation_video"
										choices={[
											{ id: 'landscape', name: '16/9' },
											{ id: 'portrait', name: '9/16' },
											{ id: 'fiche', name: '4/3' },
										]}
										validate={validateReq}
									/>
								</ConditionalField>
								<ConditionalField source="orientation" test={({ answer_type, question_asset_type }) =>
									answer_type === 'I' || (answer_type === 'J' && question_asset_type === 'I')
								}>
									<SelectInput
										source="orientation"
										choices={[
											{ id: 'landscape', name: '16/9' },
											{ id: 'portrait', name: '9/16' },
										]}
										validate={validateReq}
									/>
								</ConditionalField>

								<ConditionalField test={({answer_type}) => answer_type !== 'M'}>
									<FormDataConsumer>
										{({formData, dispatch, ...rest}) => {

											const durationTypesValues = [
												{id: 'short', name: 'interface.select.durationType.short'},
												{id: 'long', name: 'interface.select.durationType.long'},
												{id: 'custom', name: 'interface.select.durationType.custom'},
											];

											const additionalDurationType = {
												id: 'none',
												name: 'interface.select.durationType.none'
											};

											if ((formData.answer_type === 'BT' || formData.answer_type === 'BTPA' || formData.answer_type === 'BTPV' || formData.answer_type === 'M') && durationTypesValues.length <= 3) {
												durationTypesValues.unshift(additionalDurationType)
											}
											if (formData.answer_type !== 'BT' && formData.answer_type !== 'BTPA' && formData.answer_type !== 'BTPV' && formData.answer_type !== 'M' && durationTypesValues.length > 3) {
												const additionalTypeIndex = durationTypesValues.indexOf(additionalDurationType)
												durationTypesValues.splice(additionalTypeIndex, 1)

											}
											const durationTypes = [...durationTypesValues]
											return (
												<SelectInput {...rest} source="duration_type" choices={durationTypes}/>
											)
										}}
									</FormDataConsumer>


									<ConditionalField source="duration"
													  test={({duration_type}) => duration_type === 'custom'}>
										<NumberInput source="duration" options={{fullWidth: true}}
													 validate={validateReq}/>
									</ConditionalField>
								</ConditionalField>
								<ConditionalField test={({answer_type}) => answer_type === 'M'}>
									<FormDataConsumer {...props}>
										{({formData}) => {
											if ((formData.time_slicing === '' || formData.time_slicing === undefined || formData.time_slicing === null) && formData?.melody_data?.time_slicing) {
												formData.time_slicing = formData.melody_data.time_slicing
											}
											return (
												<SelectInput source="time_slicing" label="time_slicing"
															 choices={[
																 {id: '2', name: '2'},
																 {id: '3', name: '3'},
																 {id: '4', name: '4'},
																 {id: '6', name: '6'},
																 {id: '8', name: '8'},
																 {id: '9', name: '9'},
																 {id: '12', name: '12'},
																 {id: '16', name: '16'},
															 ]}/>
											)
										}}
									</FormDataConsumer>
								</ConditionalField>
							</Grid>
						</Grid>
					</Container>
					<ConditionalField test={({answer_type}) => answer_type !== 'M'}>
						<Container title="answers">
							<PossibleAnswersInput {...parentProps} label={translate('resources.questions.containers.answerList')}/>
							<Grid item xs={6}>
								<SelectFromForm
									label="good_answer"
									source="good_answer_id"
									reference="possibleAnswers"
									showMedia={false}
									validate={validateReq}
								/>
								<ConditionalField source="timed_duration" test={({answer_type}) => answer_type === 'B'}>
									<NumberInput source="timed_duration" options={{fullWidth: true}}/>
								</ConditionalField>
							</Grid>
						</Container>
					</ConditionalField>
					<ConditionalField source="medias" test={({ answer_type, answer_asset_type, question_asset_type }) => (answer_type === 'S' && answer_asset_type === 'A') || (answer_type === 'J' && question_asset_type === 'I')}>
						<Container title={'images'}>
							<GalleryInput
								type="medias"
								allowEmpty
								maxSelection={1}
								formats={['landscape', 'portrait']}
							/>
						</Container>
					</ConditionalField>
					<ConditionalField source="audios" test={({ answer_type, question_asset_type }) => answer_type === 'I' || (answer_type === 'J' && question_asset_type === 'A') || answer_type === 'BT'}>
						<Container title={'audios'}>
							<GalleryInput
								type="audios"
								allowEmpty
								maxSelection={1}
							/>
						</Container>
					</ConditionalField>
					<ConditionalField source="melody" test={({ answer_type }) => answer_type === 'M' }>
						<Container title={'wave_melody'}>
							<GalleryInput
								type="melody"
								maxSelection={1}
								minSelection={1}
							/>
						</Container>
					</ConditionalField>
					<ConditionalField source="videos" test={({ answer_type, question_asset_type }) => answer_type === 'J' && question_asset_type === 'V'}>
						<Container title={'videos'}>
							<GalleryInput
								type="videos"
								allowEmpty
								maxSelection={1}
							/>
						</Container>
					</ConditionalField>
					<ConditionalField source="relationships.artworks" test={({ answer_type }) => answer_type === 'B'|| answer_type === 'BT' || answer_type === 'BTPA' || answer_type === 'BTPV' }>
						<Container title={'artworks'}>
							<ManyTypesRelationshipsOrderedInput maxSelection={1} reference={['artworks']}/>
						</Container>
					</ConditionalField>
					<Container title="chatbot">
						<Grid container>
							<Grid item xs={6}>
								<SelectInput allowEmpty source="chatbot_waiting_animation" choices={[
									{ id: 'waiting_1', name: 'interface.select.waiting.waiting_1' },
									{ id: 'waiting_2', name: 'interface.select.waiting.waiting_2' },
									{ id: 'waiting_3', name: 'interface.select.waiting.waiting_3' },
									{ id: 'two_choice_waiting_1', name: 'interface.select.waiting.two_choice_waiting_1' },
									{ id: 'two_choice_waiting_2', name: 'interface.select.waiting.two_choice_waiting_2' },
									{ id: 'two_choice_waiting_3', name: 'interface.select.waiting.two_choice_waiting_3' },
									{ id: 'three_choice_waiting_1', name: 'interface.select.waiting.three_choice_waiting_1' },
									{ id: 'three_choice_waiting_2', name: 'interface.select.waiting.three_choice_waiting_2' },
									{ id: 'three_choice_waiting_3', name: 'interface.select.waiting.three_choice_waiting_3' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="chatbot_waiting_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="chatbot_waiting_text_en" label="chatbot_waiting_text_en" />
								</ConditionalField>
							</Grid>
							<Grid item xs={12} hr></Grid>
							<Grid item xs={6}>
								<SelectInput allowEmpty source="chatbot_success_animation" label="chatbot_success_animation" choices={[
									{ id: 'good_1', name: 'interface.select.good.good_1' },
									{ id: 'good_2', name: 'interface.select.good.good_2' },
									{ id: 'good_3', name: 'interface.select.good.good_3' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="chatbot_success_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="chatbot_success_text_en" label="chatbot_success_text_en" />
								</ConditionalField>
							</Grid>
							<Grid item xs={12} hr></Grid>
							<Grid item xs={6}>
								<SelectInput allowEmpty source="chatbot_error_animation" label="chatbot_error_animation" choices={[
									{ id: 'bad_1', name: 'interface.select.bad.bad_1' },
									{ id: 'bad_2', name: 'interface.select.bad.bad_2' },
									{ id: 'bad_3', name: 'interface.select.bad.bad_3' },
								]} />
							</Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="chatbot_error_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="chatbot_error_text_en" label="chatbot_error_text_en" />
								</ConditionalField>
							</Grid>
							<Grid item xs={12} hr></Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="answer_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="answer_text_en" label="answer_text_en" />
								</ConditionalField>
							</Grid>
							<Grid item xs={6}>
								<EscapedTextInput source="chatbot_help_text_fr" />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="chatbot_help_text_en" label="chatbot_help_text_en" />
								</ConditionalField>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Grid item xs={4}>
					<Container title={'publication'}>
						<DateTimeInput source="created_at" disabled />
						<DateTimeInput source="updated_at" disabled />
					</Container>
				</Grid>
			</Grid>
		</SimpleForm>
	)
}

export default compose(
	translate,
	connect(),
)(Form);
