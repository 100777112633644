import React from 'react';
import {
	Create,
	SimpleForm,
	ImageInput,
	ImageField,
} from 'react-admin';
import FullViewportDropzoneWrapper from '../../components/fields/fullViewportDropzoneWrapper';
import UniqueForm from '../../components/fields/uniqueForm';

const defaultValue = {
	active_fr: true,
	active_en: true
};

const MediaLibrarieCreate = ({ drawerPath, redirect = 'list', toolbar, ...props }) => {

	return (
		<UniqueForm {...props}>
			{({ formName }) =>
				<Create {...props} undoable="false">
					<SimpleForm defaultValue={defaultValue} form={formName} redirect={redirect} toolbar={toolbar}>
						<FullViewportDropzoneWrapper>
							<ImageInput source="file" accept="image/*" multiple >
								<ImageField source="url" title="title" />
							</ImageInput>
						</FullViewportDropzoneWrapper>
					</SimpleForm>
				</Create>
			}
		</UniqueForm>
	)
};

// TODO: redirect to 'list' if multiple items were uploaded, to 'edit' if single item

export default MediaLibrarieCreate;
