// sorts a media array *in place*
// works well for medias, audios, videos, slideshows

// complicated sorting is necessary because 
// - API didn't use to store a position for any media array (except images)
// - for a while during the client's contribution, the API set all positions to 0
// - it's too late to wipe the slate clean because the client has started contributing

// sorts array of objects by key `position` if found
// not having a `position` is equivalent to having `position = -1`

export default (array) => {
	if (!Array.isArray(array) || !array.length)
		return array

	array.forEach((item, i) => {
		if(item)
			item._index = i
	})

	// item can be null or an object
	// item.position can be absent, 0, or any integer >0
	array.sort((itemA, itemB) => {
		if (!itemA && !itemB)
			return 0
		const positionA = itemA && typeof itemA.position === 'number' ? itemA.position : -1
		const positionB = itemB && typeof itemB.position === 'number' ? itemB.position : -1
		if (positionA === positionB) {
			const indexA = itemA ? itemA._index : -1
			const indexB = itemB ? itemB._index : -1
			return indexA - indexB
		}
		return positionA - positionB
	})

	array.forEach(item => {
		if(item)
			delete item._index
	})
	return array
}