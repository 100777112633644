import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import get from 'lodash/get'

const HasItemsField = ({ record, source }) => {
	const value = get(record, source)
	if (Array.isArray(value) ? value.length : !!value)
		return <CheckIcon />
	else
		return <></>
}

export default HasItemsField