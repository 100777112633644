import React from 'react';

import {
	List,
	Datagrid,
	TextInput,
	NullableBooleanInput,
	Filter,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import InListBooleanInput from '../../components/fields/inlistBooleanInput';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';


const filterStyles = theme => ({
	container: theme.filters.container,
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({ classes, ...props }) => (
	<Filter {...props} className={classes.container}>
		<TextInput label='label_search' source="fLabel" alwaysOn className={classes.field} />
		<NullableBooleanInput label='published' source="fPublished" alwaysOn className={classes.field} />
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const ThemeGrid = withStyles(gridStyles)(({ classes, ...props }) => (
	<Datagrid rowClick="edit" {...props}>
		<EscapedTextField source="label_fr" className={classes.mainCell} />
		<InListBooleanInput source="published" />
		<DateTimeField source="updated_at" />
	</Datagrid>
))


const ThemeList = (props) => (
	<List
		filters={<PostFilter />}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<ThemeGrid />
	</List>
);



export default ThemeList;
