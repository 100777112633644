export const filterUndefinedValues = obj => {
	const result = {}
	Object.keys(obj).forEach(key => {
		if(obj[key] !== undefined)
			result[key] = obj[key]
	})
	return result
}

export const sanitizeEditProps = ({
	basePath,
	children,
	hasCreate,
	hasEdit,
	hasShow,
	hasList,
	id,
	isLoading,
	resource,
	undoable,
}) => filterUndefinedValues({
	basePath,
	children,
	hasCreate,
	hasEdit,
	hasShow,
	hasList,
	id,
	isLoading,
	resource,
	undoable,
})

export const sanitizeCreateProps = ({
	basePath,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	location,
	match,
	record,
	resource,
}) => filterUndefinedValues({
	basePath,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	location,
	match,
	record,
	resource,
})

export const sanitizeFormProps = ({
	basePath,
	data,
	defaultValue,
	form,
	handleSubmit,
	invalid,
	pristine,
	record,
	resource,
	redirect,
	save,
	saving,
	submitOnEnter,
	toolbar,
	undoable,
	validate,
	version,
}) => filterUndefinedValues({
	basePath,
	data,
	defaultValue,
	form,
	handleSubmit,
	invalid,
	pristine,
	record,
	resource,
	redirect,
	save,
	saving,
	submitOnEnter,
	toolbar,
	undoable,
	validate,
	version,
})

export const sanitizeListProps = ({
	filter,
	filters,
	filterDefaultValues,
	pagination,
	perPage,
	sort,
	authProvider,
	basePath,
	debounce,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	location,
	path,
	query,
	resource,
}) => filterUndefinedValues({
	filter,
	filters,
	filterDefaultValues,
	pagination,
	perPage,
	sort,
	authProvider,
	basePath,
	debounce,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	location,
	path,
	query,
	resource,
})