import React from 'react';
import {
	ReferenceArrayInput,
	AutocompleteArrayInput,
} from 'react-admin';

const FilterTagInput = ({classes, ...props}) =>
	<ReferenceArrayInput {...props}>
		<AutocompleteArrayInput 
			optionText="label_fr"
			shouldRenderSuggestions={value => value.trim().length > 2 }
			limitChoicesToValue={true}
			options={{ 
				allowDuplicates: false,
				fullWidth: true,
				classes
			}}
		/>
	</ReferenceArrayInput>

export default FilterTagInput