import React from 'react';

import {
	List,
	Datagrid,
	NumberField,
	BulkDeleteButton,
	translate,
} from 'react-admin';
import FormHelperText from '@material-ui/core/FormHelperText';
import { compose } from 'redux';
import { connect } from 'react-redux';

import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import DateTimeField from '../../components/fields/dateTimeField';

const InstantWinnerGrid = (props) => (
	<Datagrid {...props}>
		<NumberField source="id" />
		<DateTimeField source="start_at" />
		<DateTimeField source="end_at" />
		<NumberField source="max_winners" />
		<NumberField source="winners_count" />
	</Datagrid>
);

const BulkListActions = ({selectedIds, dispatch, translate, forbidden, ...props}) => (
	<>
		{forbidden && 
			<FormHelperText error>{translate('resources.instantWinnerDates.interface.deleteActive')}</FormHelperText>
		}
		<BulkDeleteButton selectedIds={selectedIds} {...props} disabled={!!forbidden}/>
	</>
)
const mapStateToProps = (state, {selectedIds}) => {
	const selected = selectedIds.map(id => state.admin.resources.instantWinnerDates.data[id])
	if(selected.some(data => data.winners_count > 0))
		return {forbidden: true}
	return {}
}
const ConnectedBulkListActions = compose(
	connect(mapStateToProps),
	translate,
)(BulkListActions)

const InstantWinnerList = ({ drawerPath, rowClick = 'edit', ...props }) => (
	<List
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
		bulkActionButtons={<ConnectedBulkListActions/>}
	>
		<InstantWinnerGrid rowClick={rowClick}></InstantWinnerGrid>
	</List>
);

export default InstantWinnerList;
