import React from 'react';
import {
	SimpleForm,
	FormDataConsumer,
	DateTimeInput,
	BooleanInput,
	required,
	NumberInput,
} from 'react-admin';

import GalleryInput from "../../components/fields/galleryInput";
import SelectInput from '../../components/fields/styledSelectInput';



import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import ManyTypesRelationshipsOrderedInput from '../../components/fields/manyTypesRelationshipsOrderedInput';
import TypeSelectInput from '../../components/fields/answerTypeSelectInput';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';
import ConditionalField from "../../components/fields/conditionalField";

const validateReq = [required()];

const _defaultValue = { active_en: true };

const Form = ({  view, redirect = 'list', defaultValue, record, parentProps, ...props }) => {

	/*if (record.audios && record.audios.length)
		record.asset_type = 'A'
	else if (record.videos && record.videos.length)
		record.asset_type = 'V'
	else
		record.asset_type = 'none'*/



	/*if (typeof record.duration === 'number')
		record.duration_type = 'custom'*/



	return (
	<SimpleForm
		{...sanitizeFormProps(props)}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
		record={record}

	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true}/>
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>
						<Grid item xs={6}>
							<TypeSelectInput
								source="type"
								question
								validate={validateReq}
							/>
							{/*<ConditionalField source="asset_type" test={({ type }) => type === 'QBP'}>
								<SelectInput
									source="asset_type"
									label='resources.questions.fields.question_asset_type'
									choices={[
										{ id: 'V', name: 'interface.select.assetType.videos'},
										{ id: 'A', name: 'interface.select.assetType.audios' },
									]}
									validate={validateReq}
								/>
							</ConditionalField>*/}

							{/*<ConditionalField source="duration_type" test={({ type }) => type === 'QBP'}>
								<SelectInput source="duration_type" choices={[
									{ id: 'short', name: 'interface.select.durationType.short' },
									{ id: 'long', name: 'interface.select.durationType.long' },
									{ id: 'custom', name: 'interface.select.durationType.custom' },
								]} />
							</ConditionalField>*/}

							<ConditionalField source="timed_duration" test={({ type }) => type === 'QBPA' || type === 'QBPV'}>
								<NumberInput source="timed_duration" options={{fullWidth: true}} validate={validateReq}/>
							</ConditionalField>



						</Grid>
					</Grid>
				</Container>



				<ConditionalField source="audios" test={({ type }) => type === 'QBPA' /*&& asset_type === 'A'*/}>
					<Container title={'audios'}>
						<GalleryInput
							type="audios"
							maxSelection={1}
						/>
					</Container>
				</ConditionalField>
				<ConditionalField source="videos" test={({ type }) => type === 'QBPV' /*&& asset_type === 'V'*/}>
					<Container title={'videos'}>
						<GalleryInput
							type="videos"
							allowEmpty
							maxSelection={1}
						/>
					</Container>
				</ConditionalField>

				<Container title={'step'}>
					<FormDataConsumer>
						{({ formData, ...props }) => <ManyTypesRelationshipsOrderedInput
							reference={'questions'}
							maxSelection={formData.type === 'QM' ? 1 : Infinity}
							filter={{ qType: formData.type }}
							{...props}
						/>}
					</FormDataConsumer>
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
)};

export default Form;
