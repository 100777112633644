import React from 'react';
import { Button, SaveButton, DeleteWithConfirmButton, Toolbar } from 'react-admin';

import PageviewIcon from '@material-ui/icons/Pageview';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

import Preview, { previewUrlLeaf } from './Preview'

const FormToolbar = ({classes, undoable, ...props}) => {

	// avoid re-renders of the iframe 
	const initialFormIsEdit = props.record && !!props.record.id
	const [formIsEdit, setFormIsEdit] = React.useState(initialFormIsEdit)
	React.useEffect(() => {
		if (initialFormIsEdit !== formIsEdit)
			setFormIsEdit(initialFormIsEdit)
	}, [initialFormIsEdit])

	// show circular loader only on the button that was clicked
	const [loadingPreview, setLoadingPreview] = React.useState(false)
	React.useEffect(() => {
		if(loadingPreview && !props.saving)
			setLoadingPreview(false)
	}, [props.saving])

	const modalControls = React.useRef({})

	const saveAndRedirect = () => {
		setLoadingPreview(true)
		props.handleSubmitWithRedirect(
			(basePath, id, record) => {
				if (formIsEdit) {
					modalControls.current.open()
					return false
				} else {
					return `${basePath}/${id}/${previewUrlLeaf}`
				}
			}
		)()
	}

	return <>
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton
				label="interface.save"
				redirect={props.redirect}
				submitOnEnter={true}
			/>
			<>{props.pristine && formIsEdit
				? <Button
					label={'interface.preview'}
					onClick={() => modalControls.current.open()}
					variant="flat"
				>
					<PageviewIcon />
				</Button>
				: <Button
					label={'interface.saveAndPreview'}
					onClick={saveAndRedirect}
					variant="flat"
				>
					{loadingPreview ? <CircularProgress classes={{root: classes.loadingIcon}}/> : <SaveIcon />}
				</Button>

			}</>
			{formIsEdit &&
				<DeleteWithConfirmButton />
			}

		</Toolbar>
		{formIsEdit &&
			<Preview {...props} controls={modalControls} />
		}
	</>
};

const styles = theme => ({
	loadingIcon: {
		height: '1em !important',
		width: '1em !important',
	},
	toolbar: {
		'&>*': {
			marginRight: '1em',
		},
	},
})

export default withStyles(styles)(FormToolbar)