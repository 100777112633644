import React from 'react';

import {
	List,
	Datagrid,
	Filter,
	SelectField,
	LongTextInput,
	RichTextField,
	translate,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import AnswerTypeSelectInput, {questionId} from '../../components/fields/answerTypeSelectInput';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import ConditionalField from '../../components/fields/conditionalField';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const QuestionGrid = compose(
	translate,
	withStyles(gridStyles),
)(({ classes, translate, ...props }) => (
	<Datagrid {...props} >
		<EscapedTextField source="id" />
			<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<SelectField source="answer_type" choices={[
			{ id: 'J', name: translate('interface.select.answerType.joystick') },
			{ id: 'I', name: translate('interface.select.answerType.image') },
			{ id: 'S', name: translate('interface.select.answerType.sound') },
			{ id: 'B', name: translate('interface.select.answerType.timer') },
			{ id: 'BT', name: translate('interface.select.answerType.blind_test') },
			{ id: 'BTPA', name: translate('interface.select.answerType.blind_test_progressive_audio') },
			{ id: 'BTPV', name: translate('interface.select.answerType.blind_test_progressive_video') },
			{ id: 'M', name: translate('interface.select.answerType.melody') }
		]} />
		<RichTextField source="text_fr" label="question_fr" className={classes.abbrev}/>
		<DateTimeField source="updated_at" />
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container,
	field: theme.filters.field,
})
const filterHas = (filter, key) => filter && !!filter[key]
const PostFilter = compose(
	translate,
	withStyles(filterStyles),
)(({ classes, translate, filter, ...props }) => (
	<Filter {...props} className={classes.container}>
		<LongTextInput source='fInternalTitle' alwaysOn className={classes.field} />
		<LongTextInput source="fText" alwaysOn disabled={filterHas(filter, 'fText')} className={classes.field} />
		<AnswerTypeSelectInput source="fAnswerType" alwaysOn disabled={filterHas(filter, 'fAnswerType')} className={classes.field} />
	</Filter>
));



const QuestionList = ({ drawerPath, rowClick = 'edit', filter, ...props }) => (
	<List
		filters={<PostFilter filter={filter} />}
		filter={filter}
		filterDefaultValues={filter}
		exporter={false}
		{...props}
		pagination={<PostPagination />}
		perPage={getPerPage()}
	>
		<QuestionGrid rowClick={rowClick}></QuestionGrid>
	</List>
);

export default QuestionList;
