import React from 'react';
import { TextField, ArrayField, ImageField, Datagrid, Button } from 'react-admin';

import { withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import MultiFormatsImageCropsInput from '../multiFormatsImageCropsInput';
import AudioField from '../audioField';
import VideoField from '../videoField';
import WaveSurferField from '../waveSurfer/waveSurferField'
import sortMediaArray from '../../../utils/sortMediaArray';

const DeleteItemButton = ({ className, record, getOnClick, id }) => {
	const itemId = record ? record.id : id;

	return (
		<Button
			label=""
			onClick={getOnClick(itemId)}
			variant="outlined"
			color="secondary"
			className={className}
		>
			<DeleteIcon />
		</Button>
	);
};

const EditItemButton = ({ className, record, getOnClick, id }) => {
	const itemId = record ? record.id : id;
	return (
		<Button
			label=""
			onClick={getOnClick(itemId)}
			variant="outlined"
			color="secondary"
			className={className}
		>
			<EditIcon />
		</Button>
	)
}

const imageCardHeaderActionStyles = {
	deleteButton: {
		marginLeft: '1em'
	}
}

const ImageCardHeaderAction = withStyles(imageCardHeaderActionStyles)(({classes, id, getOnEdit, getOnDelete}) => (
	<>
		<EditItemButton id={id} getOnClick={getOnEdit} />
		<DeleteItemButton id={id} getOnClick={getOnDelete} className={classes.deleteButton}/>
	</>
))

const ImageFormIterator = ({ source, data, ids, formats, input, getOnDelete, getOnEdit, ...props }) => {

	const listItemPseudoComponent = (dataId, data, i) => {
		const record = data[dataId];
		if (record)
			return <div key={record.id} style={{ marginRight: '30px'}}>
				<Card>
					<CardHeader
						action={<ImageCardHeaderAction id={record.id} getOnEdit={getOnEdit} getOnDelete={getOnDelete}/>}
						title={record.internal_title}
						subheader={record.artist}
					/>
					<CardContent>
						<MultiFormatsImageCropsInput source={`${source}[${i}]`} record={record} formats={formats} input={input} />
					</CardContent>
				</Card>
			</div>

	}

	return ids.map((dataId, i) => listItemPseudoComponent(dataId, data, i));
};

const MediaArrayInput = ({ field, formats, record, controls }) => {
	const source = field.input.name;

	sortMediaArray(record[source])

	if(source === 'slideshows' && Array.isArray(record[source]))
		record[source].forEach(item => sortMediaArray(item.medias))

	const getDeleteItem = itemId => () => {
		// so we don't disturb the multiple <Field> components, we should nullify the item instead of splicing it from the array
		// otherwise we will lose the crop values (this only applies to source==='medias')
		const newArray = Object.values(field.input.value);
		const removeItemIndex = newArray.findIndex(item => item && item.id === itemId);
		if (source === 'medias')
			newArray[removeItemIndex] = null
		else
			newArray.splice(removeItemIndex, 1)

		field.input.onChange(newArray)
	};

	const getEditItem = itemId => () => {
		controls.current.open(itemId)
	}

	switch (source) {
		case 'medias':
			return <ArrayField source={source} record={record}>
				<ImageFormIterator source={source} formats={formats} input={field.input} getOnDelete={getDeleteItem} getOnEdit={getEditItem}/>
			</ArrayField>
		case 'audios':
				return <ArrayField source={source} record={record}>
					<Datagrid>
						<TextField source="internal_title" />
						<AudioField source="url" />
						<EditItemButton getOnClick={getEditItem}/>
						<DeleteItemButton getOnClick={getDeleteItem} />
					</Datagrid>
				</ArrayField>
		case 'melody':
			return <WaveSurferField source="melody" record={record} getOnDelete={getDeleteItem} />
		case 'videos':
			return <ArrayField source={source} record={record}>
				<Datagrid>
					<TextField source="internal_title" />
					<VideoField source="video_url" poster="image_url" />
					<EditItemButton getOnClick={getEditItem}/>
					<DeleteItemButton getOnClick={getDeleteItem} />
				</Datagrid>
			</ArrayField>
		case 'slideshows':
			return <ArrayField source={source} record={record}>
				<Datagrid>
					<ArrayField source="medias">
						<Datagrid>
							<TextField source="internal_title" />
							<ImageField source="url" title="title_fr" />
						</Datagrid>
					</ArrayField>
					<EditItemButton getOnClick={getEditItem}/>
					<DeleteItemButton getOnClick={getDeleteItem} />
				</Datagrid>
			</ArrayField>
		default:
			throw new Error(`Type ${source} not defined in GalleryInput`);
	};
};

export default MediaArrayInput
