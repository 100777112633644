import {
	fetchUtils,
	CREATE,
	DELETE,
	DELETE_MANY,
	GET_LIST,
	GET_MANY,
	GET_ONE,
	UPDATE,
} from 'react-admin';
import create_provider from './create';
import delete_provider from './delete';
import delete_many_provider from './delete_many';
import get_list_provider from './get_list';
import get_many_provider from './get_many';
import get_one_provider from './get_one';
import update_provider from './update';


const httpClient = fetchUtils.fetchJson

const serverProvider = (type, resource, payload) => {

	if(window.ARIA_CONFIG.OWNER === 'Mazarine')
		console.log(`api ${type} ${resource}`, payload);

	switch (type) {
		case CREATE:
			return create_request(resource, payload)
		case DELETE:
			return delete_request(resource, payload)
		case DELETE_MANY:
			return delete_many_request(resource, payload)
		case GET_LIST:
			return get_list_request(resource, payload)
		case GET_MANY:
			return get_many_request(resource, payload)
		case GET_ONE:
			return get_one_request(resource, payload)
		case UPDATE:
			return update_request(resource, payload)
		default:
			throw new Error(`${type} request not defined in serverProvider`);
	}
}

const create_request = create_provider(httpClient, serverProvider)
const delete_request = delete_provider(httpClient, serverProvider)
const delete_many_request = delete_many_provider(httpClient, serverProvider)
const get_list_request = get_list_provider(httpClient, serverProvider)
const get_many_request = get_many_provider(httpClient, serverProvider)
const get_one_request = get_one_provider(httpClient, serverProvider)
const update_request = update_provider(httpClient, serverProvider)

export default serverProvider;
