// Data-display components for <list> view
import React from 'react';
import {
	List,
	Datagrid,
	Filter,
	NullableBooleanInput,
}            from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import InlistBooleanInput from '../../components/fields/inlistBooleanInput';
import { withStyles } from '@material-ui/core';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<NullableBooleanInput label="published" source="fPublished" alwaysOn className={classes.field}/>
	</Filter>
));

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const BadgeList = withStyles(gridStyles)(({classes, drawerPath, ...props}) => (
	<List
		filters={<PostFilter/>}
		filter={{fType:'thematic'}}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<Datagrid rowClick="edit">
			<EscapedTextField source="id"/>
			<EscapedTextField source="label_fr" className={classes.mainCell}/>
			<InlistBooleanInput source="published"/>
			<DateTimeField source="updated_at"/>
		</Datagrid>
	</List>
));

export default BadgeList;
