import React from 'react';

import {
	List,
	Datagrid,
	Filter,
	TextInput,
	RichTextField,
	CardActions,
	CreateButton,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import MediaGrid, { MediaGridToggleButton, getMediaGridStorage } from '../../components/parts/MediaGrid'
import VideoField from '../../components/fields/videoField';
import EditMediaInDrawer, { getOpen } from '../../components/fields/editMediaInDrawer';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextField from '../../components/fields/escapedTextField';
import { name, edit } from './'
import { withStyles } from '@material-ui/core';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const VideoGrid = withStyles(gridStyles)(({classes, editInDrawer, getOpen, ...props}) => (
	<Datagrid {...props}>
		<EscapedTextField source="id" />
		<EscapedTextField source="internal_title" className={classes.mainCell}/>
		<VideoField source="url" poster="image_url" />
		<EscapedTextField source="legend_fr" className={classes.abbrev}/>
		<EscapedTextField source="artist" />
		<RichTextField source="description_fr" className={classes.abbrev}/>
		<DateTimeField source="updated_at" />
		{editInDrawer &&
			<EditField getOpen={getOpen}/>
		}
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<TextInput source="fInternalTitle" alwaysOn className={classes.field}/>
	</Filter>
));

const EditField = ({ getOpen, record }) => {
	return (
		<IconButton onClick={getOpen(record.id)} color="primary">
			<EditIcon />
		</IconButton>
	)
}

const DefaultListActions = ({ grid, setGrid, basePath }) => {
	return (
		<CardActions>
			<MediaGridToggleButton grid={grid} setGrid={setGrid} />
			<CreateButton basePath={basePath} />
		</CardActions>
	);
};

const VideoList = ({ filter, drawerPath, rowClick = 'edit', actions, editInDrawer = false, ...props }) => {

	const [grid, setGrid] = React.useState(getMediaGridStorage())
	const StatefulListActions = React.cloneElement(actions || <DefaultListActions />, { grid, setGrid })

	const controls = React.useRef({})
	const getOpenWithControls = getOpen(controls)

	return (
		<>
			<List
				key={`hacky-key-${grid}`}
				actions={StatefulListActions}
				filters={<PostFilter />}
				filter={filter}
				exporter={false}
				{...props}
				perPage={getPerPage()}
				pagination={<PostPagination />}
			>
				{grid
					? <MediaGrid
						key="mediagrid"
						rowClick={rowClick}
						imageSource="image_url"
						subtitleSource="legend_fr"
						editInDrawer={editInDrawer}
						getOpen={getOpenWithControls}
					/>
					: <VideoGrid 
						key="datagrid"
						rowClick={rowClick}
						editInDrawer={editInDrawer}
						getOpen={getOpenWithControls}
					/>
				}

			</List>
			{editInDrawer &&
				<EditMediaInDrawer name={name} edit={edit} controls={controls} drawerPath={drawerPath} />
			}
		</>
	)
};

export default VideoList;
