import React from 'react';
import Quill from 'quill';
import { KNOW_MORE_BLOT_NAME, DEFAULT_FORMATS } from './quillConfig';

/**
 * @param {Object} input   [optional] input object from redux-form <Field>, used to update FOCUS and BLUR states 🚧commented out because of bug
 * @param {String} domId   DOM id of div that becomes Quill editor
 * @param {Bool} withLinks whether to activate the KNOW_MORE_BLOT_NAME option and allow linking to resources from the text editor
 * @param {Function} onClick   function called when the KNOW_MORE_BLOT_NAME in the toolbar is clicked
 * @param {Function} onFocus   function called when the editor becomes in focus, sends the instance of `quill` as an argument 🚧commented out because of bug
 * @param {Function} onBlur    function called when the editor becomes out of focus, sends the instance of `quill` as an argument 🚧commented out because of bug
 * @param {Function} onLink    function called when a KNOW_MORE_BLOT_NAME is created or destroyed, sends the instance of `quill` as an argument (as well as when initializing editor w/ text)
 */

export default ({
	input,
	domId,
	withLinks,
	onClick,
	onFocus,
	onBlur,
	onLink,
}) => {

	const editorResolution = React.useRef({}) // ref to Promise instanciated by Quill when applying custom format to resolve / reject data

	const quillInit = (quill) => {
		// only way to access `quill` instance

		if(typeof onLink === 'function') {
			quill.once(Quill.events.SELECTION_CHANGE, () => {
				// hack to initialize relationships array after initial injection of text into quill's editor
					onLink(quill)
			});
		}

		quill.on(Quill.events.TEXT_CHANGE, (delta1, delta2, source) => {
			// hack to keep toolbar buttons properly toggled despite multiple .format() calls for the same blotName and/or delayed .format() due to Promise
			if (source === 'api') {
				const range = quill.getSelection();
				quill.setSelection(0, 0);
				quill.setSelection(range);
			}
		});
		
		// This is commented out because it causes the following bug: if we focus on the WYSIWYG, quickly paste, and quickly focuse elsewhere, the pasted text disappears
		// Without this code, the material-ui <Label> won't react to focus, only to actual content
		// quill.on(Quill.events.SELECTION_CHANGE, (range) => {
		// 	// hack to keep redux-form <Field> `meta` properties up to date (touched, visited, active)
		// 	if (range) {
		// 		if(input)
		// 			input.onFocus()
		// 		if(typeof onFocus === 'function')
		// 			onFocus(quill)
		// 	} else {
		// 		if(input)
		// 			input.onBlur()
		// 		if(typeof onBlur === 'function')
		// 			onBlur(quill)
		// 	}
		// });

		quill.root.setAttribute('id', domId)

		if(withLinks) {
			quill.getModule('toolbar').addHandler(KNOW_MORE_BLOT_NAME, function (flag) {
				// we don't use the arrow function notation in `addHandler` above because it needs to properly handle `this` (requirement from Quill)
				if (flag) {
					// if no text is selected, don't open drawer
					const range = quill.getSelection();
					if(range.length === 0)
						return Promise.reject();
						
					return new Promise((resolve, reject) => {
						editorResolution.current = { resolve, reject };
						if(typeof onClick === 'function')
							onClick()
						else 
							reject()
					}).then(data => {
						quill.format(KNOW_MORE_BLOT_NAME, data);
						if(typeof onLink === 'function')
							onLink(quill);
					}).catch(() => {
						quill.format(KNOW_MORE_BLOT_NAME, false);
					});
				} else {
					quill.format(KNOW_MORE_BLOT_NAME, false);
					if(typeof onLink === 'function')
						onLink(quill);
				}
			});
		}

		// filter accepted formats on init / paste
		const formats = DEFAULT_FORMATS.concat(withLinks ? [{ [KNOW_MORE_BLOT_NAME]: true }] : [])
		quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
			const ops = delta.ops.map(op => {
				if(op.attributes) {
					const {attributes, insert} = op
					/**
					 * below is a complicated comparison function for parsed formats that come in the form of [{name: value}]
					 * one exception is for KNOW_MORE_BLOT_NAME where the parsed format is {KNOW_MORE_BLOT_NAME: {source, id}}
					 * we run through *every* key in the attributes objects "just in case" even though there is probably only one key per object
					 */
					const keys = Object.keys(attributes)
						.filter(attributeName => {
							const attributeValue = attributes[attributeName]
							return formats.some(format => Object.keys(format).some(formatName => {
								const formatValue = format[formatName]
								if(formatName === KNOW_MORE_BLOT_NAME)
									return attributeName === formatName && !!attributeValue === !!formatValue
								return attributeName === formatName && attributeValue === formatValue
							}))
						})

					const filteredAttributes = {}
					keys.forEach(key => filteredAttributes[key] = attributes[key])
					return {
						attributes: filteredAttributes,
						insert
					}
				}
				return op
			})
			delta.ops = ops
			return delta
		})
	};

	return {
		init: quillInit,
		promiseControls: editorResolution
	}
}