import React from 'react';
import {
	FormDataConsumer,
	ReferenceInput,
	AutocompleteInput,
} from 'react-admin';
import get from 'lodash/get'

/**
 * @param {string} source react-admin style source, like for any other field or input (should point to a unique of integer)
 * @param {array<string>} exclude array of strings of react-admin style sources pointing to the multiple arrays of integers to exclude from the Autocomplete list
 * 
 * @usage
<PrimaryTagsInput 
	source="relationships.primaryTags.ids[0]" 
	exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']}
/>
 */

const PrimaryTagsInput = ({ source, exclude = [], ...props }) =>
	<FormDataConsumer {...props}>
		{({ formData, dispatch, ...rest }) => {
			const excludeIds = exclude.map(path => get(formData, path)).flat()
			return (
				<ReferenceInput
					{...rest}
					source={source}
					reference="tags"
					filter={{ published: true, excludeIds }}
					label="primary_tags"
					sort={{ field: 'label', order: 'ASC' }}
					allowEmpty
				>
					<AutocompleteInput 
						optionText="label_fr" 
						shouldRenderSuggestions={value => value.trim().length > 2 }
						limitChoicesToValue={true}
					/>
				</ReferenceInput>
			)
		}}
	</FormDataConsumer>

export default PrimaryTagsInput