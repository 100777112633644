import React from 'react';
import {
	Create,
	SimpleForm,
	FileInput,
}            from 'react-admin';

import AudioField from '../../components/fields/audioField';
import FullViewportDropzoneWrapper from '../../components/fields/fullViewportDropzoneWrapper';
import UniqueForm from '../../components/fields/uniqueForm';

const defaultValue = {
	active_fr: true,
	active_en: true,
};

export default ({drawerPath, redirect = 'list', toolbar, ...props}) => (
	<UniqueForm {...props}>
		{({ formName }) =>
			<Create {...props} undoable="false">
				<SimpleForm defaultValue={defaultValue} redirect={redirect} form={formName} toolbar={toolbar}>
					<FullViewportDropzoneWrapper>
						<FileInput source="file" accept="audio/*" multiple>
							<AudioField source="url" label="preview"/>
						</FileInput>
					</FullViewportDropzoneWrapper>
				</SimpleForm>
			</Create>
		}
	</UniqueForm>
);

// TODO: redirect to 'list' if multiple items were uploaded, to 'edit' is single item

