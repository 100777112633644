import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	maxLength,
	DateTimeInput,
	required
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid'
import RichTextInputRelLinks, { maxLengthRichText } from "../../components/fields/richTextInputRelLinks";
import FormToolbar from "../../components/parts/FormToolbar";
import SelectInput from '../../components/fields/styledSelectInput';
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const validateText = [maxLengthRichText(300)];
const validateTitle = [maxLength(40)];
const validateReq = [required()];

const _defaultValue = { active_en: true };

const Form = ({ view, parentProps, defaultValue, required = 'list', ...props }) => (
	<SimpleForm
		toolbar={<FormToolbar />}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		required={required}
		{...sanitizeFormProps(props)}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={6}>
						<EscapedTextInput source="internal_title" validate={validateReq} />
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true} />
					</Grid>
				</Grid>

				<Container title={'info'}>
					<Grid container>
						<Grid item xs={6}>
							<EscapedTextInput source="subtitle_fr" validate={validateTitle} />
							<RichTextInputRelLinks source="description_fr" {...parentProps} validate={validateText} />
							<EscapedTextInput source="opera_show_id" />
						</Grid>

						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="subtitle_en" validate={validateTitle} />
								<RichTextInputRelLinks source="description_en" {...parentProps} validate={validateText} />
							</ConditionalField>
						</Grid>
					</Grid>
				</Container>
				<Container title={'chatbot'}>
					<Grid container>
						<Grid item xs={6}>
							<EscapedTextInput source="chatbot_waiting_text_fr" validate={validateTitle} label="chatbot_comment_text_fr" />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="chatbot_waiting_text_en" validate={validateTitle} label="chatbot_comment_text_en" />
							</ConditionalField>
						</Grid>
						<Grid item xs={12}></Grid>
						<Grid item xs={6}>
							<SelectInput allowEmpty source="chatbot_waiting_animation" label="chatbot_comment_animation" choices={[
								{ id: 'waiting_1', name: 'interface.select.waiting.waiting_1' },
								{ id: 'waiting_2', name: 'interface.select.waiting.waiting_2' },
								{ id: 'waiting_3', name: 'interface.select.waiting.waiting_3' },
								{ id: 'two_choice_waiting_1', name: 'interface.select.waiting.two_choice_waiting_1' },
								{ id: 'two_choice_waiting_2', name: 'interface.select.waiting.two_choice_waiting_2' },
								{ id: 'two_choice_waiting_3', name: 'interface.select.waiting.two_choice_waiting_3' },
								{ id: 'three_choice_waiting_1', name: 'interface.select.waiting.three_choice_waiting_1' },
								{ id: 'three_choice_waiting_2', name: 'interface.select.waiting.three_choice_waiting_2' },
								{ id: 'three_choice_waiting_3', name: 'interface.select.waiting.three_choice_waiting_3' },
							]} />
						</Grid>
					</Grid>
				</Container>
				<Container title={'images'}>
					<GalleryInput type="medias" maxSelection={1} formats={['landscape']} {...parentProps} />
				</Container>
			</Grid>
			<Grid item xs={4}>
				<Container title={'publication'}>
					<DateTimeInput source="created_at" disabled />
					<DateTimeInput source="updated_at" disabled />
				</Container>
			</Grid>
		</Grid>
	</SimpleForm>
)

export default Form;