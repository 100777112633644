import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';

const styles = theme => (
	createStyles({
		dropZone: {
			background: theme.palette.background.default,
			cursor: 'pointer',
			padding: '1rem',
			textAlign: 'center',
			color: theme.palette.text.hint,
		},
		activeDropZone: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},
		activeDragDoc: {
			'&::after': {
				content: '""',
				position: 'fixed',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 1,
			}
		}
	})
)

const FullViewportDropzoneWrapper = ({ children, classes, record, ...props }) => {

	const [activeDropZone, setActiveDropZoneState] = React.useState(false)
	const [activeDragDoc, setActiveDragDoc] = React.useState(false)
	let activeDropZone_stateless = false

	const setActiveDropZone = (bool) => {
		activeDropZone_stateless = bool
		setActiveDropZoneState(bool)
	}

	const onDragEnter = () => { setActiveDropZone(true) }
	const onDragLeave = () => { setActiveDropZone(false) }
	const onDrop = () => { 
		setActiveDropZone(false)
		setActiveDragDoc(false)
	}

	const startDragAnywhere = () => { setActiveDragDoc(true) }
	const stopDragAnywhere = () => { setActiveDragDoc(activeDropZone_stateless) }

	React.useEffect(() => {
		document.addEventListener('dragenter', startDragAnywhere)
		document.addEventListener('dragleave', stopDragAnywhere)
		return () => {
			document.removeEventListener('dragenter', startDragAnywhere)
			document.removeEventListener('dragleave', stopDragAnywhere)
		}
	})

	return <>
		{React.cloneElement(children, {
			...props,
			options: {
				onDragEnter,
				onDragLeave,
				onDropAccepted: onDrop,
				onDropRejected: onDrop,
				className: [
					classes.dropZone,
					activeDropZone ? classes.activeDropZone : '',
					activeDragDoc ? classes.activeDragDoc : '',
				].join(' ')
			}
		})}
	</>
};

export default withStyles(styles)(FullViewportDropzoneWrapper);