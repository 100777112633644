import React from 'react';

import {
	List,
	Datagrid,
	TextField,
	Filter,
	BooleanField,
	translate,
} from 'react-admin';
import PostPagination, { getPerPage } from '../../components/parts/PostPagination';
import LengthField from '../../components/fields/lengthField';
import DateTimeField from '../../components/fields/dateTimeField';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

const gridStyles = theme => ({
	mainCell: theme.datagrid.mainCell,
	abbrev: theme.datagrid.abbrev,
})

const QuestionnaireGrid = compose(
	translate,
	withStyles(gridStyles),
)(({classes, translate, ...props}) => (
	<Datagrid {...props}>
		<TextField source="id"/>
		<TextField source="internal_title" className={classes.mainCell}/>
		<BooleanField source="is_standalone"/>
		<BooleanField source="is_locked_for_guests"/>
		<LengthField source="questions" sortBy="qty_questions" label={translate('interface.numberof', {resource: translate('resources.questions.name')})}/>
		<DateTimeField source="updated_at" />
	</Datagrid>
));

const filterStyles = theme => ({
	container: theme.filters.container, 
	field: theme.filters.field,
})

const PostFilter = withStyles(filterStyles)(({classes, ...props}) => (
	<Filter {...props} className={classes.container}>
		<EscapedTextInput source='fInternalTitle' alwaysOn className={classes.field}/>
	</Filter>
));


const QuestionnaireList = ({drawerPath, rowClick = 'edit', ...props}) => (
	<List
		filters={<PostFilter/>}
		exporter={false}
		{...props}
		perPage={getPerPage()}
		pagination={<PostPagination />}
	>
		<QuestionnaireGrid rowClick={rowClick}></QuestionnaireGrid>
	</List>
);

export default QuestionnaireList;
