import React from 'react';
import {
	Edit,
	SimpleForm,
	BooleanInput,
	required,
} from 'react-admin';

import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import Grid from '../../components/parts/Grid'
import VideoField from '../../components/fields/videoField';
import FileInput from '../../components/fields/fileInput';
import GalleryInput from '../../components/fields/galleryInput';
import ConditionalField from '../../components/fields/conditionalField';
import EscapedTextInput from '../../components/fields/escapedTextInput';

const defaultValue = { active_fr: true, active_en: true };
const validateReq = [required()];

const VideoEdit = ({ toolbar, drawerPath, reduxData, ...props }) => {
	const formName = `edit-${props.resource}-${props.id}`;
	return (
		<Edit {...props} undoable={false}>
			<SimpleForm form={formName} defaultValue={defaultValue} toolbar={toolbar}>
				<HeadingView resource={props.resource} view="edit" />
				<Grid container>
					<Grid item xs={12}>

						<Grid container head>
							<Grid item xs={6}>
								<EscapedTextInput source="internal_title" validate={validateReq} />
							</Grid>
							<Grid item xs={6}></Grid>
							<Grid item xs={12}>
								<BooleanInput source="active_en" defaultValue={true} />
							</Grid>
						</Grid>

						<Container title={'info'}>
							<Grid container>

								<Grid item xs={6}>
									<EscapedTextInput source="title_fr" />
									<EscapedTextInput source="legend_fr" />
									<EscapedTextInput source="description_fr" />
									<FileInput source="subtitle_file_url_fr" accept=".vtt,text/vtt"/>
									<EscapedTextInput source="artist" />
								</Grid>
								<Grid item xs={6}>
									<ConditionalField flag="active_en">
										<EscapedTextInput source="title_en" />
										<EscapedTextInput source="legend_en" />
										<EscapedTextInput source="description_en" />
										<FileInput source="subtitle_file_url_en" accept=".vtt,text/vtt"/>
									</ConditionalField>
								</Grid>
								<Grid item xs={12}>
									<VideoField source="url" poster="image_url" />
								</Grid>
							</Grid>
						</Container>
						<Container title={'poster'}>
							<GalleryInput type="medias" allowEmpty maxSelection={1} formats={['portrait', 'landscape', 'fiche']} {...props} />
						</Container>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default VideoEdit;
