import React from 'react';
import {
	Edit,
	SimpleForm,
	Toolbar,
	SaveButton,
} from 'react-admin';
import ManyTypesRelationshipsOrderedInput from '../../components/fields/manyTypesRelationshipsOrderedInput';
import HeadingView from "../../components/parts/HeadingView";
import * as module from './index'

const CustomToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export default ({ apiModels, staticContext, ...props }) => {
	const formName = `edit-homepageHighlights`;
	const additionalProps = {
		id: 1,
		resource: module.name,
		basePath: `/${module.name}`,
	}
	return (
		<Edit {...props} {...additionalProps} undoable={false}>
			<SimpleForm form={formName} toolbar={<CustomToolbar/>}>
				<HeadingView resource={props.resource} view="create" />
				<ManyTypesRelationshipsOrderedInput reference={['sessions', 'courses']} maxSelection={5} />
			</SimpleForm>
		</Edit>
	)
};
