import React from 'react';
import {
	SimpleForm,
	BooleanInput,
	maxLength,
	DateTimeInput,
	required,
} from 'react-admin';

import ConditionalField from '../../components/fields/conditionalField';
import Container from '../../components/parts/Container';
import HeadingView from "../../components/parts/HeadingView";
import GalleryInput from "../../components/fields/galleryInput";
import Grid from '../../components/parts/Grid';
import RichTextInputRelLinks from "../../components/fields/richTextInputRelLinks";
import PrimaryTagsInput from '../../components/fields/tagFields/primaryTagInput';
import SecondaryTagsInput from '../../components/fields/tagFields/secondaryTagsInput';
import EscapedTextInput from '../../components/fields/escapedTextInput'
import { sanitizeFormProps } from '../../utils/sanitize';

const validateText = [required()];
const validateTitle = [required(), maxLength(40)];
const _defaultValue = { active_en: true };

const Form = ({ view, parentProps, defaultValue, redirect = 'list', ...props }) => (
	<SimpleForm
		{...sanitizeFormProps(props)}
		defaultValue={{ ..._defaultValue, ...defaultValue }}
		redirect={redirect}
	>
		<HeadingView resource={props.resource} view={view} />
		<Grid container>
			<Grid item xs={8}>

				<Grid container head>
					<Grid item xs={12}>
						<BooleanInput source="active_en" defaultValue={true} />
					</Grid>
				</Grid>

				<Container title={'info'}>
					{view === 'edit' &&
						<Grid container>
							<Grid item xs={6}>
								<EscapedTextInput source="slug_fr" disabled />
							</Grid>
							<Grid item xs={6}>
								<ConditionalField flag="active_en">
									<EscapedTextInput source="slug_en" disabled />
								</ConditionalField>
							</Grid>
						</Grid>
					}
					<Grid container>
						<Grid item xs={6}>
							<EscapedTextInput source="title_fr" validate={validateTitle} />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<EscapedTextInput source="title_en" validate={validateTitle} />
							</ConditionalField>
						</Grid>
						<Grid item xs={6}>
							<RichTextInputRelLinks source="description_fr" {...parentProps} validate={validateText} />
						</Grid>
						<Grid item xs={6}>
							<ConditionalField flag="active_en">
								<RichTextInputRelLinks source="description_en" {...parentProps} validate={validateText} />
							</ConditionalField>
						</Grid>
					</Grid>
				</Container>
				<Container title={'medias'}>
					<GalleryInput type="medias" maxSelection={1} formats={['portrait', 'landscape']} {...parentProps} />
				</Container>
				<Container title={'audios'}>
					<GalleryInput type="audios" maxSelection={1} {...parentProps} />
				</Container>
				<Container title={'videos'}>
					<GalleryInput type="videos" maxSelection={1} {...parentProps} />
				</Container>
			</Grid>
			<Grid item xs={4}>
				{view === 'edit' &&
					<Container title={'publication'}>
						<DateTimeInput source="created_at" disabled />
						<DateTimeInput source="updated_at" disabled />
					</Container>
				}
				<Container title={'index'}>
					<Grid >
						<PrimaryTagsInput source="relationships.primaryTags.ids[0]" exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']} />
						<SecondaryTagsInput source="relationships.secondaryTags.ids" exclude={['relationships.primaryTags.ids', 'relationships.secondaryTags.ids']} />
					</Grid>
				</Container>
			</Grid>
		</Grid>
	</SimpleForm >
);

export default Form;