import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	withStyles,
	createStyles,
} from '@material-ui/core/styles';
import {
	withTranslate,
	userLogin,
} from 'ra-core';

const styles = ({ spacing }) =>
	createStyles({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			display: 'block',
			margin: '1em auto',
			width: '100%',
			textTransform: 'none',
			borderRadius: '15em',
			padding: '1em',
		},
		icon: {
			marginRight: spacing.unit,
		},
		label: {
			color: 'yellow'
		}
	});

const textFieldStyles = (theme) =>
	createStyles({
		root: {
			
		},
		input: {
			color: 'white', // eat a bag of dicks
			padding: '6px'
		},
		formControl: {
			
		},
		domInput: {
			color: 'white'
		},
		label: {
			color: 'white'
		},
		underline: {
			'&:before': {
				borderBottomColor: 'white'
			}
		}
		
	});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
	meta: { touched, error } = { touched: false, error: '' },
	input: { ...inputProps },
	classes: {label, domInput, ...classes},
	...props
}) => (
		<TextField
			error={!!(touched && error)}
			helperText={touched && error}
			{...inputProps}
			{...props}
			fullWidth
			InputProps={{classes}}
			InputLabelProps={{className: label}}
			inputProps={{className: domInput}}
		/>
	);

const styledRenderInput = withStyles(textFieldStyles)(renderInput)

const login = (auth, dispatch, { redirectTo }) => dispatch(userLogin(auth, redirectTo));

const LoginForm = ({
	classes,
	isLoading,
	handleSubmit,
	translate,
}) => (
		<form onSubmit={handleSubmit(login)}>
			<div className={classes.form}>
				<div className={classes.input}>
					<Field
						autoFocus
						id="username"
						name="username"
						component={styledRenderInput}
						label={translate('interface.login.id')}
						disabled={isLoading}
					/>
				</div>
				<div className={classes.input}>
					<Field
						id="password"
						name="password"
						component={styledRenderInput}
						label={translate('interface.login.pwd')}
						type="password"
						disabled={isLoading}
						autoComplete="current-password"
					/>
				</div>
			</div>
			<CardActions>
				<Button
					variant="raised"
					type="submit"
					color="primary"
					disabled={isLoading}
					className={classes.button}
				>
					{isLoading && (
						<CircularProgress
							className={classes.icon}
							size={18}
							thickness={2}
						/>
					)}
					{translate('interface.login.login')}
				</Button>
			</CardActions>
		</form>
	);

const mapStateToProps = (state) => ({
	isLoading: state.admin.loading > 0,
});

const enhance = compose(
	withStyles(styles),
	withTranslate,
	connect(mapStateToProps),
	reduxForm({
		form: 'signIn',
		validate: (values, props) => {
			const errors = { username: '', password: '' };
			const { translate } = props;
			if (!values.username) {
				errors.username = translate('ra.validation.required');
			}
			if (!values.password) {
				errors.password = translate('ra.validation.required');
			}
			return errors;
		},
	})
);

const EnhancedLoginForm = enhance(LoginForm);

export default EnhancedLoginForm;
